import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField
}
    from 'react-admin';

export const RoleTypesList = props => {
    let propsObj = { ...props };
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;
      }
    }

return(
<List bulkActionButtons={false} {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} filter={{ 'id~neq': 11 }}>
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
            <TextField source="roleName" label="Role Name"/>
            <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date"/>
        </Datagrid>
    </List>
)};