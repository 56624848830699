import React, { useState, useEffect } from "react";
import * as apiClient from "../../common/apiClient";
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";
import { v4 as uuidv4 } from 'uuid';
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import { GridDetail } from './GridDetail';
import { Button, TextField } from "@material-ui/core";
import { CustomerField } from "./CustomerField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
export const OrderEdit = (props) => {
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasApprove = (p.Approve == 1) ? true : false;

    }
  }

  const classes = useStyles({});
  const notify = useNotify();
  const redirect = useRedirect();
  const [gridData, setGridData] = useState([{ key: uuidv4(), lineNumber: 1, productCode: null, productName: null, uomId: 0, price: null, qty: null, totalAmount: null, attributes: [], uomData: [] }]);
  const [headerData, setHeaderData] = useState([]);
  const [isLoading, SetIsLoading] = useState(true)
  const [transactionNo, SettransactionNo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>(null);
  const [cols, setCols] = useState<string[]>([]);
  const [data, setData] = useState([]);
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  useEffect(() => {
    setGridData(gridData)
  });
  useEffect(() => {
    loadData()
  }, []);
  const loadData = async () => {
    SetIsLoading(true)
    await apiClient.getOrderData(props.id).then(res => {
      setGridData(JSON.parse(res[0].data)[0].lineItems)
      SettransactionNo(JSON.parse(res[0].data)[0].orderNo)
      setHeaderData(JSON.parse(res[0].data)[0])
      SetIsLoading(false)
    }).catch(err => {
      console.log(err);
      SetIsLoading(false)
    });

  }
  const handleChange = (indata) => {
    console.log(indata, "******Main indata******");

    setGridData(indata);
  }
  const findDuplicateProducts = async () => {
    const productIdCount = {};
    gridData.forEach((item: any) => {
      const productId = item.productId;
      productIdCount[productId] = (productIdCount[productId] || 0) + 1;
    });
    const duplicateProductIds = Object.keys(productIdCount).filter(productId => productIdCount[productId] > 1);
    const duplicateProductNames = gridData.filter((item: any) => duplicateProductIds.includes(item.productId.toString())).map(item => item.productName);
    return duplicateProductNames;
  };

  const handleApprove = async () => {
    //orders/approveOrder

    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data: any = {};
    data.id = parseInt(props.id);
    data.userId = userId;
    apiClient.approveOrder(data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/orders");
      }
      else {
        notify(res[0].remarks, "warning")

      }
    });
  }
  const handleConfirm = async (_data) => {
    debugger
    setIsSubmitting(true);
    let userId: any = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data: any = {};
    if (_data != null) {
      data = { ..._data };
    }
    else {
      data.id = parseInt(props.id);
      data.facilityId = dialogData?.facilityId;
      data.orderType = dialogData?.orderType;
      data.clientId = dialogData?.clientId;
      data.customerId = dialogData?.customerId;
      data.documentNo = dialogData?.documentNo;
      data.orderDate = dialogData?.orderDate;
      data.saleRepId = dialogData?.saleRepId;
      data.shipAddress1 = dialogData?.shipAddress1;
      data.shipAddress2 = dialogData?.shipAddress2;
      data.shipZipCode = dialogData?.shipZipCode;
      data.shipStateId = dialogData?.shipStateId;
      data.tpMode = dialogData?.tpMode;
      data.remarks = dialogData?.remarks;
      data.cityName = dialogData?.cityName;
      data.lineItems = [...gridData];
      data.createdBy = userId;

    }

    apiClient.addeditOrder(data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/orders");
      } else {
        notify(res[0].remarks, "warning")
      }
      setIsSubmitting(false);
      setIsOpenDialog(false);
    }).catch(err => {
      console.log(err);
      setIsSubmitting(false);
      setIsOpenDialog(false);
    });
  };

  const handleSubmit = async (formdata) => {
    setIsSubmitting(true);
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data: any = {};
    data.id = parseInt(props.id);
    data.facilityId = formdata.facilityId;
    data.orderType = formdata.orderType;
    data.clientId = formdata.clientId;
    data.customerId = formdata.customerId;
    data.documentNo = formdata.documentNo;
    data.orderDate = formdata.orderDate;
    data.saleRepId = formdata.saleRepId;
    data.shipAddress1 = formdata.shipAddress1;
    data.shipAddress2 = formdata.shipAddress2;
    data.shipZipCode = formdata.shipZipCode;
    data.shipStateId = formdata.shipStateId;
    data.tpMode = formdata.tpMode;
    data.remarks = formdata.remarks;
    data.cityName = formdata.cityName;
    data.lineItems = [...gridData];
    if (gridData && gridData.length > 0) {
      let DuplicateProducts = await findDuplicateProducts();
      if (DuplicateProducts && DuplicateProducts.length > 0) {
        setIsSubmitting(false);
        notify("Duplicate Product In Grid " + DuplicateProducts.join(', '), "warning")
        return;
      }

    }
debugger

    // let m = moment(new Date());

    // data. modifyDate=  m.toISOString()
    data.createdBy = userId
    let verifcationData = { processType: "order", data: data }
    apiClient.ValidateTransaction(verifcationData).then(res => {

      // apiClient.addeditOrder(data).then(res => {
      //   if (res[0].id > 0) {
      //     notify(res[0].remarks)
      //     redirect("/orders");
      //   }
      //   else {
      //     notify(res[0].remarks, "warning")

      //   }
      //   setIsSubmitting(false);
      // }).catch(err => {
      //   console.log(err);
      //   setIsSubmitting(false);
      // });
      if (res.length > 0) {

        setCols([]);
        setData([]);
        if (res.length > 0) {
          let keys = Object.keys(res[0]);
          setCols(keys);
          setData(res);
        }
        setDialogData(data); // Set the formdata to dialogData state
        setIsOpenDialog(true); // Open the dialog
       
      }
      else {
        handleConfirm(data);
      }


    })
      .catch(err => {
        console.log(err);
        setIsSubmitting(false);
      });

  }
  return (
    <React.Fragment>
      <Edit title=" Order Edit" {...props}>
        <SimpleForm redirect="list" onSubmit={handleSubmit}
          toolbar={!isLoading ?
            <FormToolbar isSubmitting={isSubmitting} onSave={handleSubmit}  {...props} showDelete={false} /> : null}
          variant="outlined" >
          <div className="transaction-header">
            Order Detail ::-{transactionNo}
          </div>

          <ReferenceInput label="Facility" fullWidth={true} formClassName={classes.one_5_input}
            perPage={5000} source="facilityId"
            reference="facilities" >
            <AutocompleteInput disabled optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />

          </ReferenceInput>
          <ReferenceInput label="Order Type" fullWidth={true} formClassName={classes.two_5_input}
            perPage={5000} source="orderType"
            filter={{ type: 'OT' }} disabled
            reference="lookups" >
            <AutocompleteInput disabled optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />

          </ReferenceInput>
          <ReferenceInput label="Client" fullWidth={true} formClassName={classes.three_5_input}
            perPage={5000} source="clientId"
            reference="clients" >
            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
          </ReferenceInput>
          <FormDataConsumer fullWidth={true} formClassName={classes.four_5_input}>
            {formDataProps => (
              <CustomerField  {...formDataProps} />
            )}
          </FormDataConsumer>
          <ReferenceInput label="Sale Representative" fullWidth={true} formClassName={classes.last_5_input}
            perPage={5000} source="saleRepId"
            reference="sale-reps" >

            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
          </ReferenceInput>
          <DateInput source={"orderDate"} label={"Order  Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_5_input} />
          <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.two_5_input} />
          <ReferenceInput label="Mode" formClassName={classes.three_5_input}
            perPage={5000} source="tpMode"
            filter={{ type: 'TPM' }}
            validate={[required()]}
            reference="lookups" >
            <SelectInput optionText="name" variant="outlined" formClassName={classes.one_three_input} />
          </ReferenceInput>
          <div>
          </div>
          <TextInput source="shipAddress1" multiline label="Shipping Address 1" validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.one_5_input} />
          <TextInput source="shipAddress2" multiline label="Shipping Address 2" initialValue={""} fullWidth={true} formClassName={classes.two_5_input} />

          <TextInput source="shipZipCode" label="Ship Pincode" validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.three_5_input} />
          <ReferenceInput label="Shipping State" fullWidth={true} formClassName={classes.four_5_input}
            perPage={5000} source="shipStateId"
            sort={{ field: 'name', order: 'ASC' }}
            reference="states" >
            <SelectInput optionText="name" variant="outlined" validate={[required()]} />

          </ReferenceInput>
          <TextInput source="cityName" label="City Name" initialValue={""} fullWidth={true} formClassName={classes.last_5_input} />
          <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true} />

          {/* {!isLoading && isAdmin &&  <div style={{ textAlign: "right", width: "100%", marginTop: 20 }} >
          <Button className="close-me-btn" variant="contained" color="primary" onClick={handleApprove} >
           Approve
          </Button> 
        </div>
      } */}
          {!isLoading && isAdmin && <div style={{ width: "100%", marginTop: 20 }} >
            <FormDataConsumer formClassName={classes.last_three_input}>
              {({ formData, ...rest }) =>
                formData.statusId &&
                formData.statusId != 157 &&
                <ReferenceInput label="Status" disabled
                  perPage={5000} source="statusId"
                  sort={{ field: 'name', order: 'ASC' }}
                  reference="lookups" >
                  <SelectInput optionText="name" variant="outlined" validate={[required()]} />

                </ReferenceInput>
              }
            </FormDataConsumer>
          </div>}
          {!isLoading && isAdmin && <div style={{ textAlign: "right", width: "100%", marginTop: 20 }} >
            <FormDataConsumer formClassName={classes.last_three_input}>
              {({ formData, ...rest }) =>
                formData.statusId &&
                formData.statusId === 157 && propsObj && propsObj.hasApprove &&
                <Button className="close-me-btn" variant="contained" color="primary" onClick={handleApprove} >
                  Approve
                </Button>
              }
            </FormDataConsumer>
          </div>}

          {!isLoading && <FormDataConsumer fullWidth={true} >
            {formDataProps => (
              <GridDetail loadAddress={true} headerData={headerData} {...formDataProps} gridData={gridData} handleChange={handleChange} />
            )}
          </FormDataConsumer>
          }


        </SimpleForm>
      </Edit>
      <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="w3-responsive">

              <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                <tr>
                  {cols && cols.map((item, index) => {
                    return (
                      <th key={item}>{item}</th>
                    )
                  })}
                </tr>

                {data && data.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      {cols && cols.map((col, colIndex) => {
                        return (
                          <td key={rowIndex + "-" + colIndex}>
                            {row[col]}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <Button variant="contained" color="primary" style={{ marginTop: 10 }}
              onClick={() => { handleConfirm(null) }}
            >
              Proceed To Save
            </Button>

            <Button variant="contained" style={{ marginTop: 10 , marginLeft: 10 }}
              onClick={() => { setIsOpenDialog(false); setIsSubmitting(false); }}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};
