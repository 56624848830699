import React,{useState} from "react";
import { DisplayDate } from '../../common/DisplayDate';
import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
 
import {
  SelectInput,
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
  useNotify,
  useRefresh,
  AutocompleteInput,ChipField,useQueryWithStore
  

} from "react-admin";
import Button from '@material-ui/core/Button';
import PutawayConfirm from "./PutawayConfirm";
import PutawayPrint from "./PutawayPrint";
import CreditNotePrint from "./CreditNotePrint";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 300,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Goods Inward No "
      source="goodInwardNo~like"
      alwaysOn
    />
    <ReferenceInput label="Status"
      perPage={5000} source="statusId"
      reference="lookups"
      filter={{ type: 'TS' }}
    >
      <SelectInput optionText="name" variant="outlined" alwaysOn /> 
    </ReferenceInput>
    <ReferenceInput label="Vendor"
      perPage={5000} source="vendorId"
      reference="vendors"
    >
      <SelectInput optionText="name" variant="outlined" /> 
    </ReferenceInput>
   
  </Filter>
);

export const GoodInwardOrderList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const myclasses = myStyles({}); 
  
  const [isConfirmPutaway, setIsConfirmPutaway] = useState(false); 
  const [isPrintPutaway, setIsPrintPutaway] = useState(false); 
  const [selectRecord, setSelectRecord] = useState(null); 
  const [isCreditNote, setIsCreditNote] = useState(false); 
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  React.useEffect(() => {
    // Set up a timer to refresh the list every 10 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      if(!isConfirmPutaway && !isPrintPutaway )
      refresh();
    }, 10000*6); // 10 seconds in milliseconds

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [refresh,isConfirmPutaway,isPrintPutaway]);
  //   let timerId = setInterval(() => refresh(), 10000);

  // // after 5 seconds stop
  // setTimeout(() => { clearInterval(timerId);   }, 5000);

  const GeneratePutaway = async (record) => {
    let m = moment(new Date());
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data = {
      transactionId: record.id,
      transactionType: 'GRN_Putaway',
      createdBy: userId
    }
    await apiClient.addTransactionQueue(data).then(res => {
      if (res[0].result) {
        notify(res[0].message);
        refresh();
      }
      else {
        notify(res[0].message, "warning")
      }
    }).catch(err => {
      notify(err, "warning")
    });

  }
  const handleClose =() => {
    setIsConfirmPutaway(false);
    setIsPrintPutaway(false);
    setIsCreditNote(false);
    refresh(); 
  };
  const ConfirmPutaway = async (record) => {
    setIsConfirmPutaway(true);
    setSelectRecord(record);
  }
  const Print_Credit_Note = async (record) => {
    setIsCreditNote(true);
    setSelectRecord(record);
  }

  const PrintPutaway = async (record) => {
    setIsPrintPutaway(true);
    setSelectRecord(record);
  }
  const CustomDisplay = (props) => {
    const { record } = props;
    const { data, loading, error } = useQueryWithStore({
        type: 'getOne',
        resource: record.goodInwardOrdType === 165?'customers': 'vendors',
        payload: { id: record.vendorId },
    });

    if (loading) {
        return <div>Loading....</div>;
    }

    if (error) {
        console.error("Error fetching data:", error);
        return <div>Error loading data</div>;
    }

    if (record.goodInwardOrdType === 165) {
        return <div>{data.name}</div>; // Replace 'firstName' with the actual property you want to display
    } else {
        return <div>{data.name} </div>; // Replace 'firstName' with the actual property you want to display
    }  
}
  const PutawayButton = (props) => (
    <Button style={{ marginTop: 4 }} color="primary" variant="outlined" onClick={props.click}  > {props.label} </Button>
  )
  const PrintCreditNote = (props) => (
    <Button style={{ marginTop: 4,marginLeft:2 }} onClick={props.click} color="primary" variant="outlined" > Credit Note  </Button>
  )
  const Putaway = (props) => {
    let { record } = props;
    if (props.record.statusId == 147) {
      return (
        <div style={{ display: "flex" }}>
          <PutawayButton label="Generate Putaway " click={(e) => GeneratePutaway(record)} />
        </div>
      )
    }
    if (props.record.statusId == 150 || props.record.statusId == 152) {
      return (
        <div style={{ display: "flex" }}>
          <PutawayButton label="Print  Putaway " click={(e) => PrintPutaway(record)} />
          {props.record.goodInwardOrdType == 165 && props.record.statusId==152 &&  <PrintCreditNote label="Confirm Putaway " click={(e) => Print_Credit_Note(record)} />}
          {props.record.statusId==150 &&  
          <div style={{ minWidth: 10 }}></div>}
          {props.record.statusId==150 &&   <PutawayButton label="Confirm Putaway " click={(e) => ConfirmPutaway(record)} />}
        </div>

      )
    }
    else {
      return null
    }

  }

  return (
    <React.Fragment>
    <List sort={{ field: 'id', order: 'DESC' }}  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Good Inward  List" filters={<Filters />}  >
      <Datagrid rowClick={false}  >
        {propsObj.hasEdit && <EditButton />}

        <ChipField source="goodInwardNo" label="Good Inward No  " />
        

        <Putaway label="Action" source="id" />
        <ReferenceField source="facilityId" reference="facilities" label="Facility" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="clientId" reference="clients" label="Client" link={false}>
          <TextField source="name" />
        </ReferenceField>
        
        <CustomDisplay source="vendorId" label="Vendor / Customer" />
        <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper1500}}
    open={isConfirmPutaway || isPrintPutaway || isCreditNote}    >
 
 {selectRecord  && isConfirmPutaway && <PutawayConfirm
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}

{selectRecord  && isPrintPutaway && <PutawayPrint
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}

{selectRecord  && isCreditNote && <CreditNotePrint
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}




  </Drawer>
    </React.Fragment>
  );
};
