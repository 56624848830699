import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

export const ProductCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="Product Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <TextInput source="code"  fullWidth={true}  validate={[required()]}  formClassName={classes.one_4_input}/>
       <TextInput source="name"  fullWidth={true}  validate={[required()]}  formClassName={classes.two_4_input}/>
       <TextInput source="otherCode"  fullWidth={true} initialValue={""}  formClassName={classes.three_4_input}/>
       <ReferenceInput label="Product Group" fullWidth={true} formClassName={classes.last_4_input}
                    perPage={5000} source="productGroupId" 
                    reference="product-groups" >
                    <SelectInput optionText="name" variant="outlined"  validate={[required()]} />
                </ReferenceInput> 
                <NumberInput label="Height" validate={[required()]} source="height" initialValue={0} fullWidth={true} formClassName={classes.one_4_input}/>
                <NumberInput label="Width" validate={[required()]} source="width" initialValue={0} fullWidth={true} formClassName={classes.two_4_input}/>
                <NumberInput label="Length" validate={[required()]} source="length"  initialValue={0} fullWidth={true} formClassName={classes.three_4_input}/>
                <NumberInput label="Weight" validate={[required()]} source="weight"  initialValue={0}fullWidth={true} formClassName={classes.last_4_input} />
                <ReferenceInput label="UOM" fullWidth={true} formClassName={classes.one_4_input}
                    perPage={5000} source="uomId"  validate={[required()]} 
                    filter={{ baseUomId: 0 }}
                    reference="uoms" >
                    <SelectInput optionText="name" variant="outlined"  validate={[required()]} />
                </ReferenceInput> 
                <NumberInput label="Price" validate={[required()]} source="price"  initialValue={0}fullWidth={true} formClassName={classes.two_4_input} />
                <TextInput multiline source="description1"   initialValue={""} fullWidth={true} />
                <TextInput multiline source="description2"   initialValue={""} fullWidth={true} />
                <NumberInput label="Threshold Quantity" validate={[required()]} source="thresholdQuantity"  initialValue={0}fullWidth={true} formClassName={classes.one_three_input} />
            <BooleanInput source="isActive" validate={[required()]}  label="Active"  fullWidth={true} formClassName={classes.two_three_input}/>
      </SimpleForm>
    </Create>
  );
};
