import React from "react";
import { DisplayDateTime } from '../../common/DisplayDate';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Zone"
      source="name~like"
      alwaysOn
    />
  </Filter>
);
export const ZoneList = (props) => {
  return (
    <List bulkActionButtons={false} {...props} title="Zone List" filters={<Filters />}>
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="name" label="Zone" />
        <ReferenceField source="facilityId" reference="facilities" label="Facility"  link={false}>
                <TextField source="name" />
         </ReferenceField>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
         </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
