import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  DateInput,FormDataConsumer
} from "react-admin";
import Customfields from "./Customfields";
import { FormToolbar } from "../../common/FormToolbar";
import CustomSelectInput from "./CustomSelectInput";

export const RateMasterCreate = (props) => {
  return (
    <Create title="Rate Master Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <TextInput source="name"  fullWidth={true} label="Code" validate={[required()]}/>
      <ReferenceInput label="Rate Type"  validate={[required()]} fullWidth={true} source="rateTypeId" reference="lookups" perPage={5000} 
                    filter={{ type: 'RT' }}
                    >
            <SelectInput optionText="name"  />
        </ReferenceInput>
       
        {/* <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <Customfields {...formDataProps} />
              )}
            </FormDataConsumer>  */}

          <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="policy-departments" 
                selectedText={"--All--"} source={"policyDepartmentId"} 
                 optionText={"name"} label={"Policy Department"}
                  fullWidth={true} {...formDataProps} 
                
              dependentFields={["policyTypeId"]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="policy-types" selectedText={"--All--"} source={"policyTypeId"}  optionText={"name"} label={"Policy Type"} fullWidth={true} {...formDataProps} 
                filters={[{"policyDepartmentId":formDataProps.policyDepartmentId}]}
                />
              )}
            </FormDataConsumer>
         {/* <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="insurers" selectedText={"--All--"} source={"insurerId"}  optionText={"name"} label={"Insurer"} fullWidth={true} {...formDataProps} />
              )}
            </FormDataConsumer> */}
        <NumberInput  variant="outlined" label="TP Rate Percentage" source="tpRatePercentage" fullWidth={true}  />
        <NumberInput  variant="outlined" label="Brok Rate Percentage" source="brokRatePercentage" fullWidth={true}  />
        <DateInput label="Effective From" source="effectiveFrom" fullWidth={true} /> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Create>
  );
};
