import React,{useState} from "react";
import { DisplayDateTime } from '../../common/DisplayDate';
import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core'; 
import {
  SelectInput,
  List,
  Datagrid,
  SimpleShowLayout, TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
  useNotify,
  useRefresh,
  AutocompleteInput,ChipField

} from "react-admin";
import Button from '@material-ui/core/Button';


import AssignmentIcon from '@material-ui/icons/Assignment';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import AddBox from '@material-ui/icons/AddBox';



import PackslipPrint from "./PackslipPrint";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 600,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <ReferenceInput perPage={25} sort={{ field: 'orderNo', order: 'ASC' }} filterToQuery={searchText => ({ 'orderNo~like': searchText })}
      label="Order No" source="id" reference="orders" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    {/* <ReferenceInput perPage={25} sort={{ field: 'orderNo', order: 'ASC' }} filterToQuery={searchText => ({ 'orderNo~like': searchText })}
      label="Order No" source="id" reference="orders" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput> */}
    <ReferenceInput label="Status"
      perPage={5000} source="statusId"
      reference="lookups"
      sort={{ field: 'name', order: 'ASC' }} 
      filter={{ code: 'PKS' }}
    >
      <SelectInput optionText="name" variant="outlined" alwaysOn /> 
    </ReferenceInput>
    <ReferenceInput label="Customer"
      perPage={5000} source="customerId"
      reference="customers"
    >
      <SelectInput optionText="name" variant="outlined" /> 
    </ReferenceInput>
   
  </Filter>
);
const ExpandedPanel = (props) => {
  let propsObj = { ...props };
  
    
      propsObj.hasList = false;
      propsObj.hasShow = true ;
      propsObj.hasEdit = false;//(p.Edit == 1) ? true : false;
      propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport =  false;
    
  

  
  return ( 
    <List sort={{ field: 'id', order: 'DESC' }}  {...propsObj} >
    <Datagrid 
          rowClick={false} 
          // expand={<SimpleShowLayout> <ExpandedPanel source="id" /> </SimpleShowLayout>}
        >
        {/* {propsObj.hasEdit && <EditButton />} */} 

        <ReferenceField source="orderId" reference="orders" label="Order No" link={false}>
          <TextField source="orderNo" />
        </ReferenceField>
      </Datagrid>
    </List>
  
  // <div>
  //   {/* {new Date().toTimeString()} */}

  //   <table>
  //     <tr>
  //       <th>Order No</th> 
  //     </tr>
  //     <tr>
  //       <td>Order No</td> 
  //     </tr>
  //   </table>
    
  //   </div> 
    );
};

export const OrderPackingList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const myclasses = myStyles({});  
  const [selectRecord, setSelectRecord] = useState(null); 
  const [isPrintPackslip, setIsPrintPackslip] = useState(false);  
  const [isGeneratePickslip, setIsGeneratePickslip] = useState(false);  
  const [isPrint, setIsPrint] = useState(false); 
  const [isPrintInvoice, setIsPrintInvoice] = useState(false); 
  const [isCancelOrder, setIsCancelOrder] = useState(false); 
  const [isGeneratePacking, setIsGeneratePacking] = useState(false); 
  const [isConfirmPicklist, setIsConfirmPicklist] = useState(false); 
  let isAdmin=false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = false;//(p.Edit == 1) ? true : false;
      propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  
  React.useEffect(() => {
    // Set up a timer to refresh the list every 10 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      if(!isPrintPackslip && !isGeneratePacking)
      refresh();
    }, 10000); // 10 seconds in milliseconds

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [refresh,isPrintPackslip,isGeneratePacking]);
  const PrintPacklist = async (record) => {
    setIsPrintPackslip(true); 
    setSelectRecord(record);
  }

   
  
  const GeneratePacking = async (record) => {
    //clearInterval(intervalId);
    const userConfirmed = window.confirm('Are you sure?');

    if (userConfirmed) {
    setIsGeneratePacking(true);

    let m = moment(new Date());
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data = {
      orderId: record.orderId, 
      userId: userId
    }
    await apiClient.generatepacking(data).then(res => {
      if (res[0].result) {
        notify(res[0].message);
        refresh();
      }
      else {
        notify(res[0].message, "warning")
      }
    }).catch(err => {
      notify(err, "warning")
    });
  }
    //setSelectRecord(record); 

  }

  const CompletePacking = async (record) => {
    //clearInterval(intervalId);
    const userConfirmed = window.confirm('Are you sure?');

    if (userConfirmed) { 
    let m = moment(new Date());
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data = {
      orderId: record.orderId, 
      userId: userId
    }
    await apiClient.completepacking(data).then(res => {
      if (res[0].result) {
        notify(res[0].message);
        refresh();
      }
      else {
        notify(res[0].message, "warning")
      }
    }).catch(err => {
      notify(err, "warning")
    });
  }
    //setSelectRecord(record); 

  }
  
  
  const handleClose =() => {
    setIsPrintPackslip(false);
    setIsPrint(false);
    setIsPrintInvoice(false);
    setIsGeneratePickslip(false);
    setIsCancelOrder(false);
    setIsConfirmPicklist(false);
    refresh(); 
  };
  
  const CompletePackingButton = (props) => (
    <Button style={{ marginTop: 4  ,marginLeft: 4}} startIcon={<AssignmentIcon />}  color="primary" variant="contained" onClick={props.click}  > {props.label} </Button>
  )
  
  const GeneratePackingButton = (props) => (
    <Button  startIcon={<AddBox />} style={{ marginTop: 4 ,marginLeft: 4}} color="primary"  variant="contained" onClick={props.click}  > {props.label} </Button>
  )
  const PrintPackingButton = (props) => (
    <Button startIcon={<PrintIcon />} onClick={props.click} style={{ marginTop: 4 ,marginLeft: 4 }} color="primary" variant="outlined"  > Print Packing  </Button>
  )
  
  
  const OrderAction = (props) => {
    let { record } = props;
    
    if (props.record.statusId == 177) {
      return (
        <div style={{ display: "flex" }}>
          <PrintPackingButton label="Print Packing " click={(e) => PrintPacklist(record)} />
          <div style={{ minWidth: 10 }}></div>
          <CompletePackingButton label="Complete Packing " click={(e) => CompletePacking(record)} />
        </div>
      )
    }
    if (props.record.statusId == 179) {
      return (
        <div style={{ display: "flex" }}>
          <PrintPackingButton label="Print Packing " click={(e) => PrintPacklist(record)} />
          
        </div>
      )
    }
     
    if (props.record.statusId == 178  ) {
      return <div style={{ display: "flex" }}>
      {<GeneratePackingButton label="Generate Packing" click={(e) => GeneratePacking(record)} />}  
    </div>
    
    }
    else {
     return null;
      
    }

  }

  return (
    <React.Fragment>
    <List sort={{ field: 'id', order: 'DESC' }}  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Order Packing List" filters={<Filters />}  >
    <Datagrid 
          rowClick={false} 
          // expand={<SimpleShowLayout> <ExpandedPanel source="id" /> </SimpleShowLayout>}
        >
        {/* {propsObj.hasEdit && <EditButton />} */} 

        <ReferenceField source="orderId" reference="orders" label="Order No" link={false}>
          <ChipField source="orderNo" />
        </ReferenceField>
        <OrderAction label="Action" source="id" />
        
        <ReferenceField source="customerId" reference="customers" label="Customer" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="clientId" reference="clients" label="Client" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="facilityId" reference="facilities" label="Facility" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="totalQty"  label="Total Qty" />
        <NumberField source="totalPackets"  label="Total Packets" />
        <NumberField source="totalPackets"  label="Total Packets" />
        <NumberField source="totalWeight"  label="Total Weight" />
        <NumberField  source="totalBoxWeight"  label="Total Box Weight" />
        <NumberField source="totalOrderWeight"  label="Total Order Weight" /> 
        
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={( isGeneratePickslip)?{ paper: myclasses.drawerPaper1500}:{ paper: myclasses.drawerPaper}}
    open={ isPrintPackslip }     > 
        
{selectRecord  && isPrintPackslip && <PackslipPrint
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          transactionType={'completepacking'}
          {...props}
           />}
 
           
  </Drawer>
    </React.Fragment>
  );
};
