import React, { useEffect,useCallback,useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,AutocompleteInput,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';

import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import  PopupCreateToolbar  from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const SaleOrderEdit = props => {
    const classes = useStyles({});
   
    
    const TwoDecimalPlaces = (value) => {
        
        if (isNaN(value))
        {
            value=0;
        }
        return Number(value).toFixed(2).valueOf();
    }
   
    const handleSubmit = async (formdata) => {
        
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }

        let data={...formdata};
        let m = moment(new Date());
        data.dispatchOrderId= parseInt(data.dispatchOrderId);
        data.noOfBox= parseInt(data.noOfBox);
        data.orderId= parseInt(data.orderId);
        data.freightCharge= parseFloat(formdata.freightCharge);             
        data.actualWeight= parseFloat(formdata.actualWeight);             
        data.isActive=true;
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("dispatch-order-dtls", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <>
        <Edit {...props} id={props.record.id} resource="dispatch-order-dtls" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
  <ReferenceInput label="Order Number" fullWidth={true}
                    perPage={5000} source="orderId"
                    disabled
                    reference="orders" >
                    
                    <SelectInput disabled optionText="orderNo" variant="outlined"  />
                </ReferenceInput>
                <ReferenceInput label="Pay Type" source="payTypeId" filter={{ type: 'PT' }} fullWidth={true} 
                  reference="lookups" validate={[required()]}   > 
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} /> 
                </ReferenceInput>
                <NumberInput source="noOfBox" label="Total Box" validate={[required()]} fullWidth={true} /> 
                { <NumberInput source="actualWeight" label="ActualWeight" validate={[required()]} fullWidth={true} initialValue={0} /> }  
                { <NumberInput source="freightCharge" label="Freight Charge" validate={[required()]} fullWidth={true}  /> }  
               <BooleanInput source="isCharged" validate={[required()]} label="Add Charges In Invoice" initialValue={false} />

            
            </SimpleForm>
        </Edit>
       
      </>
    );
}
export default SaleOrderEdit;