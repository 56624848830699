import React, { useState, useEffect } from "react";
import * as apiClient from "../../common/apiClient";
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

import { GridDetail } from './GridDetail';
import { Button, TextField } from "@material-ui/core";

export const GoodInwardOrderEdit = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect = useRedirect();
  const [gridData, setGridData] = useState([{ lineNumber: 1, productCode: null, productName: null, uomId: 0, price: null, qty: null, totalAmount: null, attributes: [],uomData:[] }]);
  const[isLoading,SetIsLoading]=useState(true)
  const[transactionNo,SettransactionNo]=useState("")
  const[facilityId,SetFacilityId]=useState(0)
  const[clientId,SetClientId]=useState(0)
  const[goodInwardOrdType,SetGoodInwardOrdType]=useState(0)

  
 
  useEffect(() => { setGridData(gridData) }); 
  useEffect(() => { loadData() }, []); 

  const loadData =  async() => {
    SetIsLoading(true)
    await apiClient.getGoodInwardOrder(props.id).then(res => {
       //let data=JSON.parse(res[0].data.lineItems)
      setGridData(JSON.parse(res[0].data)[0].lineItems)
      SettransactionNo(JSON.parse(res[0].data)[0].goodInwardNo)
      SetIsLoading(false)
    }).catch(err => {
      console.log(err);
      SetIsLoading(false)
    });
    
  }
  const handleChange = (indata) => {

    setGridData(indata);
  }

 
  const handleSubmit = async (formdata) => {
    //alert("c");
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }

    let data: any = {};
    data.id = parseInt(props.id);
    data.facilityId = formdata.facilityId;
    data.goodInwardOrdType = formdata.goodInwardOrdType;
    data.clientId = formdata.clientId;
    data.vendorId = formdata.vendorId;
    data.documentNo = formdata.documentNo;
    data.goodInwardDate=formdata.goodInwardDate;
    data.remarks = formdata.remarks;
    if(formdata.invoiceNo!=null)
      {
        data.invoiceNo=formdata.invoiceNo;
      }

      if(formdata.invoiceDate!=null)
        {
          data.invoiceDate=formdata.invoiceDate;
          data=apiClient.padDate(data,"invoiceDate");  
        }

        if(formdata.grNo!=null)
          {
            data.grNo=formdata.grNo; 
          }

          
        if(formdata.carrierId!=null)
          {
            data.carrierId=parseInt(formdata.carrierId); 
          }
          if(formdata.noOfBox!=null)
            {
              data.noOfBox=formdata.noOfBox; 
            }

            if(formdata.payTypeId!=null)
              {
                data.payTypeId=parseInt(formdata.payTypeId); 
              }
              if(formdata.bookingCity!=null)
                {
                  data.bookingCity=formdata.bookingCity; 
                }
                if(formdata.freightCharge!=null)
                  {
                    data.freightCharge=parseFloat(formdata.freightCharge); 
                  }
                  if(formdata.saleRepId!=null)
                    {
                      data.saleRepId=parseInt(formdata.saleRepId); 
                    }
              
              
    data.lineItems = [...gridData];
    // let m = moment(new Date());

    // data. modifyDate=  m.toISOString()
    data.createdBy = userId
    apiClient.addeditGoodInwardOrder(data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/good-inward-orders");
      }
      else {
        notify(res[0].remarks, "warning")

      }
      //console.log(res);
      //props.onClose();
    }).catch(err => {
      console.log(err);
    });
  }
  return (
    <Edit title="Good Inward Order Edit" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} toolbar={<FormToolbar onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >
        <div className="transaction-header">
        Good Inward Order Detail ::-{transactionNo}
        
          
        
        </div>
        
        <ReferenceInput label="Facility" fullWidth={true} formClassName={classes.one_4_input}
          perPage={5000} source="facilityId"
          reference="facilities" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput label="Good Inward Order Type" fullWidth={true} formClassName={classes.two_4_input}
          perPage={5000} source="goodInwardOrdType"
          filter={{ type: 'IT' }}
          reference="lookups" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput> 
        
        <ReferenceInput label="Client" fullWidth={true} formClassName={classes.three_4_input}
          perPage={5000} source="clientId"
          reference="clients" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <FormDataConsumer fullWidth={true} formClassName={classes.last_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType != 165 )?
             (
              <ReferenceInput label="Vendor" fullWidth={true} formClassName={classes.last_4_input}
              perPage={5000} source="vendorId"
              reference="vendors" >
              {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
            </ReferenceInput> 
            
            ):<ReferenceInput label="Customer" fullWidth={true} formClassName={classes.last_4_input}
            perPage={5000} source="vendorId"
            reference="customers" >
            {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
          </ReferenceInput> 
          }
        </FormDataConsumer>
        
        
        <DateInput source={"goodInwardDate"} label={"Good Inward  Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_4_input} />
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.two_4_input} />
        <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true} formClassName={classes.three_4_input} />
        <FormDataConsumer fullWidth={true} formClassName={classes.last_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (
              <TextInput source="invoiceNo" label="Original Invoice No" initialValue={""} fullWidth={true} formClassName={classes.last_4_input}  variant="outlined"/> 
            
            ):null
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} formClassName={classes.one_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (
              <DateInput source={"invoiceDate"} label={"Original Inward Date"} fullWidth={true}  variant="outlined" formClassName={classes.one_4_input} />
            
            ):null
          }
        </FormDataConsumer>

        
        <FormDataConsumer fullWidth={true} formClassName={classes.two_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (
              <ReferenceInput label="Carrier" fullWidth={true} formClassName={classes.two_4_input}
              perPage={5000} source="carrierId"  variant="outlined" sort={{ field: 'name', order: 'ASC' }}
                reference="carriers" >
              <SelectInput optionText="name"  />
        </ReferenceInput>
            
            ):null
          }
        </FormDataConsumer>

        
         

        <FormDataConsumer fullWidth={true} formClassName={classes.three_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (
              <TextInput source="grNo" label="Gr No" initialValue={""} fullWidth={true} formClassName={classes.three_4_input}  variant="outlined"/> 
            
            ):null
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} formClassName={classes.last_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (
              <NumberInput source="noOfBox" label="No Of Boxes" fullWidth={true} formClassName={classes.last_4_input}  variant="outlined"/> 
            
            ):null
          }
        </FormDataConsumer>


        <FormDataConsumer fullWidth={true} formClassName={classes.one_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (              
        <ReferenceInput label="Pay Type" source="payTypeId" filter={{ type: 'PT' }} fullWidth={true} 
        reference="lookups"    formClassName={classes.one_4_input} > 
          <SelectInput optionText="name" variant="outlined" fullWidth={true} /> 
      </ReferenceInput>
            
            ):null
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} formClassName={classes.two_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (              
              <NumberInput source="freightCharge" label="Freight Charge" fullWidth={true} formClassName={classes.two_4_input}  variant="outlined"/> 
            
            ):null
          }
        </FormDataConsumer>


        
        <FormDataConsumer fullWidth={true} formClassName={classes.three_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (              
              <TextInput source="bookingCity" label="Booking City" initialValue={""} fullWidth={true} formClassName={classes.three_4_input}  variant="outlined"/> 
            
            
            ):null
          }
        </FormDataConsumer>
     
        <FormDataConsumer fullWidth={true} formClassName={classes.last_4_input}>
          {({ formData, ...rest }) =>
             (formData.goodInwardOrdType &&
            formData.goodInwardOrdType == 165 )?
             (              
              <ReferenceInput label="Sale Rep" fullWidth={true} formClassName={classes.last_4_input}
              perPage={5000} source="saleRepId"  variant="outlined" sort={{ field: 'name', order: 'ASC' }}
                reference="sale-reps" >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>
            
            
            ):null
          }
        </FormDataConsumer>

     
        {!isLoading &&  <FormDataConsumer fullWidth={true} >
          {formDataProps => (
           <GridDetail goodInwardOrdType={goodInwardOrdType} {...formDataProps} gridData={gridData} handleChange={handleChange} />
          )}
        </FormDataConsumer>}

     
        <FormDataConsumer fullWidth={true} formClassName={classes.one_4_input}>
          {({ formData, ...rest }) =>
             {
                SetFacilityId(formData.facilityId);
                SetClientId(formData.clientId);
                SetGoodInwardOrdType(formData.goodInwardOrdType);
             }
          }
                  </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
