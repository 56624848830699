import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const AttributeCreate = props => {
    const classes = useStyles({});
    const [tpPremiumAmt, setTpPremiumAmt] = useState(0);
    const [odPremiumAmt, setOdPremiumAmt] = useState(0);
    const [IsMultiPolicy, setIsMultiPolicy] = useState(null);
    const [IsinsurerId, setinsurerId] = useState(-1);

    const [net, setNet] = useState(0);
    const initialValues = {
        attributeGroupId: props.attributeGroupId,

    }
 

    const handleSubmit = (formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }
        let data = {
            attributeGroupId: parseInt(props.attributeGroupId), 
            name: formdata.name, 
            isActive: formdata.isActive,
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(),

        }
        apiClient.addEntiyRecord("attribute-group-dtls", data).then(res => {
            
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Create {...props} >
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">

 
                <TextInput source="name" fullWidth={true} validate={[required()]} />

                <BooleanInput source="isActive" validate={[required()]} label="Active" fullWidth={true} initialValue={true} />





            </SimpleForm>
        </Create>
    );
}
export default AttributeCreate;