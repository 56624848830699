import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const ProductAttributes = (props) => {
    const classes = useStyles({});
    const [attributeData, setAttributeData] = useState([])


    useEffect(() => {
        debugger
        let _data = [];
        _data = [...props.gridData];
        //let item = _data[props.selectedIndex];
        let aData = _data[props.selectedIndex]["attributes"];
        setAttributeData([...aData])
    }, []);

    const handleChange = (event, index) => {
        let adata = [...attributeData];
        adata[index]["attributeVal"] = event.target.value;
        setAttributeData(adata)
    }
    const handleSubmit = (formdata) => {


        props.onClose(attributeData,'attribute');

    }
    return (
        <Create {...props} >
            <SimpleForm
                onSubmit={handleSubmit}

                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <div className="transaction-detail ">
                    Product Attributes
                </div>
                {attributeData.length > 0 && <div>
                    {attributeData.map((item, index) => {
                        return <div style={{ width: "100%" }}>
                            {attributeData[index]["attributeType"] === "Text" && <TextInput source={"code" + index} label={item.name} fullWidth={true} validate={[required()]} variant="outlined" initialValue={item.attributeVal} onChange={(event) => handleChange(event, index)} />}
                            {attributeData[index]["attributeType"] === "Number" && <NumberInput source={"code" + index} label={item.name} fullWidth={true} validate={[required()]} variant="outlined" initialValue={item.attributeVal} onChange={(event) => handleChange(event, index)} />}
                            {attributeData[index]["attributeType"] === "Numeric" && <NumberInput source={"code" + index} label={item.name} fullWidth={true} validate={[required()]} variant="outlined" initialValue={item.attributeVal} onChange={(event) => handleChange(event, index)} />}
                            {attributeData[index]["attributeType"] === "Date" && <DateInput source={"code" + index} label={item.name} fullWidth={true} validate={[required()]} variant="outlined" initialValue={item.attributeVal} onChange={(event) => handleChange(event, index)} />}
                            {attributeData[index]["attributeType"] === "List" && <ReferenceInput label={item.name} source={"code" + index} reference="attribute-group-dtls" fullWidth={true} formClassName={classes.one_three_input} variant="outlined" defaultValue={parseInt(item.attributeVal)}
                                filter={{ attributeGroupId: parseInt(item.attributegroupId) }}
                                validate={[required()]} 
                                onChange={(event) => handleChange(event, index)} >
                                <SelectInput optionText="name" validate={[required()]} variant="outlined" fullWidth={true} formClassName={classes.one_three_input} defaultValue={parseInt(item.attributeVal)} />
                            </ReferenceInput>}
                        </div>
                    })}

                </div>
                }



            </SimpleForm>
        </Create>
    );
}
export default ProductAttributes;