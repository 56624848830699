import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import { Create, SimpleForm, TextInput, BooleanInput, DateInput, useNotify, NumberInput, required, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

// import "./report.css";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    dialogPaper: {
        minWidth: '90%',
        maxWidth: '90%',
        margin: 'auto'
    }
}));

export const MasterReport = (props: any) => {
    // const classes = useStyles();
    const classes = useStyles({});
    const notify = useNotify();
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');
    const [customerId, setCustomerId] = useState<any>("");
    const [vendorId, setVendorId] = useState<any>("");

    const [rtype, setRType] = useState<any>(null);
    const [productId, setProductId] = useState<any>(null);

    const [saleRepId, setSaleRepId] = useState<any>(0);

    const [facilityId, setFacilityId] = useState<any>(0);
    const [clientId, setClientId] = useState<any>(0);
    const [orderType, setOrderType] = useState<any>(0);

    const [open, setOpen] = useState(false);
    const [popupContent, setPopupContent] = useState("");


    const openPopup = (selectedRow) => {

        apiClient.viewdocumentinHTML(selectedRow.transactionId, selectedRow.transactionTypeId, props.rpt).then(res => {

            setPopupContent(res[0].HTMLString);
            setOpen(true);
        }).catch(err => {
            console.log(err);
        });
    }
    const generateReport = () => {
        setFectched(false);

        let sDate = moment(startdate).format('YYYY-MM-DD');
        let eDate = moment(enddate).format('YYYY-MM-DD');
        if (startdate === "") {
            sDate = "";
        }
        if (enddate === "") {
            eDate = "";
        }
        let userid = localStorage.getItem("userId");
        let data = {
            reportType: props.rpt,
            userId: userid,
            customerId: null,
            productId: null,
            rtype: null,
            fromDate: sDate,
            toDate: eDate,
            vendorId: null,
            facilityId: null,
            clientId: null,
            saleRepId: null,
            asOnDate: sDate,
            orderType: orderType

        };
        if (customerId != null && customerId != undefined && customerId > 0) {
            data.customerId = customerId;
        }
        if (vendorId != null && vendorId != undefined && vendorId > 0) {
            data.vendorId = vendorId;
        }
        if (productId != null && productId != undefined && productId > 0) {
            data.productId = productId;
        }
        if (facilityId != null && facilityId != undefined && facilityId > 0) {
            data.facilityId = facilityId;
        }
        if (saleRepId != null && saleRepId != undefined && saleRepId > 0) {
            data.saleRepId = saleRepId;
        }

        if (clientId != null && clientId != undefined && clientId > 0) {
            data.clientId = clientId;
        }
        debugger
        if (rtype != null && rtype != undefined) {
            data.rtype = rtype;
        }
        if (data.facilityId == 0) {
            notify("Please Select Facility ", "warning")
            return;

        }
        if (data.clientId == 0) {
            notify("Please Select Client ", "warning")
            return;

        }
        if (props.rpt == "saleledger") {
            if (data.customerId == null || data.customerId == undefined || data.customerId == 0) {
                {
                    notify("Please enter customer ", "warning")
                    return;
                }
            }
            if (data.rtype == null || data.rtype == undefined) {
                {
                    notify("Please select Report Type ", "warning")
                    return;
                }
            }
        }
        if (props.rpt == "stock") {

            if (data.rtype == null || data.rtype == undefined) {
                {
                    notify("Please select Report Type ", "warning")
                    return;
                }
            }
        }

        if (props.rpt == "itemledger") {
            if (data.productId == null || data.productId == undefined || data.productId == 0) {
                {
                    notify("Please enter Product ", "warning")
                    return;
                }
            }

        }


        setLoading(true);

        apiClient.loadReport("/report/masterreport", data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    const printReport = () => {
        let sDate = moment(startdate).format('YYYY-MM-DD');
        let eDate = moment(enddate).format('YYYY-MM-DD');
        if (startdate === "") {
            sDate = "";
        }
        if (enddate === "") {
            eDate = "";
        }
        let userid = localStorage.getItem("userId");
        let data = {
            reportType: props.rpt,
            userId: userid,
            customerId: null,
            productId: null,
            rtype: null,
            fromDate: sDate,
            toDate: eDate,
            vendorId: null,
            facilityId: null,
            clientId: null,
            saleRepId: null,
            transactionId: 0,
            processtypeId: 0,
            rptFrom: props.rpt

        };

        if (customerId != null && customerId != undefined && customerId > 0) {
            data.customerId = customerId;
        }
        if (vendorId != null && vendorId != undefined && vendorId > 0) {
            data.vendorId = vendorId;
        }
        if (productId != null && productId != undefined && productId > 0) {
            data.productId = productId;
        }
        if (facilityId != null && facilityId != undefined && facilityId > 0) {
            data.facilityId = facilityId;
        }
        if (saleRepId != null && saleRepId != undefined && saleRepId > 0) {
            data.saleRepId = saleRepId;
        }

        if (clientId != null && clientId != undefined && clientId > 0) {
            data.clientId = clientId;
        }
        debugger
        if (rtype != null && rtype != undefined) {
            data.rtype = rtype;
        }


        {
            if (data.customerId == null || data.customerId == undefined || data.customerId == 0) {
                {
                    notify("Please enter customer ", "warning")
                    return;
                }
            }

        }




        apiClient.viewdocumentinHTML_V1(data).then(res => {

            setPopupContent(res[0].HTMLString);
            setOpen(true);
        }).catch(err => {
            console.log(err);
        });
    }



    const printContent = () => {
        const printWindow: any = window.open('', '', 'width=800,height=600');


        printWindow.document.write(popupContent);

        printWindow.document.close();
        printWindow.print();
    }
    return (<>
        <SimpleForm toolbar={null}>
            <div style={{ width: "100%" }} >
                <div className="report-heading">
                    {props.rptTtile}
                </div>
                <div className="filters">
                    <div className="filter-row" >

                    </div>
                </div>
                <div className="filters">
                    <div className="filter-row" >


                        {(props.rpt == "saleledger" || props.rpt == "inwardledger" || props.rpt == "accountledger" || props.rpt == "itemledger") && <> <div className="filter-column"  >
                            <DateInput source="startDate" variant="outlined" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} />
                        </div>
                            <div className="filter-column" >
                                <DateInput source="endDate" variant="outlined" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} />
                            </div></>}
                        {(props.rpt == "stock") && <> <div className="filter-column" >
                            <DateInput source="startDate" variant="outlined" label="Stock As On" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} />
                        </div>
                        </>}

                        {props.rpt == "stock" && <div className="filter-column" >

                            {props.rpt == "stock" && <SelectInput
                                validate={[required()]}
                                onChange={(event) =>

                                    setRType(event.target.value)
                                }
                                variant="outlined"
                                source="Report Type"
                                choices={[
                                    { id: 'consolidated', name: 'Consolidated' },
                                    { id: 'binwise', name: 'Bin Wise' },
                                    { id: 'binwisezero', name: 'Include Zero Stock Bin Wise' },
                                    

                                ]} />}

                        </div>}

                        {props.rpt == "saleledger" && <div className="filter-column" >

                            {props.rpt == "saleledger" && <SelectInput
                                validate={[required()]}
                                onChange={(event) =>

                                    setRType(event.target.value)
                                }
                                variant="outlined"
                                source="Report Type"
                                choices={[
                                    { id: 'itemwise', name: 'Item Wise' },
                                    { id: 'invoicewise', name: 'Invoice Wise' },

                                ]} />}

                        </div>}



                        {props.rpt == "stock" && <div className="filter-column" ></div>}

                        {props.rpt != "accountledger" && props.rpt != "aegingreport" && <div className="filter-column" > <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            onChange={(e) => { setFacilityId(e); }}
                            variant="outlined"
                            label="Facility" source="facilityId" reference="facilities" fullWidth={true} >

                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput></div>}

                        {props.rpt != "accountledger" && props.rpt != "aegingreport" && <div className="filter-column" ><ReferenceInput
                            perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            onChange={(e) => { setClientId(e); }}

                            label="Client" source="clientId" reference="clients" fullWidth={true} >

                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput></div>}
                        {(props.rpt == "saleledger" || props.rpt == "accountledger" || props.rpt == "aegingreport") && <div className="filter-column" >

                            {(props.rpt == "saleledger" || props.rpt == "accountledger" || props.rpt == "aegingreport") && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                                filterToQuery={searchText => ({ 'name~like': searchText })}
                                onChange={(data) => { setCustomerId(data); }}
                                label="Customer" source="customerId" reference="customers" fullWidth={true} >
                                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                            </ReferenceInput>
                            }
                        </div>}
                        {(props.rpt == "accountledger" || props.rpt == "aegingreport") && <div className="filter-column" >

                            {(props.rpt == "accountledger" || props.rpt == "aegingreport") && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                                filterToQuery={searchText => ({ 'code~like': searchText })}
                                onChange={(e) => { setClientId(e.target.value); }}
                                variant="outlined"
                                label="Client" source="clientId" reference="clients" fullWidth={true} >

                                <SelectInput optionText="name" />
                            </ReferenceInput>
                            }
                        </div>}
                        {(props.rpt == "accountledger") && <div className="filter-column" >

                            {(props.rpt == "accountledger") && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                                filterToQuery={searchText => ({ 'code~like': searchText })}
                                onChange={(e) => { setSaleRepId(e); }}
                                variant="outlined"
                                label="Sale Reps" source="saleRepId" reference="sale-reps" fullWidth={true} >

                                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                            </ReferenceInput>
                            }
                        </div>}

                        {(props.rpt == "inwardledger") && <div className="filter-column" >

                            {(props.rpt == "inwardledger") && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                                filterToQuery={searchText => ({ 'name~like': searchText })}
                                onChange={(data) => { setVendorId(data); }}
                                label="Vendor" source="vendorId" reference="vendors" fullWidth={true} >
                                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                            </ReferenceInput>
                            }
                        </div>}



                        {props.rpt == "saleledger" && <div className="filter-column" >

                            {props.rpt == "saleledger" && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                                filterToQuery={searchText => ({ 'name~like': searchText })}
                                onChange={(data) => { setOrderType(data); }}
                                filter={{ type: 'OT' }}
                                label="Sale Type" source="orderTypeId" reference="lookups" fullWidth={true} allowEmpty>
                                <AutocompleteInput optionText="name" variant="outlined"
                                    shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                            </ReferenceInput>}

                        </div>}

                        {(props.rpt == "itemledger" || props.rpt === "stock" || props.rpt == "saleledger" || props.rpt == "inwardledger") && <div className="filter-column" >

                            {(props.rpt == "itemledger" || props.rpt === "stock" || props.rpt == "saleledger" || props.rpt == "inwardledger") && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                                filterToQuery={searchText => ({ 'name~like': searchText })}
                                onChange={(data) => { setProductId(data); }}
                                label="Product Name" source="productId" reference="products" fullWidth={true} >
                                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                            </ReferenceInput>
                            }
                        </div>}

                        {(props.rpt == "itemledger" || props.rpt == "stock" || props.rpt == "saleledger" || props.rpt == "inwardledger") && <div className="filter-column" >

                            {(props.rpt == "itemledger" || props.rpt === "stock" || props.rpt == "saleledger" || props.rpt == "inwardledger") && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                                filterToQuery={searchText => ({ 'code~like': searchText })}
                                onChange={(data) => { setProductId(data); }}
                                label="Product Code" source="productId" reference="products" fullWidth={true} >
                                <AutocompleteInput optionText="code" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                            </ReferenceInput>
                            }
                        </div>}




                    </div>
                </div>



                {fectched && data.length > 0 && <div className="filter-action" >Total {data.length} Records found</div>}
                {fectched && data.length == 0 && <div className="filter-action" >No Record found</div>}




                <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
                </div>
                <div className="filter-action">
                    <Button variant="contained"
                        onClick={() => {
                            generateReport();
                        }}
                        startIcon={<AssessmentIcon />}
                        color="primary">
                        Generate Report
                    </Button>
                    {props.rpt == "accountledger" && data && data.length > 0 && <Button style={{ marginLeft: 10 }} variant="contained"
                        onClick={() => {
                            printReport();
                        }}
                        startIcon={<AssessmentIcon />}
                        color="primary">
                        Print Priview
                    </Button>}
                </div>
                <div className="report-data">

                    {isLoading && <CircularProgress />}

                    <div className="w3-responsive">

                        <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                            <tr>
                                {props.rpt != "accountledger" && cols && cols.map((item, index) => {
                                    return (
                                        <th key={item}>{item}</th>
                                    )
                                })}
                                {props.rpt == "accountledger" && cols && cols.map((item, index) => {
                                    return (item != 'transactionId' && item != 'transactionTypeId' ? <th key={item}>{item}</th> : null)
                                })}
                                {props.rpt == "accountledger" && cols && cols.map((item, index) => {
                                    return (item == 'transactionId' ? <th key={item}>{"Action"}</th> : null)
                                })}
                            </tr>

                            {data && data.map((row, rowIndex) => {
                                return (
                                    <tr key={rowIndex}>
                                        {cols && cols.map((col, colIndex) => {
                                            return (col != 'transactionId' && col != 'transactionTypeId' ? <td key={rowIndex + "-" + colIndex}> {row[col]} </td> : null)

                                        })}
                                        {props.rpt == "accountledger" ? <td key={rowIndex + "-" + rowIndex}><div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => openPopup(row)} > View Detail</div> </td> : null}
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
            </div>

        </SimpleForm>
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="popup-title" classes={{ paper: classes.dialogPaper }}
            maxWidth="md"


        >
            <DialogTitle id="popup-title">Details</DialogTitle>
            <DialogContent dividers dangerouslySetInnerHTML={{ __html: popupContent }}>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Close
                </Button>
                <Button onClick={printContent} color="primary">
                    Print
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
};