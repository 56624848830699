
import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../common/apiClient";
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    header: {
        fontSize: 16,
        marginBottom: 10,
        textAlign: 'center',
        textDecoration: 'underline',
    },
    subheader: {
        fontSize: 16,
        marginBottom: 5,
    },
    headerRow:
    {
        flexDirection: "row",
        border: 1, 
        marginTop:1,
        fontSize: 11,
        
    },
    cellLabel: {
        flex: 1,
        padding: 5,
        fontSize: 12,
        borderRight: 1, 
        
    },
    cellValue: {
        flex: 1,
        padding: 5,
        fontSize: 12,
        width: "25%",
    },
    
    headerColumn:
    {

        height: "100",
        borderLeft: 1,
        width: "30%",
        fontSize: 11
    },
    text: {
        fontSize: 12,
        marginBottom: 10,
    },
    table: {
        display: 'table',
        width: '100%',
        marginBottom: 10,
        borderTop: 1,
        borderLeft: 1,
        borderRight: 1,
        marginTop:10
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: 0.5,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
        marginBottom: 2,
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    tableCell: {
        flex: 1,
        padding: 5,
        fontSize: 12,
        
    },
    totalRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        borderTop: 1,
        borderTopColor: '#000',
        borderTopStyle: 'solid',
        paddingTop: 5,
    },
});

const Invoice = ({ customer, order, items, data }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    
                    <Text style={styles.header}>Dispatch / Gate Pass</Text>
                    <View style={styles.headerRow} >
                    
                    <Text style={styles.cellLabel}>Client : </Text>
                    <Text style={styles.cellValue}>{data[0].clientName}</Text>
                    
                    </View>
                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Carrier : </Text>
                    <Text style={styles.cellValue}>{data[0].carrierName}</Text> 
                    </View>

                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Facility : </Text>
                    <Text style={styles.cellValue}>{data[0].facilityName}</Text> 
                    </View>
                    
                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Gate Pass Number : </Text>
                    <Text style={styles.cellValue}>{data[0].dispatchOrderNo}</Text> 
                    </View>
                    
                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Gate Pass Date : </Text>
                    <Text style={styles.cellValue}>{data[0].dispatchOrderDate}</Text> 
                    </View>

                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Vehicle No: </Text>
                    <Text style={styles.cellValue}>{data[0].vehicleNo}</Text> 
                    </View>
                    
                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Driver Name: </Text>
                    <Text style={styles.cellValue}>{data[0].driverName}</Text> 
                    </View>
                    
                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Driver Contact No: </Text>
                    <Text style={styles.cellValue}>{data[0].driverContactNo}</Text> 
                    </View>

                    <View style={styles.headerRow} > 
                    <Text style={styles.cellLabel}>Total Box: </Text>
                    <Text style={styles.cellValue}>{data[0].totalBox}</Text> 
                    </View> 

                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={styles.tableCell}>Order No</Text>
                            <Text style={styles.tableCell}>Customer</Text>
                            <Text style={styles.tableCell}>No of Box</Text>

                        </View>

                        {data[0].lineItems.map(item => (
                            <View key={item.id} style={styles.tableRow}>
                                <Text style={styles.tableCell}>{item.orderNo}</Text>
                                <Text style={styles.tableCell}>{item.customerName}</Text>
                                <Text style={styles.tableCell}>{item.noOfBox}</Text>
                            </View>
                        ))}
                    </View>

                    {/* <View style={styles.totalRow}>
              <Text style={{ ...styles.tableCell, ...styles.tableHeader }}>Total Box:</Text>
              <Text style={styles.tableCell}>
                {items.reduce((total, item) => total + item.qty * item.price - item.discount, 0).toFixed(2)}
              </Text>
            </View> */}
                </View>
            </Page>
        </Document>
    );
};
const DispatchPrint = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [finalData, setFinalData] = useState([]);

    useEffect(() => {

        LoadData(props.record)
    }, []);
    const LoadData = async (id) => {

        await apiClient.globalPrint(id, 'dispatchorder').then(res => {
            console.log(res)
            let data = res;
            setFinalData(JSON.parse(data[0].data));
            setLoading(false);

        }).catch(err => {
            setLoading(false);
            console.log(err);
        });

    }
    const customer = {
        name: 'John Doe',
        address: '123 Main St, Cityville, USA',
    };

    const order = {
        number: '12345',
        date: '2023-11-08',
    };

    const items = [
        {
            id: 1,
            productName: 'Product 1',
            productCode: 'P001',
            qty: 2,
            price: 10,
            discount: 2,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
        {
            id: 2,
            productName: 'Product 2',
            productCode: 'P002',
            qty: 3,
            price: 15,
            discount: 3,
        },
    ];

    return (
        <>
            <div>
                <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
            </div>
            {!isLoading && <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Invoice customer={customer} order={order} items={items} data={finalData} />
            </PDFViewer>}
        </>

    );
};

export default DispatchPrint;
