import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { Button } from '@material-ui/core';
import * as apiClient from '../../common/apiClient';

export const CustomerField = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    
    const loadCustmerDetail = async (id) => {
    
       const customer=  await apiClient.getCustomerData(id);
       if(customer && customer.length>0)
       {
        
        form.change("shipAddress1", customer[0].name +' '+ customer[0].shipAddress1);
        form.change("shipAddress2", customer[0].shipAddress2);
        form.change("shipZipCode", customer[0].shipZipCode);
        form.change("shipStateId", customer[0].shipStateId);
        
       }
    }
    
    //customers?filter[where][id]=507
    // onClick={(v) => { 
                

    //     form.change("shipAddress1", formData.billAddress1);
    //     form.change("shipAddress2", formData.billAddress2);
    //     form.change("shipZipCode", formData.billZipCode);
    //     form.change("shipCountryId", formData.billCountryId);
    //     form.change("shipRegionId", formData.billRegionId);
    //     form.change("shipStateId", formData.billStateId);
    //     form.change("shipDistrictId", formData.billDistrictId); 
    //     form.change("shipCityId", formData.billCityId);
    // }}
    return (
        <ReferenceInput label="Customer" fullWidth={true} formClassName={classes.four_5_input}
                            perPage={5000} source="customerId"
                            onChange={(event)=>loadCustmerDetail(event)}
                            sort={{ field: 'name', order: 'ASC' }}
                            disabled
                            reference="customers"  >
                            {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
                            <SelectInput variant="outlined"  optionText="name"/>

                          </ReferenceInput>  
    )
}
