import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  DateInput,FormDataConsumer
} from "react-admin";
import Customfields from "./Customfields";
import { FormToolbar } from "../../common/FormToolbar";
import CustomSelectInput from "./CustomSelectInput";


export const RateMasterEdit = (props) => {
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  
  return (
    <Edit {...propsObj}  title="Rate Master Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <TextInput source="name"  fullWidth={true} label="Code"  validate={[required()]}/>
      <ReferenceInput label="Rate Type"  validate={[required()]} fullWidth={true} source="rateTypeId" reference="lookups" perPage={5000} 
                    filter={{ type: 'RT' }}
                    >
            <SelectInput optionText="name"  />
        </ReferenceInput>
       
        <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="policy-departments" selectedText={"--All--"} source={"policyDepartmentId"}  optionText={"name"} label={"Policy Department"} fullWidth={true} {...formDataProps} 
                
                dependentFields={["policyTypeId"]}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CustomSelectInput reference="policy-types" selectedText={"--All--"} source={"policyTypeId"}  optionText={"name"} label={"Policy Type"} fullWidth={true} {...formDataProps} 
                filters={[{"policyDepartmentId":formDataProps.policyDepartmentId}]}
                />
              )}
            </FormDataConsumer>
          
        <NumberInput  variant="outlined" label="TP Rate Percentage" source="tpRatePercentage" fullWidth={true}  />
        <NumberInput  variant="outlined" label="Brok Rate Percentage" source="brokRatePercentage" fullWidth={true}  />
        <DateInput label="Effective From" source="effectiveFrom" fullWidth={true} /> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Edit>
  );
};
