import React, { useState,useEffect } from "react";
import * as apiClient from "../../common/apiClient"; 
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

import { GridDetail } from './GridDetail';

export const PurchaseOrderCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect=useRedirect();
  
const [gridData, setGridData] = useState([{ lineNumber: 1, productCode:null , productName: null, uomId: 0, price: null, qty: null, totalAmount: null,attributes:[]}]);



  useEffect(() => {
  setGridData(gridData)
  
  });
  
  const handleChange = (indata) => { 
     setGridData(indata);
  }

  const handleSubmit = async (formdata) => {
        //alert("c");
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }

    let data:any={};
    data.id=0;
    data.facilityId=formdata.facilityId;
    data.purchaseOrdType=formdata.purchaseOrdType;
    data.clientId=formdata.clientId;
    data.vendorId=formdata.vendorId;
    data.documentNo=formdata.documentNo;
    data.purchaseDate=formdata.purchaseDate;

    data.remarks=formdata.remarks; 
    data.lineItems=[...gridData];
    // let m = moment(new Date());
    
    // data. modifyDate=  m.toISOString()
     data.createdBy=  userId
    apiClient.addeditPurchaseOrder( data).then(res => {
      if(res[0].id>0)
      {
        notify(res[0].remarks)
        redirect("/purchase-orders");
      }
      else
      {
        notify(res[0].remarks,"warning")

      }
        //console.log(res);
        //props.onClose();
    }).catch(err => {
        console.log(err);
    });
}
  return (
    <Create title="Purchase Order Create" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} toolbar={<FormToolbar onSave={handleSubmit}  {...props} showDelete={false} showCancel={false} onCancel={()=>{ redirect("/purchase-orders");}} />} variant="outlined" >
        <div className="transaction-header">
          Purchase Order Detail
        </div>
        <ReferenceInput label="Facility" fullWidth={true} formClassName={classes.one_4_input}
          perPage={5000} source="facilityId"
          reference="facilities" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput label="Purchase Order Type" fullWidth={true} formClassName={classes.two_4_input}
          perPage={5000} source="purchaseOrdType"
          filter={{ type: 'PO' }}
          reference="lookups" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput> 
        <ReferenceInput label="Client" fullWidth={true} formClassName={classes.three_4_input}
          perPage={5000} source="clientId"
          reference="clients" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput label="Vendor" fullWidth={true} formClassName={classes.last_4_input}
          perPage={5000} source="vendorId"
          reference="vendors" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>  
        <DateInput source={"purchaseDate"} label={"Purchase Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_4_input} />
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.two_4_input} />
        <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true} formClassName={classes.three_4_input} />
        <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail {...formDataProps} gridData={gridData}  handleChange ={handleChange}/>
              )}
            </FormDataConsumer>
             
            
              </SimpleForm>
    </Create>
  );
};
