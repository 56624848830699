import React,{useState,useCallback} from 'react';
import {useRefresh,NumberField,TextField,ReferenceField,Datagrid,ReferenceManyField, TabbedForm, FormTab, FormDataConsumer, Edit, SimpleForm, TextInput, BooleanInput, NumberInput, required } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { Addressfields } from "./Addressfields";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import FacilityMapCreate from './FacilityMapCreate';
import FacilityMapEdit from './FacilityMapEdit';
import EditIcon from '@material-ui/icons/Edit';
const myStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    listWithDrawer: {
      marginRight: 300,
    },
    drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 400,
    },
    drawerPaper1500: {
      zIndex: 100,
      marginTop: 50,
      width: 1300,
    },
  }));
export const ClientEdit = props => {
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const myclasses = myStyles({});
    const refresh = useRefresh();
    const [IsEditRecord, setIsEditRecord] = useState(null);
    const handleClose = useCallback(() => {
        setIsAdd(false);
        setIsEdit(false);
        
    
      }, []);
      const onCloseDocumentForm = () => {
        setIsAdd(false);
        setIsEdit(false);
        
        refresh();
      }
      const CustomEdit = (props) => {
        let { record } = props;
        return <Button
          color="secondary" disableElevation
          size="medium"
    
          onClick={() => {
            props.onClick(record);
          }}
          startIcon={<EditIcon />}>Edit</Button>
      }
    return (
        <>
        <Edit label="Edit Client" {...props}>
            <TabbedForm redirect="list" toolbar={<FormToolbar {...props} />} variant="outlined">
                <FormTab label="Client  Details">
                    <TextInput source="code" label='Code' fullWidth={true} validate={[required()]} />
                    <TextInput source="name" label='Name' fullWidth={true} validate={[required()]} />
                    <TextInput source="contactPerson" label='Contact Person ' fullWidth={true} validate={[required()]} />
                    <TextInput source="contactNumber" label='Contact Number ' fullWidth={true} validate={[required()]} />
                    <TextInput source="address1" label='Address 1 ' fullWidth={true} />
                    <TextInput source="address2" label='Address 2' fullWidth={true} />
                    <TextInput source="zipCode" label='Pincode' fullWidth={true} />
                    <FormDataConsumer fullWidth={true} >
                        {formDataProps => (
                            <Addressfields {...formDataProps} />
                        )}
                    </FormDataConsumer>

                    <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} />
                </FormTab>
                <FormTab label="Facility Mapping">
            <div>
              <Button onClick={() => { setIsAdd(true); }}>Add Facility Mapping</Button>
            </div>



            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="client-facility-maps"
              target="clientId"> 
              <Datagrid > 
                <CDeleteButton {...props} /> 
                <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEdit(true); }} label="Edit" />   
                 

                <ReferenceField source="facilityId" reference="facilities" label="Facility " link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <TextField  source="gstNo" />
                <TextField  source="panNo" />
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
            </TabbedForm>
        </Edit>
        <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper }}
        open={isEdit || isAdd} >
        {isAdd && <FacilityMapCreate
          onClose={onCloseDocumentForm}
          onCancel={handleClose}
          productId={props.id}

          {...props} />}

        {isEdit && <FacilityMapEdit
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}

          record={IsEditRecord}
          {...props} />

        }
        
      </Drawer>
        </>

    );
}