import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Divider from '@material-ui/core/Divider';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

import VideoCallIcon from '@material-ui/icons/VideoCall';
import LinkIcon from '@material-ui/icons/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StoreIcon from '@material-ui/icons/Store';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink, setSidebarVisibility } from 'react-admin';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MenuContainer } from './MenuContainer';

type MenuName = 'mnuAdminTool' | 'mnuUtility' | 'mnuTestOperations' | 'mnuReports';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        mnuAdminTool: false,
        mnuUtility: false,
        mnuTestOperations: true,
        mnuReports: false
    });
    const [rights, setRights] = useState([]);
    const [categories, setCategories] = useState<string[]>([]);

    const MyOnMenuClick = () => {
        window.scrollTo(0, 0);
        //onMenuClick();
    }

    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(setSidebarVisibility(false));
    // },[]);
    const onMenuClick1 = () => {
        // dispatch(setSidebarVisibility(false));
    }
    let role = localStorage.getItem("role");
    let isSuperAdmin = (role == 'super administrator');
    let isAdministrator = (role == 'system');
    //let isPrincipal = (role == 'principal');
    //let isFaculty = (role == 'faculty');
    //let isStudent = (role == 'student');

    const SetMenuData = () => {
        let menuData = [];
        let menu = localStorage.getItem("menu");
        if (menu) {
            menuData = JSON.parse(menu);
        }
        var outObject = menuData.reduce(function (a:any, e:any) {
            let estKey = (e['ModuleCategory']);
            (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
            return a;
        }, {});
        var keys = Object.keys(outObject);
        setCategories(keys);
        setRights(outObject);
    }

    useEffect(() => {
        SetMenuData();
        console.log("EMPLOYEE LOGGED IN ");
    },[]);
    let uid = localStorage.getItem("userId");
    const url = "/employees/" + uid;
    return (
        <div>
            {' '}
            <DashboardMenuItem onClick={onMenuClick1} sidebarIsOpen={open} />
            {categories && categories.map((item:any,index:number) =>{
                return(
                    <MenuContainer key={index} onMenuClick={MyOnMenuClick} sidebarIsOpen={open} dense={dense} caption={item} items={rights[item]} />
                )
            })}
            <Divider />
            {/* {(isSuperAdmin || isAdministrator) &&
                <SubMenu
                    handleToggle={() => handleToggle('mnuAdminTool')}
                    isOpen={state.mnuAdminTool}
                    sidebarIsOpen={open}
                    name="Masters"
                    icon={<SettingsIcon />}
                    dense={dense}
                >

                    <MenuItemLink
                        to={`/areas`}
                        primaryText="Area"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/tpas`}
                        primaryText="TPA"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/insurers`}
                        primaryText="Insurer"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/corporates`}
                        primaryText="Corporate"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/hospitals`}
                        primaryText="Hospital"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/role-types`}
                        primaryText="Role Types"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        to={`/employees`}
                        primaryText="Employee"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            } */}

            {/* <MenuItemLink
                to={`/realiz-ords`}
                primaryText="Realize order"
                leftIcon={<AssessmentOutlinedIcon />}
                onClick={onMenuClick1}
                sidebarIsOpen={open}
                dense={dense}
            /> */}

            {/* <Divider /> */}
            {/* {(isSuperAdmin || isAdministrator) &&
                <SubMenu
                    handleToggle={() => handleToggle('mnuUtility')}
                    isOpen={state.mnuUtility}
                    sidebarIsOpen={open}
                    name="Utility"
                    icon={<SettingsIcon />}
                    dense={dense}
                >

                    <MenuItemLink
                        to={`/users`}
                        primaryText="User"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/configs`}
                        primaryText="Configration"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>

            }
            <Divider />
            {(isSuperAdmin || isAdministrator) &&
                <SubMenu
                    handleToggle={() => handleToggle('mnuReports')}
                    isOpen={state.mnuReports}
                    sidebarIsOpen={open}
                    name="Reports"
                    icon={<SettingsIcon />}
                    dense={dense}
                >

                    <MenuItemLink
                        to={`/generalquery`}
                        primaryText="General Query"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/hbr`}
                        primaryText="Hospital Bill Report"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/hir`}
                        primaryText="Hospital Invoice Report"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        to={`/hrc`}
                        primaryText="Hospital Reclaim Report"
                        leftIcon={<AssessmentOutlinedIcon />}
                        onClick={onMenuClick1}
                        sidebarIsOpen={open}
                        dense={dense}
                    />



                </SubMenu>

            }



            <Divider />
            <MenuItemLink
                to={`/changepassword`}
                primaryText="Change Password"
                leftIcon={<VpnKeyIcon />}
                onClick={onMenuClick1}
                sidebarIsOpen={open}
                dense={dense}
            /> */}
             <MenuItemLink
                to={`/changepassword`}
                primaryText="Change Password"
                leftIcon={<VpnKeyIcon />}
                onClick={onMenuClick1}
                sidebarIsOpen={open}
                dense={dense}
            /> 
            {logout}
            <Divider />
        </div>
    );
};

export default Menu;
