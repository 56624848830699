
import React, { useEffect, useCallback, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import RevisePickslip from './RevisePickslip';
const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 800,
    },
    drawerPaper1500: {
        zIndex: 100,
        marginTop: 50,
        width: 1300,
    },
}));
const ConfirmPickslip = (props) => {
    const classes = useStyles({});
    const [isLoading, SetIsLoading] = useState(true)
    const [gridData, setGridData] = useState([])
    const [headerData, setHeaderData] = useState(null)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [isEdit, setIsEdit] = useState(null)
    const myclasses = myStyles({});
    useEffect(() => {
        loadData();
    }, []);

    const handleClose = () => {
        setIsEdit(false);
        setSelectedRecord(null);
        loadData();
    };
    const loadData = async () => {

        SetIsLoading(true)
        await apiClient.getPicklistData(props.record.id).then(res => {
            debugger
            if (res && res != null) {
                let data = JSON.parse(res[0].data);
                setHeaderData(data[0]);
                setGridData(data[0].lineItems)
                SetIsLoading(false)
            }

        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });

    }


    const changeBin = async (data, index) => {
        //     let item=gridData[index]
        // await apiClient.updatebininputaway(parseInt(item.lineNumber),parseInt(0),parseInt(data),1).then(res => {
        //     debugger
        //     if (res && res != null) {
        //         if(res[0].id>0 )
        //         {
        //             toast.success("Success  : " + res[0].remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //         }
        //         else
        //         {
        //             toast.error("Validation issue : " + res[0].remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //         }


        //         SetIsLoading(false)
        //     }

        // }).catch(err => {
        //     console.log(err);
        //     SetIsLoading(false)
        // });

    }
    const ConfirmPicking = async () => {

        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        let data = {
            transactionId: props.record.id,
            transactionType: 'ORDER_PICKING',
            createdBy: userId
        }
        await apiClient.confirmPicking(data.transactionId, data.createdBy).then(res => {
            if (res[0].id > 0) {
                props.onClose();

                toast.success(res[0].remarks, {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                // refresh();
            }
            else {

                toast.error(res[0].remarks, {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }).catch(err => {
            toast.error(err, {
                position: "bottom-center",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            //notify(err, "warning")
        });
    }
    const handleSubmit = async (formdata) => {

        // //pending-putaway-data
        // //props.onClose(attributeData,'attribute');

        // //good-inward-orders/confirmputaway/{grnId}/{userId}

        // let userId;
        // userId = localStorage.getItem("userId");
        // if (userId) {
        //     userId = parseInt(userId);
        // }
        // SetIsLoading(true)
        // await apiClient.confirmputaway(parseInt(props.record.id), userId).then(res => {
        //     debugger
        //     if (res && res != null) {
        //         if(res[0].id>0 )
        //         {
        //             toast.success("Success  : " + res[0].Remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //             props.onClose();
        //         }
        //         else
        //         {
        //             toast.error("Validation issue : " + res[0].Remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //         }
        //         SetIsLoading(false)
        //     }

        // }).catch(err => {
        //     console.log(err);
        //     SetIsLoading(false)
        // });

    }
    return (
        <React.Fragment>
            <Create {...props} >
                <SimpleForm
                    onSubmit={handleSubmit}
                    toolbar={null}
                    // toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                    variant="outlined">
                    {!isLoading && <div style={{ minWidth: "100%" }}>
                        <div className="transaction-detail ">
                            Confirm Pick Order No- {headerData.orderNo}

                        </div>
                        <div style={{minHeight:400,maxHeight:400,overflow:'auto'}}>
                        <Table className={classes.customtable} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: 5 }} ></TableCell>
                                    <TableCell style={{ width: 200 }} >Product Code</TableCell>
                                    <TableCell >Product Name</TableCell>
                                    <TableCell >Price</TableCell>
                                    <TableCell >Bin</TableCell>
                                    <TableCell >Qty</TableCell>
                                    <TableCell >Action</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody >
                                {gridData && gridData.map((item, index) => {
                                    return <tr >
                                        <TableCell style={{ width: 5 }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell >
                                            {item.productCode}
                                        </TableCell>
                                        <TableCell >
                                            {item.productName}
                                        </TableCell>

                                        <TableCell >
                                            {item.price}
                                        </TableCell>
                                        <TableCell >
                                            {item.binName}
                                        </TableCell>
                                        <TableCell >
                                            {item.qty}
                                        </TableCell>
                                        <TableCell >
                                            <Button style={{ marginTop: 4 }} color="primary" onClick={() => { setIsEdit(true); setSelectedRecord(item) }} variant="contained" > Change </Button>
                                        </TableCell> 
                                    </tr>
                                })}
                            </TableBody>
                        </Table>
                        </div>
                        <Button style={{ marginTop: 4 }} color="primary" onClick={() => { ConfirmPicking(); }} variant="contained" > Confirm Picking </Button>
                        <Button style={{ marginTop: 4, marginLeft: 4 }} color="primary" onClick={() => { props.onClose(); }} variant="contained" > Back </Button>
                    </div>}
                </SimpleForm>
            </Create>
            <Drawer
                anchor="right"
                onClose={handleClose}
                classes={{ paper: myclasses.drawerPaper1500 }}
                open={isEdit}  >
                {selectedRecord && isEdit && <RevisePickslip
                    on_Close={handleClose}
                    on_Cancel={handleClose}
                    record={selectedRecord}
                    orderId={props.record.id}
                    pickdetail={selectedRecord}
                    {...props}
                />}

            </Drawer>
        </React.Fragment>

    );
}
export default ConfirmPickslip;