import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import { useStyles } from "../../formStyles";
export const RegionEdit = (props) => {
  const classes = useStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <Edit title="Region Edit" {...propsObj}>

<SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}>

<TextInput source="code" label="Code" validate={[required()]}  fullWidth={true}  />
            <TextInput source="name" label="Name"  validate={[required()]} fullWidth={true}  />
                 <ReferenceInput label="Country" source="countryId" reference="countries" fullWidth={true}>
              <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>

          <BooleanInput source="isActive" label="Active" initialValue={true} fullWidth={true}/>
                 </SimpleForm>


    </Edit>
  );
};
