import React,{useState} from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
  Toolbar,
  SaveButton,
  
  BooleanInput,
  FormTab,
  Field,
  Link,
  Grid,ReferenceInput,AutocompleteInput,required,useNotify,useRedirect,FormDataConsumer
} from "react-admin";
import { containerStyles } from "../../common/formStyles";
import { Button } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as apiClient from "../../common/apiClient";
import moment from 'moment';

import { FormToolbar } from "../../common/FormToolbar";

export const ActivityEdit = (props) => {
  const classes = containerStyles(); 
  const[balanceAmount,setBalanceAmount]=useState(0)
  const[clientId,setClientId]=useState(0)
  const[customerId,setCustomerId]=useState(0)
  const notify = useNotify();
  const redirect = useRedirect();
  
const handleSubmit = async (formdata) => {
  
  let userId;
  userId = localStorage.getItem("userId");
  if (userId) {
    userId = parseInt(userId);
  }

  let data={...formdata};
  let m = moment(new Date());
  
  data. createdDate=  m.toISOString()
  data. createdBy=  userId
  apiClient.addEditPayment(data).then(res => {
    if (res[0].id > 0) {
      notify(res[0].remarks)
      redirect("/account-payments");
    }
    else {
      notify(res[0].remarks, "warning")

    }
      
  }).catch(err => {
      console.log(err);
  });
}
const loadBalancePayment = (clientId,customerId) => { 
  apiClient.getCustomerBalancePayment(clientId,customerId,0).then(res => { 
    setBalanceAmount(parseFloat(res[0].balanceAmount));
      
  }).catch(err => { 
  });

}
const handleApprove = async () => {
  let userId;
  userId = localStorage.getItem("userId");
  if (userId) {
    userId = parseInt(userId);
  }
  let data: any = {};
  data.id = parseInt(props.id);
  data.approvedBy = userId;
  apiClient.approvepayment(data).then(res => {
    if (res[0].id > 0) {
      notify(res[0].remarks)
      redirect("/account-payments");
    }
    else {
      notify(res[0].remarks, "warning")

    }
  });
}
  return (
    <Edit title="Payment Edit" {...props}>
       
    <SimpleForm
        redirect="list"
        onSubmit={handleSubmit} 
        toolbar={<FormToolbar onSave={handleSubmit} {...props} showDelete={false} showCancel = {true} />}
        variant="outlined"
      >
        <Button className="close-me-btn" variant="contained" color="primary" onClick={handleApprove} >
                Approve
              </Button>
        <div style={{width:"50%"}}>
        <DateInput source="paymentDate" label="Payment Date" fullWidth variant="outlined" validate={[required()]} />
       <ReferenceInput label="Client"   fullWidth={true} 
          perPage={5000} source="clientId"
          reference="clients" onChange={(e)=>
          {
            setClientId(e);
            loadBalancePayment(e,customerId);
          }
          } >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput> 
        <ReferenceInput label="Customer"    fullWidth={true} 
          perPage={5000} source="customerId"
          reference="customers" 
          onChange={(e)=>
          {
            setCustomerId(e);
            loadBalancePayment(clientId,e);
          }
            
          } 
          >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <NumberInput source="amount" label="Amount Received (₹)" fullWidth variant="outlined" validate={[required()]} /> 
        { clientId>0 && customerId>0 &&  <div className="payment-balance-amount-container" >
          <div className="payment-balance-amount-container-label">
            Balance 
          </div>
          <div className="payment-balance-amount-container-value">
              {balanceAmount && parseFloat(balanceAmount.toString()).toFixed(2)}
          </div>
        </div>
      }
      <ReferenceInput label="Sale Representative" fullWidth={true} 
          perPage={5000} source="saleRepId"
          reference="sale-reps" >
          
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>    
        <ReferenceInput label="Payment Mode"   fullWidth={true} 
                    perPage={5000} source="paymentModeId"
                    filter={{ type: 'PYM' }} 
                    validate={[required()]}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" />
                </ReferenceInput> 
                <ReferenceInput label="Deposit To"   fullWidth={true} 
                    perPage={5000} source="depositBankId"
                    filter={{ clientId: clientId }} 
                    validate={[required()]}
                    reference="bank-masters" >
                    <SelectInput optionText="name" variant="outlined" />
                </ReferenceInput>  
                <TextInput source="referenceNo" variant="outlined" label="Reference No"  fullWidth={true}   validate={[required()]} />
                <TextInput source="remarks" variant="outlined" label="Remarks"   fullWidth={true}  />  
                    
        <FormDataConsumer formClassName={classes.last_three_input}>
          {({ formData, ...rest }) =>
          {
            formData.clientId &&
            formData.clientId !=clientId && setClientId(formData.clientId )
              
            
          }}
        </FormDataConsumer>
        
       </div>
    </SimpleForm>
    </Edit>
  );
};
