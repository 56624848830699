import React,{useState,useEffect} from "react";
import { DisplayDateTime } from '../../common/DisplayDate';
import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core'; 
import {
  SelectInput,
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
  useNotify,
  useRefresh,
  AutocompleteInput,
  DateInput,
  FunctionField ,
  ChipField, SingleFieldList,ReferenceManyField

} from "react-admin";
import Button from '@material-ui/core/Button';

import CancelIcon from '@material-ui/icons/CancelRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PrintIcon from '@material-ui/icons/PrintOutlined';

import PickslipPrint from "./PickslipPrint";
import DeliveryChallan from "./DeliveryChallan";
import InvoicePrint from "./InvoicePrint";
import GeneratePickslip from "./GeneratePickslip";
import CancelOrderEdit from "./CancelOrderEdit";
import ConfirmPickslip from "./ConfirmPickslip";
import Facility from "../Facility";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 600,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    {/* <SearchInput
      variant="outlined"
      label="Order No "
      source="orderNo~like"
      alwaysOn
    /> */}

<ReferenceInput perPage={25} sort={{ field: 'orderNo', order: 'ASC' }} filterToQuery={searchText => ({ 'orderNo~like': searchText })}
      label="Order No" source="id" reference="orders" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    

    <ReferenceInput label="Status"
      perPage={5000} source="statusId"
      reference="lookups"
      sort={{ field: 'name', order: 'ASC' }} 
      filter={{ type: 'OTS' }}
    >
      <SelectInput optionText="name" variant="outlined" alwaysOn /> 
    </ReferenceInput>
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Customer" source="customerId" reference="customers" fullWidth={true} >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Client" source="clientId" reference="clients" fullWidth={true} >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    <DateInput source="orderDate" label="Order Date" />
     <SearchInput variant="outlined" label="Document No " source="documentNo~like" />
     <ReferenceInput label="Order Type" fullWidth={true} 
          perPage={5000} source="orderType"
          filter={{ type: 'OT' }} 
          reference="lookups" >
          
          <SelectInput optionText="name" variant="outlined"/>
          
        </ReferenceInput> 

   
  </Filter>
);

export const OrderList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const myclasses = myStyles({}); 
  
  
  const [selectRecord, setSelectRecord] = useState(null); 
  const [isPrintPickslip, setIsPrintPickslip] = useState(false);  
  const [isGeneratePickslip, setIsGeneratePickslip] = useState(false);  
  const [isPrint, setIsPrint] = useState(false); 
  const [isPrintInvoice, setIsPrintInvoice] = useState(false); 
  const [isCancelOrder, setIsCancelOrder] = useState(false); 
  const [isConfirmPicklist, setIsConfirmPicklist] = useState(false); 
  const [sequenceNo, setSequenceNo] = useState(1);

  let isAdmin=false;
  let TypeID = localStorage.getItem("TypeID");
  
  let userFacilities:any=localStorage.getItem("facilities");
  if(userFacilities=='[object Object]')
    {
      userFacilities=null;
    }
  if(userFacilities)
    {
      userFacilities=JSON.parse(JSON.parse(JSON.parse(JSON.stringify(localStorage.facilities))))

    }
    
  
  if (TypeID != null && TypeID == "1") {
    isAdmin = true;
  }
  if(!isAdmin && userFacilities==null)
    {
      userFacilities=[{facilityId:0}]
    }

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate =   false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
      propsObj.hasCancel = (p.Cancel == 1) ? true : false;
    }
  }

  
  React.useEffect(() => {
    // Set up a timer to refresh the list every 10 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      if(!isCancelOrder && !isConfirmPicklist)
      refresh();
    }, 10000); // 10 seconds in milliseconds

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [refresh,isCancelOrder,isConfirmPicklist]);
  const PrintPicklist = async (record) => {
    setIsPrintPickslip(true);
    setIsPrint(false);
    setIsPrintInvoice(false);
    setIsGeneratePickslip(false);
    setSelectRecord(record);
  }

   const PrintDeliveryChallan = async (record) => {
    setIsPrintPickslip(false);
    setIsPrint(true);
    setIsPrintInvoice(false);
    setSelectRecord(record);
  }

  const PrintInvoice = async (record) => {
    setIsPrintPickslip(false);
    setIsPrint(false);
    setIsPrintInvoice(true);
    setSelectRecord(record);
  }
  
  const CancelOrder = async (record) => {
    //clearInterval(intervalId);

    setIsCancelOrder(true);
    setSelectRecord(record); 

  }
  const GeneratePicking = async (record) => {
    setIsGeneratePickslip(true);
    setSelectRecord(record);
    // let m = moment(new Date());
    // let userId;
    // userId = localStorage.getItem("userId");
    // if (userId) {
    //   userId = parseInt(userId);
    // }
    // let data = {
    //   transactionId: record.id,
    //   transactionType: 'ORDER_PICKING',
    //   createdBy: userId
    // }
    // await apiClient.addTransactionQueue(data).then(res => {
    //   if (res[0].result) {
    //     notify(res[0].message);
    //     refresh();
    //   }
    //   else {
    //     notify(res[0].message, "warning")
    //   }
    // }).catch(err => {
    //   notify(err, "warning")
    // });

  }
   
  const CancelOrderButton = (props) => (
    <Button startIcon={<CancelIcon />} style={{ marginTop: 4 ,marginLeft: 4}} color="primary"  variant="contained" onClick={props.click}  > {props.label} </Button>
  )
  
  
  const OrderAction = (props) => {
    
    let { record } = props;
    
    
    if ( props.record.statusId == 157 || props.record.statusId == 213     ) {
      return <div style={{ display: "flex" }}>
      { <CancelOrderButton label="Cancel Order " click={(e) => CancelOrder(record)} />}  
    </div>
    
    }
    else {
     return null;
      
    }

  }
 
  const handleClose =() => {
    setIsPrintPickslip(false);
    setIsPrint(false);
    setIsPrintInvoice(false);
    setIsGeneratePickslip(false);
    setIsCancelOrder(false);
    setIsConfirmPicklist(false);
    refresh(); 
  };
   let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
  

  return (
    <React.Fragment>
    <List sort={{ field: 'id', order: 'DESC' }} 
         filter={ { userId: userId }}
    {...propsObj} exporter={null} bulkActionButtons={false} title="Order  List" filters={<Filters />}  >
      <Datagrid rowClick={false}  >
        {propsObj.hasEdit && <EditButton />}
        <OrderAction label="Action" source="id" /> 
        <ChipField source="orderNo" label="Order No  " />
        
        <ReferenceField source="customerId" reference="customers" label="Customer" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="documentNo" label="Document No  " />
        <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="totalQty" label="Total Qty  " />
        <NumberField source="totalAmount" label="Total Amount" />  
        
        <ReferenceField source="parentOrderId" reference="orders" label="Parent Order" link={false}> 
        <ChipField source="orderNo" style={{ backgroundColor: 'black',color:'white' }} /> 
      </ReferenceField>
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField> 
        <DisplayDateTime source="modifyDate" label="Updated Date"/>
      </Datagrid>
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={(isConfirmPicklist|| isGeneratePickslip)?{ paper: myclasses.drawerPaper1500}:{ paper: myclasses.drawerPaper}}
    open={isPrint || isPrintPickslip || isPrintInvoice || isGeneratePickslip || isCancelOrder || isConfirmPicklist}     > 
 {selectRecord  && isPrint && <DeliveryChallan
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}
 {selectRecord  && isPrintInvoice && <InvoicePrint
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}            
{selectRecord  && isPrintPickslip && <PickslipPrint
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}
{selectRecord  && isGeneratePickslip && <GeneratePickslip
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}
{selectRecord  && isCancelOrder && <CancelOrderEdit
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}
{selectRecord  && isConfirmPicklist && <ConfirmPickslip
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}
           
  </Drawer>
    </React.Fragment>
  );
};
