import React from "react";
import { CreateToolbar } from "../../CreateToolbar";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  SimpleForm,
  TabbedForm,
  useRedirect,
  useNotify,
  NumberInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import moment from "moment";
import { Otherfields } from "./Otherfields";
import * as apiClient from "../../common/apiClient";

export const RowMasterCreate = (props) => {
  const classes = useStyles({});
  const redirect=useRedirect();
  const notify = useNotify();
  let defaultValues = {

    // dealDate: moment().toISOString(),
    // openDate: moment().toISOString(),

  };
  const handleSubmit = async (formdata) => {
    
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();
    if(parseInt(data.fromRow)<=0)
    {
      notify("Please enter valid input in Start Row fields ", "warning") 
      return;
    }
    if(parseInt(data.toRow)<=0)
    {
      notify("Please enter valid input in To Row fields ", "warning") 
      return;
    }
    if(parseInt(data.fromRow)>parseInt(data.toRow))
    {
      notify("Please enter valid input in Start and To Row fields ", "warning") 
      return;
    }

   
    apiClient.createrowmaster(data).then(res => {
      if(res[0].result)
      {
        notify("Rows are added succesffully");
        redirect("/row-masters");
      }
      else
      {
        notify(res[0].message, "warning")   
      }
      
      

    }).catch(err => {
     notify("unable to add ", "warning")
      //console.log(err);
    });
  }

  return (
    <Create title="Row Master Create" {...props}>
      <SimpleForm variant="outlined" redirect="list" onSubmit={handleSubmit}toolbar={<CreateToolbar onSave={handleSubmit}  {...props} />} initialValues={defaultValues}>
      
        <FormDataConsumer>
          {formDataProps => (
            <Otherfields {...formDataProps} />
          )}
        </FormDataConsumer>
        <TextInput source="name" label="Prefix" fullWidth={true} validate={[required()]} />       
        <NumberInput source="fromRow" label="Start Row Number "  fullWidth={true}  validate={[required()]} />
        <NumberInput source="toRow" label="To Row Number "  fullWidth={true}validate={[required()]} />

      </SimpleForm>

    </Create>
  );
};
