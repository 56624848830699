// FileUpload.jsx

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import './FileUpload.css';

const FileUpload = ({ onFileUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const csvFile = acceptedFiles[0];

      // Check if the file is a CSV file
      if (csvFile.type === 'text/csv' || csvFile.name.endsWith('.csv')) {
        Papa.parse(csvFile, {
          complete: (result) => {
            onFileUpload(result.data);
          },
          header: true,
          dynamicTyping: true,
        });
      } else {
        alert('Please upload a valid CSV file.');
      }
    }
  }, [onFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      <p>Drag & drop a CSV file here, or click to select a file</p>
    </div>
  );
};

export default FileUpload;
