import React, { useCallback, useState, useEffect } from "react";
import * as apiClient from "../../common/apiClient";
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  ReferenceField, TextField, useRefresh
} from "react-admin";
import { Drawer } from '@material-ui/core';
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import CDeleteButton from "../../common/CDeleteButton";
import { GridDetail } from './GridDetail';
import { Button } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles';
import SaleOrderCreate from "./SaleOrderCreate";
import SaleOrderEdit from "./SaleOrderEdit";
import moment from "moment";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));

export const OrderEdit = (props) => {
  const classes = useStyles({});
  const myclasses = myStyles({});



  const [isAddOrder, setIsAddOrder] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [gridData, setGridData] = useState([{ lineNumber: 1, productCode: null, productName: null, uomId: 0, price: null, qty: null, totalAmount: null, attributes: [], uomData: [] }]);
  const [isLoading, SetIsLoading] = useState(true)
  const [transactionNo, SettransactionNo] = useState("")
  const [IsEditRecord, setIsEditRecord] = useState(null);
  const [isEditOrder, setIsEditOrder] = useState(false);
  const [clientId, setIsClient] = useState(0);
  useEffect(() => {
    setGridData(gridData)
  });



  useEffect(() => {

    loadData()
  }, []);


  const loadData = async () => {
    SetIsLoading(true)
    await apiClient.getOrderData(props.id).then(res => {
      //let data=JSON.parse(res[0].data.lineItems)
      setGridData(JSON.parse(res[0].data)[0].lineItems)
      SettransactionNo(JSON.parse(res[0].data)[0].orderNo)
      SetIsLoading(false)
    }).catch(err => {
      console.log(err);
      SetIsLoading(false)
    });

  }
  const handleChange = (indata) => {

    setGridData(indata);
  }
  const onCloseDocumentForm = () => {
    setIsAddOrder(false);
    setIsEditOrder(false);

    refresh();
  }

  const onCancel = () => {
    setIsAddOrder(false);
    setIsEditOrder(false);


  }
  const handleClose = useCallback(() => {
    setIsAddOrder(false);
    setIsEditOrder(false);


  }, []);
  const CustomEdit = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<EditIcon />}>Edit</Button>
  }
  const handleSubmit = async (formdata) => {
    let m = moment();
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }

    let data =
    {

      id: parseInt(props.id),
      autoNo: parseInt(formdata.autoNo),
      dispatchOrderNo: formdata.dispatchOrderNo,
      dispatchOrderDate: formdata.dispatchOrderDate,
      facilityId: formdata.facilityId,
      clientId: formdata.clientId,
      remarks: formdata.remarks,
      carrierId: parseInt(formdata.carrierId),
      totalOrders: 0,
      totalBox: parseInt(formdata.totalBox),
      vehicleNo: formdata.vehicleNo,
      driverName: formdata.driverName,
      driverContactNo: formdata.driverContactNo,
      awbNo: formdata.awbNo,
      statusId: parseInt(formdata.statusId),
      createdBy: parseInt(userId),
      createdDate: formdata.createdDate,
      isActive: true,
      modifyDate: m.toISOString(),
      modifyBy: userId,
      awbDate:formdata.awbDate
    }
    // data. modifyDate=  m.toISOString()
    debugger
    if(data.awbDate==null)
    {
      delete data["awbDate"]
    }
    else
    {
      data=apiClient.padDate(data,"awbDate");  
      // let m1 = moment(data.awbDate);
      // data.awbDate = m1.toISOString();
    }
     

    apiClient.updateRecord("dispatch-orders", data).then(res => {
      if (res.id > 0) {
        notify("Updated...",)
        redirect("/vw-dispatch-orders");
      }
      else {
        notify("Unable to update", "warning")

      }
      //console.log(res);
      //props.onClose();
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <>
      <Edit title=" Order Edit" {...props}>

        <TabbedForm redirect="list" onSubmit={handleSubmit} toolbar={<FormToolbar onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined"  >
          <FormTab label="Dispatch  Details">

            <div className="transaction-header">
              Dispatch Order Detail
            </div>
            <ReferenceInput label="Facility" fullWidth={true} formClassName={classes.first_inline_input} disabled
              perPage={5000} source="facilityId"
              sort={{ field: 'name', order: 'ASC' }}
              reference="facilities" >
              <AutocompleteInput disabled optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
            </ReferenceInput>

            <ReferenceInput label="Client" fullWidth={true} formClassName={classes.last_inline_input} disabled
              perPage={5000} source="clientId"
              sort={{ field: 'name', order: 'ASC' }}
              reference="clients" >
              <AutocompleteInput disabled optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
            </ReferenceInput>
            <DateInput source={"dispatchOrderDate"} label={"Disptach Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.first_inline_input}  />
            <ReferenceInput label="Carrier" fullWidth={true} formClassName={classes.last_inline_input}
              perPage={5000} source="carrierId"
              sort={{ field: 'name', order: 'ASC' }}
              reference="carriers" >
              <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
            <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true} />
            <NumberInput source="totalBox" disabled label="Total Box" validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput source="vehicleNo" label="Vehicle No" validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} />
            <TextInput source="driverName" label="Driver Name" validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput source="driverContactNo" label="Driver Contact No" validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} />
            <TextInput source="awbNo"  label="Awb No"  fullWidth={true} formClassName={classes.first_inline_input} />            
            <DateInput source={"awbDate"} label={"Awb Date"} fullWidth={true}  variant="outlined" formClassName={classes.last_inline_input} />
          </FormTab>
          <FormTab label="Orders">
            <div>
              <Button onClick={() => { setIsAddOrder(true); }}>Add Order</Button>
            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="dispatch-order-dtls"
              target="dispatchOrderId">
              <Datagrid >
                <CDeleteButton {...props} />
                <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditOrder(true); }} label="Edit" />
                <ReferenceField source="orderId" reference="orders" label="Order No " link={false}>
                  <TextField source="orderNo" />
                </ReferenceField> 
                <ReferenceField source="orderId" reference="orders" label="Document No " link={false}>
                  <TextField source="documentNo" />
                </ReferenceField>
                
                <ReferenceField source="customerId" reference="customers" label="Customer " link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="noOfBox" label="Total Box" />
                <TextField source="freightCharge" label="Freight Charge" /> 
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
              </Datagrid>

            </ReferenceManyField>
          </FormTab>
          <FormDataConsumer formClassName={classes.last_three_input}>
            {({ formData, ...rest }) =>
              formData.clientId &&

              (
                setIsClient(formData.clientId)
              )
            }
          </FormDataConsumer>

        </TabbedForm>
      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper }}
        open={isEditOrder || isAddOrder} >
        {isAddOrder && clientId > 0 && <SaleOrderCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          dispatchId={props.id}
          clientId={clientId}
          {...props} />}

        {isEditOrder && <SaleOrderEdit
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}

          record={IsEditRecord}
          {...props} />

        }



      </Drawer>
    </>
  );
};
