import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const DimensionGroupCreate = (props) => {
  let defaultValues = {
    
    isActive: true,
    
    
    
    
  };
  return (
    <Create title="Industry Type Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={defaultValues}> 
      

       <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
       <NumberInput  variant="outlined" source="width"   placeholder={'Enter Number '} fullWidth={true}  validate={[required()]} />
       <NumberInput  variant="outlined" source="height"   placeholder={'Enter Number '} fullWidth={true}  validate={[required()]} />
       <NumberInput  variant="outlined" source="length"   placeholder={'Enter Number '} fullWidth={true}  validate={[required()]} />
       <NumberInput  variant="outlined" source="weight"   placeholder={'Enter Number (in kgs) '} fullWidth={true}  validate={[required()]} />
       <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Create>
  );
};
