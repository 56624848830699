import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput,
} from "react-admin";
import moment from "moment";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";

export const RegionCreate = (props) => {
  const classes = useStyles({});
  let defaultValues = {
     
    
  };
  return (
    <Create title="Region Create" {...props}>
       
<SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props}  />} initialValues={defaultValues}>
          
            <TextInput source="code" label="Code" validate={[required()]}  fullWidth={true}  />
            <TextInput source="name" label="Name"  validate={[required()]} fullWidth={true}  />
                 <ReferenceInput label="Country" source="countryId" reference="countries" fullWidth={true}>
              <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>

          <BooleanInput source="isActive" label="Active" initialValue={true} fullWidth={true}/>
          
        </SimpleForm>


    </Create>
  );
};
