import React from "react";
import { DisplayDateTime } from '../../common/DisplayDate';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Journal No" source="journalNo~like" alwaysOn />
    </Filter>
);
export const ActivityList = (props) => {
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <List {...props} title="Account Journal List"  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}  filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="journalNo" label="Journal Number" />
        <DateField  source="journalDate" label="Journal Date"/>
        <ReferenceField source="clientId" reference="clients" label="Client"  link={false}>
                <TextField source="name" />
            </ReferenceField>
        <ReferenceField source="customerId" reference="customers" label="Customer"  link={false}>
                <TextField source="name" />
            </ReferenceField>
         <NumberField source="amount" label="Journal Amount" />
        <BooleanField source="isApproved"/>
        <ReferenceField source="journalTypeId" reference="lookups" label="Journal Type"  link={false}>
                <TextField source="name" />
            </ReferenceField>
        <ReferenceField source="approvedBy" reference="users" label="Apporved By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" /> 
      </Datagrid>
    </List>
  );
};
