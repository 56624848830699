import React,{useState} from "react";
import { DisplayDateTime } from '../../common/DisplayDate';
import { Drawer } from '@material-ui/core'; 
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import CancelIcon from '@material-ui/icons/CancelRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";
import InvoicePrint from "./InvoicePrint";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 600,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Purchase No "
      source="purchaseNo~like"
      alwaysOn
    />
  </Filter>
);
export const PurchaseOrderList = (props) => {
  const myclasses = myStyles({}); 
  const [selectRecord, setSelectRecord] = useState(null); 
  const handleClose =() => {
    setIsPrint(false);
   
  };
  const [isPrint, setIsPrint] = useState(false); 
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const Print_Invoice = (props) => (
    <Button startIcon={<PrintIcon />} onClick={props.click} style={{ marginTop: 4 ,marginLeft: 4 }} color="primary" variant="outlined"  > Print   </Button>
  )
  const OrderAction = (props) => {
    
    let { record } = props;
    
      return (
        <div style={{ display: "flex" }}> 
          <Print_Invoice label="Print " click={(e) => {setSelectRecord(record);setIsPrint(true)}} />  
        </div>
      )
     

  }
 
  return (
    <React.Fragment>
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Product Group List" filters={<Filters />}  >
      <Datagrid rowClick={ false}>
        {propsObj.hasEdit && <EditButton />}
        
        <TextField source="purchaseNo" label="Purchase No  " />
        <OrderAction id="Action"/>
        <ReferenceField source="facilityId" reference="facilities" label="Facility"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="clientId" reference="clients" label="Client"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="vendorId" reference="vendors" label="Vendor"  link={false}>
                <TextField source="name" />
            </ReferenceField>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper}}
    open={isPrint}     > 
 
 {selectRecord  && isPrint && <InvoicePrint
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}
           
  </Drawer>
    </React.Fragment>
  );
};
