import React,{useState} from "react";
import { DisplayDateTime } from '../../common/DisplayDate';
import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
 
import {
  SelectInput,
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
  useNotify,
  useRefresh,
  AutocompleteInput,ChipField

} from "react-admin";
import Button from '@material-ui/core/Button';
import PutawayConfirm from "./PutawayConfirm";
import PickslipPrint from "./PickslipPrint";
import DispatchPrint from "./DispatchPrint";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 300,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 700,
  },
}));
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Awb No "
      source="awbNo~like"
      alwaysOn
    />
    <SearchInput
      variant="outlined"
      label="Dispatch Order No "
      source="dispatchOrderNo~like"
      alwaysOn
    />

    
    {/* <ReferenceInput label="Status"
      perPage={5000} source="statusId"
      reference="lookups"
      sort={{ field: 'name', order: 'ASC' }} 
      filter={{ type: 'OTS' }}
    >
      <SelectInput optionText="name" variant="outlined" alwaysOn /> 
    </ReferenceInput> */}
    {/* <ReferenceInput label="Carrier"
      perPage={5000} source="carrierId"
      reference="carriers"
    >
      <SelectInput optionText="name" variant="outlined" /> 
    </ReferenceInput> */}
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Carrier" source="carrierId" reference="carriers" fullWidth={true} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput  sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Customer" source="customerId" reference="customers"   allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    <ReferenceInput perPage={25} sort={{ field: 'orderNo', order: 'ASC' }} filterToQuery={searchText => ({ 'orderNo~like': searchText })}
      label="Order No" source="orderId" reference="orders" fullWidth={true} allowEmpty >
      <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    <ReferenceInput perPage={25} sort={{ field: 'documentNo', order: 'ASC' }} filterToQuery={searchText => ({ 'documentNo~like': searchText })}
      label="Document No" source="orderId" reference="orders" fullWidth={true} allowEmpty >
      <AutocompleteInput optionText="documentNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
   
    
                
  </Filter>
);

export const OrderList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const myclasses = myStyles({}); 
  
  const [isConfirmPutaway, setIsConfirmPutaway] = useState(false); 
  const [selectRecord, setSelectRecord] = useState(null); 
  const [isPrint, setIsPrint] = useState(false); 
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const PrintPicklist = async (record) => {
    setIsPrint(true);
    setSelectRecord(record);
  }

  //   let timerId = setInterval(() => refresh(), 10000);

  // // after 5 seconds stop
  // setTimeout(() => { clearInterval(timerId);   }, 5000);

  const GeneratePicking = async (record) => {
    let m = moment(new Date());
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data = {
      transactionId: record.id,
      transactionType: 'ORDER_PICKING',
      createdBy: userId
    }
    await apiClient.addTransactionQueue(data).then(res => {
      if (res[0].result) {
        notify(res[0].message);
        refresh();
      }
      else {
        notify(res[0].message, "warning")
      }
    }).catch(err => {
      notify(err, "warning")
    });

  }
  const handleClose =() => {
    setIsPrint(false);
    refresh(); 
  };
  const ConfirmPicking = async (record) => {
    // setIsConfirmPutaway(true);
    // setSelectRecord(record);
    let m = moment(new Date());
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data = {
      transactionId: record.id,
      transactionType: 'ORDER_PICKING',
      createdBy: userId
    }
    await apiClient.confirmPicking(data.transactionId,data.createdBy).then(res => {
      if (res[0].id>0) {
        notify(res[0].remarks);
        refresh();
      }
      else {
        notify(res[0].remarks, "warning")
      }
    }).catch(err => {
      notify(err, "warning")
    });
  }
  const PutawayButton = (props) => (
    <Button style={{ marginTop: 4 }} color="primary" variant="outlined" onClick={props.click}  > {props.label} </Button>
  )
  const PrintPickingButton = (props) => (
    <Button onClick={props.click} style={{ marginTop: 4 }} color="primary" variant="outlined"  > Print Picklist  </Button>
  )
  const Putaway = (props) => {
    let { record } = props;
    if (props.record.statusId == 157) {
      return (
        <div style={{ display: "flex" }}>
          <PutawayButton label="Generate Picklist " click={(e) => GeneratePicking(record)} />
          
        </div>
        
      )
    }
    if (props.record.statusId == 160) {
      return (
        <div style={{ display: "flex" }}>
          <PrintPickingButton label="Print  Picklist " click={(e) => PrintPicklist(record)} />
          <div style={{ minWidth: 10 }}></div>
          <PutawayButton label="Confirm Picklist " click={(e) => ConfirmPicking(record)} />
        </div>

      )
    }
    if (props.record.statusId == 161) {
      return (
        <div style={{ display: "flex" }}>
          <PrintPickingButton label="Print  Picklist " click={(e) => PrintPicklist(record)} />
          </div>

      )
    }
    
    else {
      return null
    }

  }
  function showPopup(id){
    
    setSelectRecord(id);
    setIsPrint(true);
    
  }
  const PrintButton=(props)=>( 
    <Button onClick={props.click}  > Print </Button>
  )
  const DisplayPrint = (props) => {
    let { record } = props;
  
    return (
      <PrintButton click={(e)=>showPopup(record.id)}   />
      
    )
  }
  return (
    <React.Fragment>
    <List sort={{ field: 'id', order: 'DESC' }}  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Order  List" filters={<Filters />}  >
      <Datagrid rowClick={null}  >
        {propsObj.hasEdit && <EditButton />}
      <DisplayPrint label="" source="id" />
        <ChipField source="dispatchOrderNo" label="Distpatch Order No  " />


        {/* <Putaway label="Action" source="id" /> */}
        <ReferenceField source="facilityId" reference="facilities" label="Facility" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="clientId" reference="clients" label="Client" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="carrierId" reference="carriers" label="Carrier" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="customerId" reference="customers" label="Customer" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="orderId" reference="orders" label="Document No " link={false}>
                  <TextField source="documentNo" />
                </ReferenceField>
                <ReferenceField source="orderId" reference="orders" label="Order No " link={false}>
                  <TextField source="orderNo" />
                </ReferenceField>
                
        <TextField source="totalBox" label="Total Box  " />
        <TextField source="awbNo" label="Awb No  " /> 
        <DisplayDateTime source="awbDate" label="Awb Date" locales="fr-FR" />
        
        
        {/* <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
          <TextField source="name" />
        </ReferenceField> */}
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper1500}}
    open={isPrint}    >
 
 
           
{selectRecord && isPrint && <DispatchPrint
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />} 


  </Drawer>
   
    </React.Fragment>
  );
};
