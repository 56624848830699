import React, { useEffect,useCallback,useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';

import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import  PopupCreateToolbar  from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const ClientEdit = props => {
    const classes = useStyles({});
   
    
    const TwoDecimalPlaces = (value) => {
        
        if (isNaN(value))
        {
            value=0;
        }
        return Number(value).toFixed(2).valueOf();
    }
     

    const handleSubmit = async (formdata) => {
        
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }

        let data={...formdata};
        let m = moment(new Date());
        
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("product-client-maps", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="product-client-maps" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
<ReferenceInput label="Client" fullWidth={true}
                    perPage={5000} source="clientId"
                    
                    reference="clients" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                

                <BooleanInput source="isActive" validate={[required()]} label="Active" fullWidth={true} initialValue={true} />


            
            </SimpleForm>
        </Edit>
    );
}
export default ClientEdit;