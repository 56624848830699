import React, { useState, useCallback, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as apiClient from '../../common/apiClient';
import { v4 as uuidv4 } from 'uuid';
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
  Edit, SimpleForm, TextInput, RichTextField,Button,
  ReferenceInput, SelectInput, EditButton, NumberField,
  DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
  ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import ProductAttributes from './ProductAttributes';
import ProductUomData from './ProductUomData';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 300,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));


export const GridDetail = ({ formData, ...rest }) => {
  const form = useForm();
  const myclasses = myStyles({}); 
  const [gridData, setGridData] = useState([]); 
  const [isAddEditAttribute, setIsAddEditAttribute] = useState(false); 
  const [isAddEditUomData, setIsAddEditUomData] = useState(false); 
  const [selectRecord, setSelectRecord] = useState(null); 
  const [selectedIndex, setSelectedIndex] = useState(null); 
  
  const classes = containerStyles({});  

 
  const loadAttributes = async (productId) => {
    let attrData = [];
    await apiClient.getproductAttributes(productId).then(res => {
      attrData = res;
    }).catch(err => {
      console.log(err);
    });
    return attrData;
  }
  const productUomHierarchy = async (productId) => {
    let attrData = [];
    await apiClient.productUomHierarchy(productId).then(res => {
      attrData = res;
    }).catch(err => {
      console.log(err);
    });
    return attrData;
  }
  const getproductdata = async (productId) => {
    let result = "";
    await apiClient.getproductdata(productId).then(res => {
      result = res.name;
    }).catch(err => {
      console.log(err);
    });
    return result;
  }

  const getlineTotal = (index) => {
    debugger
    let result = 0;
    let qty = index.qty;
    let price = index.price;
    let discount = index.discount;
    if (isNaN(parseInt(index.qty))) {
      qty = 0;
    }
    if (isNaN(parseFloat(index.price))) {
      price = 0;
    }
    if (isNaN(parseFloat(index.discount))) {
      discount = 0;
    }
    if(rest.goodInwardOrdType!=165 )
      {
        discount = 0;
      }
    let newPrice = parseFloat(price) * parseFloat(discount) * 0.01;
    price = price - newPrice;
    result = parseFloat(price) * parseInt(index.qty)
    if(isNaN(result))
    {
      result=0;
    }

    return result;
  }
  const  handleOpenPopup=(event,index,item,type)=>
  {
    setSelectedIndex(index);
    setSelectRecord(item.attributes)
    if(type==='attribute')
    {
      setIsAddEditAttribute(true);
    }

    if(type==='uom')
    {
      setIsAddEditUomData(true);
    }
    
    
  }
  useEffect(() => {
    const _gridData = [...rest.gridData];
    for (let index = 0; index < _gridData.length; index++) {
      _gridData[index].key = uuidv4();
    }
    setGridData([..._gridData])
  }, []);
  const  handleDeleteRow=(mainIndex)=>
  {
    
     let data = [];
     let mainData=[...gridData];
    for (let index = 0; index < mainData.length; index++) {
      const element = mainData[index];
      if(mainIndex!=index)
      {
        data.push({...element})
      }
      
    }
    setGridData([...data])
    rest.handleChange([...data]);
    
  }
  const  handleAddRow=()=>
  {
    debugger
    let data = [...gridData];
    let _item ={...data[0]};
    
      let keys = Object.keys(_item);
      keys.map((item, index)=>
      {
        _item[item]=null

      }

      )
    //_item.attributes=null
    _item.key = uuidv4();
    data.push(_item);
    setGridData(data)
    rest.handleChange(data);
    
  }
  
  
  const handleChange = async (event, index, idenifier) => {
    let data = [...gridData];
    debugger
    if (idenifier === "productCode") {
      data[index][idenifier] = event;
      data[index].attributes = await loadAttributes(event);
      data[index].productName = await getproductdata(event);
      data[index].uomData = await productUomHierarchy(event);
      

    }
    else {
      data[index][idenifier] = event.target.value;
    }
    debugger
    if(idenifier =='goodQty' || idenifier =='damageQty')
    {
      let goodQty=parseInt(data[index]['goodQty']);
      let damageQty=parseInt(data[index]['damageQty']);

      data[index].qty = parseInt(goodQty)+parseInt(damageQty)
    }
    data[index].totalAmount = getlineTotal(data[index])
    setGridData(data)
    rest.handleChange(data);

  }

  useEffect(() => {
    setGridData([...rest.gridData])
  }, []); 
  
  

  const handleClose =(data,type) => {
    if(type==='attribute')
    {
      debugger
      setIsAddEditAttribute(false);  
      let _gdata =[];
      _gdata = [...gridData];
       //let item = _data[props.selectedIndex];
          
      
      
      _gdata[selectedIndex].attributes=data;
       setGridData([..._gdata])
       rest.handleChange(_gdata);
       setSelectRecord(null)
       setSelectedIndex(-1)
    }
    if(type==='uom')
    {
      debugger
      setIsAddEditUomData(false);  
      let _gdata =[];
      _gdata = [...gridData];
       //let item = _data[props.selectedIndex];
          
      
      
      _gdata[selectedIndex].uomData=data;
       setGridData([..._gdata])
       rest.handleChange(_gdata);
       setSelectRecord(null)
       setSelectedIndex(-1)
    }
    


    
  };
  
  const getTotalQty = () => {
    return gridData.reduce((total, item) => total + (item.qty || 0), 0);
  };

  const getTotalAmount = () => {
    return gridData.reduce((total, item) => total + (item.totalAmount || 0), 0);
  };
  const onCancel = () => {
    setIsAddEditAttribute(false);
    setIsAddEditUomData(false);
    setSelectRecord(null);

  }


  return (
    <React.Fragment>
      <div className="transaction-detail ">
        
      Good Inward Product Detail
        <div>
          
        </div>
      </div>
      <Table className={classes.customtable} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 5 }} ></TableCell>
            <TableCell style={{ width: 200 }} >Product Code</TableCell>
            <TableCell >Product Name</TableCell> 
            <TableCell style={{ width: 100 }}>Price</TableCell>
            {rest.goodInwardOrdType==165 && <TableCell style={{ width: 100 }}>Discount</TableCell> }
            <TableCell style={{ width: 100 }}>Good Qty</TableCell>
            <TableCell style={{ width: 100 }}>Dam Qty</TableCell>
            <TableCell style={{ width: 50 }}>Total Qty</TableCell>
            <TableCell style={{ width: 100 }}>Total Amount</TableCell>

          </TableRow>
        </TableHead>
        <TableBody >
          {gridData.map((item, index) => {
            return <tr >
              <TableCell style={{ width: 5 }}>
              
                {/* {index + 1} */}
               {/* {index>0 &&   <Button
                 color="secondary" disableElevation
                size="small"
                redirect={false}
                onClick={(event)=>handleDeleteRow(index)}
                startIcon={<DeleteIcon />} */}
                <div style={{display:'flex',flexDirection:'row'}}>
                <span className='transaction-sno'  >{index+1}</span> 
                { gridData.length>1 && <span onClick={(event)=>{handleDeleteRow(index)}}  className='transaction-link-button'  ><DeleteIcon /></span>}
                </div>
                {/* {gridData.length>1  && <span onClick={(event)=>{handleDeleteRow(index)}}  className='transaction-link-button'  ><DeleteIcon /></span>} */}
            
                
              </TableCell>
              <TableCell>
                <ReferenceInput label=""
                  perPage={10} 
                  reference="products"
                  source={item.key}
                  defaultValue={item.productCode}
                  filterToQuery={searchText => ({ 'code~like': searchText })}
                  onChange={(event) => handleChange(event, index, "productCode")}
                >
                  <AutocompleteInput optionText="code" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
                </ReferenceInput>
              </TableCell>
              <TableCell>
                {item.productName}     {"  "} 
                
                {item.attributes && item.attributes.length>0 && <span onClick={(event)=>{handleOpenPopup(event,index,item,'attribute')}}  className='transaction-link-button'  >Attributes</span>}
              </TableCell>
              
              <TableCell>
                <div className='transaction-sm-col'>
                {item.productCode && item.productCode>0?<NumberInput  validate={[required()]} source={"price" + index} label="" variant="outlined" initialValue={item.price} onChange={(event) => handleChange(event, index, "price")} />:null}  
                </div>

              </TableCell>
              {rest.goodInwardOrdType==165 && <TableCell>
                <div className='transaction-sm-col'>
                {item.productCode && item.productCode>0?<NumberInput  validate={[required()]} source={"discount" + index} label="" variant="outlined" initialValue={item.discount} onChange={(event) => handleChange(event, index, "discount")} />:null}  
                </div>

              </TableCell>
          }
              
              <TableCell>
                <div className='transaction-sm-col'> 
                  { item.productCode && item.productCode>0?<NumberInput label=""  validate={[required()]} source={"goodqty" + index} variant="outlined" initialValue={item.goodQty} onChange={(event) => handleChange(event, index, "goodQty")} />:null}
                  {item.uomData && item.uomData.length>0 &&   <span onClick={(event)=>{handleOpenPopup(event,index,item,'uom')}}  className='transaction-link-button'  >Secondry Uom Qty</span>}
                  
                </div>
              </TableCell>
              <TableCell>
                <div className='transaction-sm-col'>
                  
                  {item.productCode && item.productCode>0?<NumberInput  validate={[required()]} source={"damqty" + index} label="" variant="outlined" initialValue={item.damageQty} onChange={(event) => handleChange(event, index, "damageQty")} />:null}
                </div>
              </TableCell>
              <TableCell>
                <div className='transaction-sm-col'> 
                  {/* {item.productCode && item.productCode>0?<NumberInput  validate={[required()]} source={"qty" + index} label="" variant="outlined" disabled={true} initialValue={item.qty} onChange={(event) => handleChange(event, index, "qty")} />:null} */}
                  {item.qty}
                  
                </div>
              </TableCell>
              <TableCell>
                <div className='transaction-sm-col'>
                  {item.totalAmount}
                 {index+1===gridData.length && <Button onClick={handleAddRow} ><AddIcon /></Button>}
                </div>  
              </TableCell>  
            </tr>
          })}
            <tr>
          <TableCell colSpan={rest.goodInwardOrdType==165?6:5}></TableCell>
          <TableCell><div style={{width:"100%",fontWeight:"bold",textAlign:"left"}}>Total:</div></TableCell>
          <TableCell><div style={{width:"100%",fontWeight:"bold",textAlign:"right"}}>{getTotalQty()}</div></TableCell>
          <TableCell><div style={{width:"100%",marginRight:"20px",fontWeight:"bold",textAlign:"right"}}>{getTotalAmount()}</div></TableCell>
        </tr>
        </TableBody>
      </Table>

      <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper}}
    open={isAddEditAttribute || isAddEditUomData}    >
 
 {selectRecord && selectRecord.length>0 && isAddEditAttribute && <ProductAttributes
          onClose={handleClose}
          onCancel={onCancel} 
          record={selectRecord}
          selectedIndex={selectedIndex}
          {...rest} />}

{selectRecord && selectRecord.length>0 && isAddEditUomData && <ProductUomData
          onClose={handleClose}
          onCancel={onCancel} 
          record={selectRecord}
          selectedIndex={selectedIndex}
          {...rest} />}
  </Drawer>
    </React.Fragment>


  )
}
