import React from "react";
import { DisplayDateTime } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="City Name" source="name~like" alwaysOn />
    </Filter>
);
export const CityList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} title="City List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        
        <TextField source="code" label="City Code" />
        <TextField source="name" label="City Name" />
        <ReferenceField source="stateId" reference="states" link={false} label="State" >
                        <TextField source="name" />
                    </ReferenceField>
        <ReferenceField source="districtId" reference="districts" link={false} label="District" >
                        <TextField source="name" />
                    </ReferenceField>
      
      
                    <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
            </Datagrid>      
    </List>
  );
};
