import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const SaleOrderCreate = props => {
    const classes = useStyles({}); 
    const[totalPackets,setTotalPackets]=useState(null);
    const[isLoading,setIsLoading]=useState(true);
    const[isLoadingOtherCharges,setIsLoadingOtherCharges]=useState(true);
    const[otherCharges,setOtherCharges]=useState(null);
    const initialValues = {
        dispatchId: props.dispatchId,
        clientId: props.clientId, 
    } 
    
    const LoadPacket = async(id) => {
        setIsLoading(true)  ;
        setTotalPackets(null);
        await apiClient.getdata_v1(`orders/${id}`).then(res => { 
            setIsLoading(false)  ;
            if(res.totalPackets)
                {
                    setTotalPackets(res.totalPackets)
                }
            
            //LoadOtherCharges(res.clientId,res.facilityId);
        }).catch(err => {
            setTotalPackets(0)
            setIsLoading(false);
        });

    }
   
    const handleSubmit = (formdata) => { 
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }
        let data = {
            dispatchOrderId: parseInt(formdata.dispatchId),            
            orderId: parseInt(formdata.orderId),            
            noOfBox: parseInt(formdata.noOfBox),            
            freightCharge: parseFloat(formdata.freightCharge),             
            payTypeId: parseInt(formdata.payTypeId),            
            actualWeight: parseFloat(formdata.actualWeight),             
            isCharged:formdata.isCharged,
            isActive: true,
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(),

        }
        apiClient.addEntiyRecord("dispatch-order-dtls", data).then(res => { 
            props.onClose();
        }).catch(err => { 
        }); 
    }
    return (
        <Create {...props} >
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined"> 
                <ReferenceInput label="Order Number" fullWidth={true}
                    perPage={5000} source="orderId"
                      filter={{ clientId: initialValues.clientId,statusId:161 }}
                     onChange={(event)=>LoadPacket(event)}
                    reference="orders" >
                    <AutocompleteInput  optionText="orderNo" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
                </ReferenceInput> 
               {!isLoading   && <NumberInput source="noOfBox" label="Total Box" validate={[required()]} fullWidth={true} initialValue={totalPackets} /> }
               { <ReferenceInput label="Pay Type" source="payTypeId" filter={{ type: 'PT' }} fullWidth={true} 
                  reference="lookups" validate={[required()]}  >
                    <SelectInput optionText="name" variant="outlined" />
                </ReferenceInput>}
                
                { <NumberInput source="actualWeight" label="ActualWeight" validate={[required()]} fullWidth={true} initialValue={0} /> }  
               { <NumberInput source="freightCharge" label="Freight Charge" validate={[required()]} fullWidth={true} initialValue={otherCharges} /> }  
               {<BooleanInput source="isCharged" validate={[required()]} label="Add Charges In Invoice" initialValue={false} />}
            </SimpleForm>
        </Create>
    );
}
export default SaleOrderCreate;