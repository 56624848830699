// Install necessary packages
// npm install @react-pdf-viewer
// npm install @react-pdf/renderer

// Create a component for the PDF invoice
import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../common/apiClient";
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  header: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
    textDecoration: 'none',
  },
  subheader: {
    fontSize: 16,
    marginBottom: 5,
  },
  textHeader: {
    fontSize: 17,
    marginTop: 5,
    marginBottom: 10,
    marginLeft: 5,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 5,
  },
  table: {
    display: 'table',
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: 'row',
    border: 0.5,
    borderColor: '#000',
    borderStyle: 'solid',
    
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  tableCell: {
    flex: 1, 
    padding: 10,
    fontSize: 10,
    maxWidth:100,  
    

  },
  tableCellProduct: {
    flex: 1, 
    padding: 10,
    fontSize: 10,
    minWidth:100,  

  },
   
  tableCellQty: {
    flex: 1, 
    padding: 10,
    marginLeft: 20,
    fontSize: 10,
    
  },
  
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    borderTop: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    paddingTop: 5,
  },
  viewBox:
  {
    flexDirection: 'row',
  textAlign:"left",
    marginTop: 0,
    // borderTop: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    paddingTop: 0,
    fontSize:12
  },
  viewBox1:
  {
    flexDirection: 'row',
  textAlign:"left",
    marginTop: 0,
    // borderTop: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    paddingTop: 0,
    fontSize:12
  },
  viewBoxCell1:
  {

    fontSize:11,
    marginTop: 10,
    paddingLeft: 2,
    border: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    paddingTop: 10,
    width: "15%"
  },
  viewBoxCell3:
  {

    paddingTop: 10,
    marginTop: 10,
    paddingLeft: 2,
    borderLeft: 0,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    fontSize:11,
    width: "20%"
  },
  viewBoxCell2:
  {
    
    paddingTop: 10,
    marginTop: 10,
    paddingLeft: 2,
    borderLeft: 0,
    borderTop: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    
    width: "25%"
  },
  viewBoxCell4:
  {

    paddingTop: 10,
    marginTop: 10,
    paddingLeft: 2,
    borderLeft: 0,
    borderTop: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
    
    width: "40%"
  }

});

const PackingPDF = ({ data,headerData }) => {
  return (
    <Document>
      {data.map((item,index)=>{
      return   <Page size="A3" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Packslip</Text> 
          <View style={styles.viewBox}>
            <View style={styles.viewBoxCell1}>
              <Text style={styles.text}>Order Number</Text>
            </View>
            
            <View style={styles.viewBoxCell2}>
              <Text style={styles.text}>{item.orderNo}</Text>
            </View>
            <View style={styles.viewBoxCell3}>
              <Text style={styles.text}>Order Date </Text>
            </View>
            <View style={styles.viewBoxCell4}>
              <Text style={styles.text}>{item.orderDate}</Text>
            </View>
          </View> 

          <View style={styles.viewBox}>
            <View style={styles.viewBoxCell1}>
              <Text style={styles.text}>Packet No</Text>
            </View>
            
            <View style={styles.viewBoxCell2}>
              <Text style={styles.text}>{item.packetNo}</Text>
            </View>
            <View style={styles.viewBoxCell3}>
              <Text style={styles.text}>Packet Weight  </Text>
            </View>
            <View style={styles.viewBoxCell4}>
              <Text style={styles.text}>{item.productWeight} {" kgs"}</Text>
            </View>
          </View>
          <View style={styles.viewBox}>
            <View style={styles.viewBoxCell1}>
              <Text style={styles.text}>Product Count</Text>
            </View>
            
            <View style={styles.viewBoxCell2}>
              <Text style={styles.text}>{item.productCount}</Text>
            </View>
            <View style={styles.viewBoxCell3}>
              <Text style={styles.text}>Packet Quantity </Text>
            </View>
            <View style={styles.viewBoxCell4}>
              <Text style={styles.text}>{item.productQty}</Text>
            </View>
          </View>
          
            
          <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={styles.tableCellProduct}>Product Name </Text>
                            <Text style={styles.tableCell}>Product Code</Text> 
                              <Text style={styles.tableCellQty}>Qty</Text>
                              <Text style={styles.tableCellQty}>Price</Text>
                            <Text style={styles.tableCell}>Weight(Kg)</Text>   
                        </View>
            {item.detail.map(ditem => (
                <View key={ditem} style={styles.tableRow}>
                  <Text style={styles.tableCellProduct}>{ditem.productName}</Text>
                  <Text style={styles.tableCell}>{ditem.productCode}</Text> 
                  <Text style={styles.tableCellQty}>{ditem.qty}</Text>
                  <Text style={styles.tableCellQty}>{ditem.price}</Text>
                  <Text style={styles.tableCell}>{ditem.weight} {" kgs"}</Text> 
                    
                </View>
              ))}
            </View> 

          {/* <View style={styles.totalRow}>
              <Text style={{ ...styles.tableCell, ...styles.tableHeader }}>Total Qty:</Text>
              <Text style={styles.tableCell}>
                {data[0].totalAmount.toFixed(2)}
              </Text>
              <Text style={{ ...styles.tableCell, ...styles.tableHeader }}>Total Quantity:</Text>
              <Text style={styles.tableCell}>
                {data[0].totalQty}
              </Text>
              </View>     */}
        </View>
        </Page>
      })}
     
    </Document>
  );
};
const PackslipPrint = (props) => { 
  const [isLoading, setLoading] = useState(true);
  const [finalData, setFinalData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  useEffect(() => {
    LoadData(props.record.orderId)
  }, []);
  const LoadData = async (id) => {  
    let payload = { transactionId: parseInt(id) }
    setLoading(true)
    await apiClient.globalPrint(payload.transactionId,props.transactionType).then(res => { 
      let data = JSON.parse(res[0].data);   
      data.forEach(order => {
            const details = JSON.parse(order.detail); 
            order.detail=details 
      }); 
      setHeaderData(data[0]);
      setFinalData(data);
      setLoading(false);

    }).catch(err => {
      setLoading(false);
      console.log(err);
    });

  }


  return (
    <>
      <div>
        <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
        {/* {JSON.stringify(finalData)} */}
      </div>
      {!isLoading && <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <PackingPDF data={finalData} headerData={headerData} />
        
      </PDFViewer>
      }
    </>

  );
};

export default PackslipPrint;
