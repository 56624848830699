import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const CountryCreate = (props) => {
  const classes = useStyles({});
  const initialValue={
    // billCountryId:0,
    // billRegionId:0,
    // billStateId:0,
     billDistrictId:0,
     billCityId:0,
     shipCountryId:0,
     shipRegionId:0,
     shipStateId:0,
     shipDistrictId:0,
     shipCityId:0
 }
  return (
    <Create title="Country Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        <TextInput source="code"  validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="name" validate={[required()]}  formClassName={classes.two_three_input}/>
        <ReferenceInput
              label="Currency"
              source="currencyId"
              reference="currency"
              formClassName={classes.last_three_input}
              >
              <SelectInput optionText="currencyName" />
            </ReferenceInput>
            <TextInput source="dateFormat"  validate={[required()]} formClassName={classes.one_three_input}/>
            <ReferenceInput
              label="Timezone"
              source="timezoneId"
              reference="timezones"
              formClassName={classes.two_three_input}
              >
              <SelectInput optionText="timeZoneName" />
            </ReferenceInput>
            <BooleanInput source="isActive" validate={[required()]} label="Active" formClassName={classes.last_three_input}  />
            <ImageField source="countryFlag" title="Icon" formClassName={classes.one_three_input} />
            <ImageInput
              source="countryFlag"
              formClassName={classes.two_three_input}
              
              label="Flag"
              accept="image/*"
              className="logourl"
            >
              <ImageField source="countryFlag" title="Icon" />
            </ImageInput>
            
        
        </SimpleForm>
    </Create>
  );
};
