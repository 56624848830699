import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const BillAddressfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div >
            <ReferenceInput variant="outlined" label="Country" source="billCountryId" reference="countries"
                onChange={(v) => {                     
                    form.change("billRegionId", 0);
                    form.change("billStateId", 0);
                    form.change("billDistrictId", 0);
                    form.change("billCityId", 0);
                }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500} className={classes.one_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Region" source="billRegionId" reference="regions"
                onChange={(v) => {                    
                    form.change("billStateId", 0);
                    form.change("billDistrictId", 0);
                    form.change("billCityId", 0);
                    
                }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500} className={classes.two_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="State" source="billStateId"
                reference="states" perPage={500}
                filter={{ "regionId": formData.billRegionId }}
                sort={{ field: 'name', order: 'ASC' }}
                onChange={(v) => {
                    form.change("billDistrictId", 0);
                    form.change("billCityId", 0);
                }}
                fullWidth={true} validate={[required()]} >
                <AutocompleteInput validate={[required()]} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            
            <ReferenceInput variant="outlined" label="District" source="billDistrictId"
                reference="districts" perPage={500}
                filter={{ "stateId": formData.billStateId }}
                sort={{ field: 'name', order: 'ASC' }}
                onChange={(v) => {
                    form.change("billCityId", 0);
                }}
                fullWidth={true} >
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            
            <ReferenceInput variant="outlined" label="City" source="billCityId"
                reference="cities" perPage={500}
                filter={{ "districtId": formData.billDistrictId }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true}>
                {/* <SelectInput   optionText="name" /> */}
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
        </div>
    )
}
