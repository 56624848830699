import React,{useState,useCallback} from 'react';
import { useRefresh,FormTab,NumberField, ReferenceManyField,Datagrid,ReferenceField,TextField,TabbedForm, FormDataConsumer, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, BooleanInput, NumberInput, required } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BillAddressfields } from "./BillAddressfields";
import { ShipAddressfields } from "./ShipAddressfields";
import { DisplayDate } from '../../common/DisplayDate';
import { CopyAddress } from './CopyAddress';
import CDeleteButton from "../../common/CDeleteButton";
import Button from '@material-ui/core/Button';
import DisCreate from "./DisCreate";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));

export const CustomerEdit = props => {
  const [isAddDiscount, setIsAddDiscount] = useState(false);
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const handleClose = useCallback(() => {
    
    setIsAddDiscount(false);

  }, []);
  const onCloseDocumentForm = () => {
  
    setIsAddDiscount(false);
    refresh();
  }

  const onCancel = () => {
     
    setIsAddDiscount(false);

  }
  return (
    <React.Fragment>
      <Edit label="Edit Customer" {...props}>

        <TabbedForm redirect="list" toolbar={<FormToolbar {...props} />} variant="outlined">
          <FormTab label="Customer  Details"
            variant="outlined">
            <ReferenceInput variant="outlined" label="Customer Group" source="custGroupId" reference="cust-groups"
              fullWidth={true} perPage={500} >
              <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
            <TextInput source="code" label='Code' fullWidth={true} validate={[required()]} />
            <TextInput source="name" label='Name' fullWidth={true} validate={[required()]} />
            <TextInput source="contactPerson" label='Contact Person ' fullWidth={true} validate={[required()]} />
            <TextInput source="contactNumber" label='Contact Number ' fullWidth={true} validate={[required()]} />
            <div>
              <h4> Billing Address</h4>
            </div>
            <TextInput source="billAddress1" label='Address 1 ' fullWidth={true} initialValue={""} />
            <TextInput source="billAddress2" label='Address 2' fullWidth={true} initialValue={""} />
            <TextInput source="billZipCode" label='Pincode' fullWidth={true} initialValue={""} />


            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <BillAddressfields {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput source="billEmailId" label='Bill Email Id ' fullWidth={true} initialValue={""} />

            <div>
              <h4> Shipping Address</h4>
            </div>
            <FormDataConsumer fullWidth={true} >
                        {formDataProps => (
                            <CopyAddress {...formDataProps} />
                        )}
                    </FormDataConsumer>
            
            <TextInput source="shipAddress1" label='Address 1 ' fullWidth={true} initialValue={""} />
            <TextInput source="shipAddress2" label='Address 2' fullWidth={true} initialValue={""} />
            <TextInput source="shipZipCode" label='Pincode' fullWidth={true} initialValue={""} />
            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <ShipAddressfields {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput source="emailId" label='Email' fullWidth={true} initialValue={""} />
            <TextInput source="gstNo" label='Gst No' fullWidth={true} initialValue={""} />
            <TextInput source="panNo" label='Pan No' fullWidth={true} initialValue={""}/>
            <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} />
          </FormTab>
          <FormTab label="Discounts">
          <div>
              <Button onClick={() => { setIsAddDiscount(true); }}>Add Customer Discount</Button>
            </div>


            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="customer-item-wise-discounts"
              target="customerId">

              <Datagrid >
              <CDeleteButton {...props} />
                
                <ReferenceField source="clientId" reference="clients" label="Client " link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="productId" reference="products" label="Product Code" link={false}>
                  <TextField source="code" />
                </ReferenceField>
                <ReferenceField source="productId" reference="products" label="Product Name" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <NumberField  source="discount" />
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper }}
        open={ isAddDiscount} >
        
        {isAddDiscount && <DisCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          customerId={props.id}

          {...props} />}


      </Drawer>
    </React.Fragment>
  );
}