import React, { useState, useEffect } from "react";
import * as apiClient from "../../common/apiClient";
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify 
} from "react-admin"; 
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles"; 
import { GridDetail } from './GridDetail';
import { Button } from "@material-ui/core";
import { CustomerField } from "./CustomerField";
import { v4 as uuidv4 } from 'uuid';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProductImport from "./ProductImport";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 300,
  },
  drawerPaper1000: {
    zIndex: 100,
    marginTop: 50,
    width: 1000,
  },
}));
export const OrderCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect = useRedirect();
  const [isImportProduct, setIsImportProduct] = useState(false); 
  const [gridData, setGridData] = useState([{ key: uuidv4(), lineNumber: 1, productId: null, productName: null, uomId: 0, price: null, qty: null, damageQty: null, totalAmount: null, attributes: [], uomData: [] }]); 
  const myclasses = myStyles({}); 
  const[isLoading,SetIsLoading]=useState(false)
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>(null);
  const [cols, setCols] = useState<string[]>([]);
  const [data, setData] = useState([]);

  let isAdmin=false;
  let TypeID = localStorage.getItem("TypeID");
  debugger
  let userFacilities:any=localStorage.getItem("facilities");
  if(userFacilities=='[object Object]')
    {
      userFacilities=null;
    }
  if(userFacilities)
    {
      userFacilities=JSON.parse(JSON.parse(JSON.parse(JSON.stringify(localStorage.facilities))))

    }
    
  
  if (TypeID != null && TypeID == "1") {
    isAdmin = true;
  }
  if(!isAdmin && userFacilities==null)
    {
      userFacilities=[{facilityId:0}]
    }

  let location = useLocation();
  useEffect(() => {
    setGridData(gridData)
    
  }, [gridData]);

  const handleChange = (indata) => {
    setGridData(indata);
    setIsImportProduct(false);
    SetIsLoading(false);
  }
  useEffect(() => {
    const values = queryString.parse(location.search);
    let oid = (values.oid) ? values.oid : '';
    if (oid != "") {
      getOrder(oid);
    } else {
      setIsFirstLoading(false);
    }

  }, []);
  const findDuplicateProducts = async () => {
    const productIdCount = {};

    // Iterate through the array to count occurrences of each productId
    gridData.forEach((item: any) => {
      const productId = item.productId;
      productIdCount[productId] = (productIdCount[productId] || 0) + 1;
    });

    // Find duplicate productIds
    const duplicateProductIds = Object.keys(productIdCount).filter(productId => productIdCount[productId] > 1);

    // Get duplicate product names
    const duplicateProductNames = gridData.filter((item: any) => duplicateProductIds.includes(item.productId.toString())).map(item => item.productName);

    return duplicateProductNames;

  };
  const handleClose =(data,type) => { 
    SetIsLoading(false);
    setIsImportProduct(false);
  };
  const handleSubmit = async (formdata) => {
    //alert("c");
    setIsSubmitting(true);
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }

    let data: any = {};
    data.id = 0;
    data.facilityId = formdata.facilityId;
    data.orderType = formdata.orderType;
    data.clientId = formdata.clientId;
    data.customerId = formdata.customerId;
    data.documentNo = formdata.documentNo;
    data.orderDate = formdata.orderDate;
    data.saleRepId = formdata.saleRepId;
    data.remarks = formdata.remarks;
    data.shipAddress1 = formdata.shipAddress1;
    data.shipAddress2 = formdata.shipAddress2;
    data.shipZipCode = formdata.shipZipCode;
    data.shipStateId = formdata.shipStateId;
    data.cityName = formdata.cityName; 
    data.tpMode = formdata.tpMode;
    data.lineItems = [...gridData];
    if (gridData && gridData.length > 0) {
      let DuplicateProducts = await findDuplicateProducts();
      if (DuplicateProducts && DuplicateProducts.length > 0) {
        setIsSubmitting(false);
        notify("Duplicate Product In Grid " + DuplicateProducts.join(', '), "warning")
        return;
      }
    }
    
    data.createdBy = userId
    let verifcationData = { processType: "order", data: data }
    apiClient.ValidateTransaction(verifcationData).then(res => {

      // apiClient.addeditOrder(data).then(res => {
      //   if (res[0].id > 0) {
      //     notify(res[0].remarks)
      //     redirect("/orders");
      //   }
      //   else {
      //     notify(res[0].remarks, "warning")

      //   }
      //   setIsSubmitting(false);
      // }).catch(err => {
      //   console.log(err);
      //   setIsSubmitting(false);
      // });
      if (res.length > 0) {

        setCols([]);
        setData([]);
        if (res.length > 0) {
          let keys = Object.keys(res[0]);
          setCols(keys);
          setData(res);
        }
        setDialogData(data); // Set the formdata to dialogData state
        setIsOpenDialog(true); // Open the dialog
       
      }
      else {
        handleConfirm(data);
      }


    })
      .catch(err => {
        console.log(err);
        setIsSubmitting(false);
      });
    // apiClient.addeditOrder(data).then(res => {
    //   if (res[0].id > 0) {
    //     notify(res[0].remarks)
    //     redirect("/orders");
    //   }
    //   else {
    //     notify(res[0].remarks, "warning") 
    //   }
    //   setIsSubmitting(false);
    // }).catch(err => {
    //   console.log(err);
    //   setIsSubmitting(false);
    // });
  }
  const handleConfirm = async (_data) => {
    debugger
    setIsSubmitting(true);
    let userId: any = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data: any = {};
    if (_data != null) {
      data = { ..._data };
    }
    else {
      data.id = 0;
      data.facilityId = dialogData?.facilityId;
      data.orderType = dialogData?.orderType;
      data.clientId = dialogData?.clientId;
      data.customerId = dialogData?.customerId;
      data.documentNo = dialogData?.documentNo;
      data.orderDate = dialogData?.orderDate;
      data.saleRepId = dialogData?.saleRepId;
      data.shipAddress1 = dialogData?.shipAddress1;
      data.shipAddress2 = dialogData?.shipAddress2;
      data.shipZipCode = dialogData?.shipZipCode;
      data.shipStateId = dialogData?.shipStateId;
      data.tpMode = dialogData?.tpMode;
      data.remarks = dialogData?.remarks;
      data.cityName = dialogData?.cityName;
      data.lineItems = [...gridData];
      data.createdBy = userId;

    }

    apiClient.addeditOrder(data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/orders");
      } else {
        notify(res[0].remarks, "warning")
      }
      setIsSubmitting(false);
      setIsOpenDialog(false);
    }).catch(err => {
      console.log(err);
      setIsSubmitting(false);
      setIsOpenDialog(false);
    });
  };

  const getOrder = (oid: any) => {
    apiClient.getEntiyRecord("orders", oid).then(res => {
      //if(res) {
      delete res.id;

      Object.keys(res).forEach((item, index) => {
        if (!res[item] && res[item] != 0) {
          //console.log(res[item], "KEY VALUE NULL");
          delete res[item];
        }
      })
      res["orderDate"]=new Date();
      
      
      setIsFirstLoading(false);
      //   }
    }).catch(err => {
      console.log(err);
    });
  }
  const onCancel = () => {
    setIsImportProduct(false); 
    SetIsLoading(false);
  } 
  return (
    <>
    {!isFirstLoading && <Create title=" Order Create" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} toolbar={<FormToolbar  isSubmitting={isSubmitting} onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >
        <div className="transaction-header">
          Order Detail
        </div>
        {<ReferenceInput label="Facility" fullWidth={true} 
          perPage={5000} source="facilityId"
          reference="facilities" >
          
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>}
        

        <ReferenceInput label="Order Type" initialValue={166} fullWidth={true} formClassName={classes.one_4_input}
          perPage={5000} source="orderType"
          filter={{ type: 'OT' }}
          reference="lookups" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput label="Client" initialValue={1}  fullWidth={true} formClassName={classes.two_4_input}
          perPage={5000} source="clientId"
          reference="clients" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <FormDataConsumer fullWidth={true} formClassName={classes.three_4_input}>
          {formDataProps => (
            <CustomerField  {...formDataProps} />
          )}
        </FormDataConsumer>

        <ReferenceInput label="Sale Representative" fullWidth={true} formClassName={classes.last_4_input}
          perPage={5000} source="saleRepId"
          reference="sale-reps" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <DateInput source={"orderDate"} label={"Order  Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_5_input} initialValue={new Date()}
        options={{
          format: apiClient.DateFormat
      }}
        />
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.two_5_input} />
        <ReferenceInput label="Mode" initialValue={168} formClassName={classes.three_5_input} 
                    perPage={5000} source="tpMode"
                    filter={{ type: 'TPM' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput>
        <div>
        </div>
        <TextInput source="shipAddress1" multiline label="Shipping Address 1" validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.one_5_input} />
        <TextInput source="shipAddress2" multiline label="Shipping Address 2" initialValue={""} fullWidth={true} formClassName={classes.two_5_input} />

        <TextInput source="shipZipCode" label="Ship Pincode" validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.three_5_input} />
        <ReferenceInput label="Shipping State" fullWidth={true} formClassName={classes.four_5_input}
          perPage={5000} source="shipStateId"
          sort={{ field: 'name', order: 'ASC' }}
          reference="states" >
          <SelectInput optionText="name" variant="outlined" validate={[required()]} />
        </ReferenceInput>

        <TextInput source="cityName"  label="City Name" initialValue={""} fullWidth={true} formClassName={classes.last_5_input} />
        <TextInput source="remarks"  label="Remarks" initialValue={""} fullWidth={true} />

        <div style={{ textAlign: "right", width: "100%", marginTop: 20 }} >
          <Button className="close-me-btn" variant="contained" color="primary" onClick={()=>{setIsImportProduct(true);SetIsLoading(true);}}>
            Import Products
          </Button>
          
        </div>
        {!isLoading && <FormDataConsumer fullWidth={true} >
          {formDataProps => (
            <GridDetail loadAddress={false} {...formDataProps} gridData={gridData} handleChange={handleChange} />
          )}
        </FormDataConsumer>}


      </SimpleForm>
    </Create>
}
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper1000}}
    open={isImportProduct}    >
 
 {isImportProduct && <ProductImport
          onClose={handleClose}
          onCancel={onCancel} 
          onSuccess={handleChange}
          />}


  </Drawer>
  <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="w3-responsive">

              <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                <tr>
                  {cols && cols.map((item, index) => {
                    return (
                      <th key={item}>{item}</th>
                    )
                  })}
                </tr>

                {data && data.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      {cols && cols.map((col, colIndex) => {
                        return (
                          <td key={rowIndex + "-" + colIndex}>
                            {row[col]}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <Button variant="contained" color="primary" style={{ marginTop: 10 }}
              onClick={() => { handleConfirm(null) }}
            >
              Proceed To Save
            </Button>

            <Button variant="contained" style={{ marginTop: 10 , marginLeft: 10 }}
              onClick={() => { setIsOpenDialog(false); setIsSubmitting(false); }}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>

    </>
  );
};
