import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { Button } from '@material-ui/core';

export const ShipAddressfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div >
            
            <ReferenceInput variant="outlined" label="Country" source="shipCountryId" reference="countries"
                onChange={(v) => {                     
                    form.change("shipRegionId", 0);
                    form.change("shipStateId", 0);
                    form.change("shipDistrictId", 0);
                    form.change("shipCityId", 0);
                }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500} className={classes.one_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Region" source="shipRegionId" reference="regions"
                onChange={(v) => {                    
                    form.change("shipStateId", 0);
                    form.change("shipDistrictId", 0);
                    form.change("shipCityId", 0);
                    
                }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500} className={classes.two_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="State" source="shipStateId"
                reference="states" perPage={500}
                filter={{ "regionId": formData.shipRegionId }}
                sort={{ field: 'name', order: 'ASC' }}
                onChange={(v) => {
                    form.change("shipDistrictId", 0);
                    form.change("shipCityId", 0);
                }}
                fullWidth={true}  >
                {/* <SelectInput optionText="name" /> */}
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            
            <ReferenceInput variant="outlined" label="District" source="shipDistrictId"
                reference="districts" perPage={500}
                filter={{ "stateId": formData.shipStateId }}
                sort={{ field: 'name', order: 'ASC' }}
                onChange={(v) => {
                    form.change("shipCityId", 0);
                    
                }}
                fullWidth={true}  >
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            
            <ReferenceInput variant="outlined" label="City" source="shipCityId"
                reference="cities" perPage={500}
                filter={{ "districtId": formData.shipDistrictId }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} >
                {/* <SelectInput   optionText="name" /> */}
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
        </div>
    )
}
