import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';

import { Timeline } from './screens/student/Timeline';

import {ChangePassword} from "./screens/changepassword/ChangePassword";
import {MasterReport} from "./screens/reports/MasterReport";
import {MasterReport2} from "./screens/reports/MasterReport2";
import {MasterReport3} from "./screens/reports/MasterReport3";
import {LeaveReport} from "./screens/reports/LeaveReport";
import {SalaryReport} from "./screens/reports/SalaryReport"; 
import {AttedanceSummaryReport} from "./screens/reports/AttedanceSummaryReport";
import { ModuleExport } from './screens/reports/ModuleExport';

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/timeline" render={(props) => <Timeline {...props}/>} />,
    
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    <Route exact path="/stock" render={(props) => <MasterReport  rptTtile="Stock Status" rpt="stock" {...props} />} />,
    <Route exact path="/saleledger" render={(props) => <MasterReport  rptTtile="Sale Report" rpt="saleledger" {...props} />} />,
    <Route exact path="/itemledger" render={(props) => <MasterReport  rptTtile="Item Ledger" rpt="itemledger" {...props} />} />,
    <Route exact path="/inwardledger" render={(props) => <MasterReport  rptTtile="Inward Report" rpt="inwardledger" {...props} />} />,
    <Route exact path="/accountledger" render={(props) => <MasterReport  rptTtile="Account Ledger" rpt="accountledger" {...props} />} />,
    <Route exact path="/ageing" render={(props) => <MasterReport  rptTtile="Aeging Report" rpt="aegingreport" {...props} />} />,
    <Route exact path="/repoexport" render={(props) => <ModuleExport rptTtile="Module Export" rpt="ModuleExport" {...props} />} />,
    
    // <Route exact path="/hbr" render={(props) => <MasterReport rptTtile="Hospital Bill Report" rpt="HBR" {...props} />} />,
    // <Route exact path="/hir" render={(props) => <MasterReport rptTtile="Hospital Invoice Report" rpt="HIR" {...props} />} />,
    // <Route exact path="/hrc" render={(props) => <MasterReport rptTtile="Hospital Reclaim Report" rpt="HRC" {...props} />} />,
    // <Route exact path="/dsrreport" render={(props) => <MasterReport2 rptTtile="DSR Report" rpt="HRC" {...props} />} />,
    // <Route exact path="/invreport" render={(props) => <MasterReport3 rptTtile="Invoice Report" rpt="HRC" {...props} />} />,
    // <Route exact path="/leavereport" render={(props) => <LeaveReport rptTtile="Leave Report" rpt="HRC" {...props} />} />,
    // <Route exact path="/empsalreport" render={(props) => <SalaryReport rptTtile="Salary Report" rpt="HRC" {...props} />} />,
    // <Route exact path="/attendreport" render={(props) => <AttedanceSummaryReport rptTtile="Attendance Report" rpt="HRC" {...props} />} />,
    
    
];
