import React, { useEffect, useCallback, useState,useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify';

import Pdf from "react-to-pdf";

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import { CircularProgress } from '@material-ui/core';

const GeneratePickslip = (props) => {
    const classes = useStyles({});
    const [isLoading, SetIsLoading] = useState(true)
    const [gridData, setGridData] = useState([])
    const [headerData, setHeaderData] = useState(null)
    
    const ref = useRef() ;

    useEffect(() => {
        loadData();
    }, []);

    const options = {
        orientation: 'landscape',
        unit: 'in',
      //  format: [4,2]
    };

    const loadData = async () => {
        let payload = { transactionId: parseInt(props.record.id) }
        SetIsLoading(true)

        await apiClient.getOrderData( parseInt(props.record.id)).then(res => {
            if (res && res != null) {
                let data =  JSON.parse(res[0].data);
                setHeaderData(data[0]);
                setGridData(data[0].lineItems)
                SetIsLoading(false)
            }
         }).catch(err => {
           console.log(err);
           SetIsLoading(false)
         });

       
    }

    
    
    const handleSubmit = async (formdata) => {

       let m = moment(new Date());
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data = {
      transactionId: props.record.id,
      transactionType: 'ORDER_PICKING',
      createdBy: userId
    }
    await apiClient.addTransactionQueue(data).then(res => {
      if (res[0].result) {
        
        props.onClose();        
      }
      else {
        //notify(res[0].message, "warning")
      }
    }).catch(err => {
      ///notify(err, "warning")
    });
    }
    return (
        <React.Fragment>
         {isLoading &&    <div style={{paddingLeft:"50%",paddingTop:"20%"}}>
            <CircularProgress color="secondary" />
        </div>}
        {!isLoading &&      <Create {...props} >
                <SimpleForm
                    onSubmit={handleSubmit}  
                    toolbar={null}
                    variant="outlined">
                    {!isLoading && <div ref={ref} style={{paddingLeft:"1%",minWidth: "99%",maxWidth: "99%" }} >
                    <div style={{  minWidth: "100%",maxWidth: "100%" }} >
                        <div className="transaction-detail ">
                            Generate Picklist . Order No- {headerData.orderNo} 
                        </div>
                        <div style={{minHeight:400,maxHeight:400,overflow:'auto'}}>
                        <Table className={classes.customtable} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: 5 }} ></TableCell>
                                    <TableCell style={{ width: 200 }} >Product Code</TableCell>
                                    <TableCell >Product Name</TableCell>  
                                    <TableCell >Qty</TableCell>
                                    <TableCell >Price </TableCell>
                                    <TableCell >Discount </TableCell>
                                    <TableCell >Total Amount </TableCell>
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {gridData.map((item, index) => {
                                    return <tr >
                                        <TableCell style={{ width: 5 }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell >
                                            {item.productCode}
                                        </TableCell>
                                        <TableCell >
                                            {item.productName}
                                        </TableCell>
                                        <TableCell >
                                            {item.qty}
                                        </TableCell>
                                        <TableCell >
                                            {item.price }
                                        </TableCell>
                                        <TableCell >
                                            {item.discount }
                                        </TableCell>
                                        <TableCell >
                                            {item.totalAmount }
                                        </TableCell>
                                        
                                        
                                        


                                    </tr>
                                })}
                            </TableBody>
                        </Table>
                        </div>
                    </div>
                    </div>}
                    <Pdf targetRef={ref} filename="print.pdf" options={options}  scale={0.8}>
        {({ toPdf }) => 
        
        <div style={{marginTop:10}}>
            <Button
                style={{ marginLeft: 10 }}
                variant="contained" color="primary"
                size="medium"
                label="Download PDF"
                startIcon={<PrintIcon />}
                onClick={toPdf}
            />

<Button
                style={{ marginLeft: 10 }}
                variant="contained" color="primary"
                size="medium"
                label="Generate Pickslip"
                
                onClick={()=>handleSubmit()}
            />

            <Button
                style={{ marginLeft: 10 }}
                variant="contained" color="primary"
                size="medium"
                label="Cancel"
                startIcon={<CancelIcon />}
                onClick={()=>props.onClose()}
            />
            
            
        </div>
        
        
        }
      </Pdf>
                </SimpleForm>
            </Create>
}
        </React.Fragment>

    );
}
export default GeneratePickslip;