import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const Otherfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <React.Fragment>
            <ReferenceInput variant="outlined" label="Facility" source="facilityId" reference="facilities"
                onChange={(v) => {                     
                    form.change("zoneId", null);
                    
                }}
                sort={{ field: 'name', order: 'ASC' }}
                validate={[required()]}
                fullWidth={true} perPage={500} >
                <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Zone" source="zoneId" reference="zones" validate={[required()]}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ "facilityId": formData.facilityId }}
                fullWidth={true} perPage={500} 
                onChange={(v) => {                     
                    form.change("rowId", null);
                    
                }}
                >
                <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput variant="outlined" label="Row" source="rowId" reference="row-masters" validate={[required()]}
             filter={{ "zoneId": formData.zoneId }}
                fullWidth={true} perPage={5000}
                sort={{ field: 'name', order: 'ASC' }}
                >
                {/* <SelectInput optionText="code" validate={[required()]} /> */}
                <AutocompleteInput optionText="code" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />                  
            </ReferenceInput>

            
            </React.Fragment>
    )
}
