import React,{useState} from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
  Toolbar,
  SaveButton,
  Button,
  BooleanInput,
  FormTab,
  Field,
  Link,
  Grid,ReferenceInput,AutocompleteInput,required,useNotify,useRedirect
} from "react-admin";
import { containerStyles } from "../../common/formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as apiClient from "../../common/apiClient";
import moment from 'moment';

export const ActivityCreate = (props) => {
  const classes = containerStyles(); 
  const[balanceAmount,setBalanceAmount]=useState(0)
  const[clientId,setClientId]=useState(0)
  const[customerId,setCustomerId]=useState(0)
  const notify = useNotify();
  const redirect = useRedirect();
  let propsObj = {
    basePath:"/account-journals" ,
    resource:"Create",
    title: "Journal"
  };

  const loadBalancePayment = (clientId,customerId) => { 
    apiClient.getCustomerBalancePayment(clientId,customerId,0).then(res => { 
      setBalanceAmount(parseFloat(res[0].balanceAmount));
        
    }).catch(err => { 
    });

}

// {
//   "id": "",
//   "paymentDate": "",
//   "clientId": "",
//   "customerId": "",
//   "paymentModeId": "",
//   "depositBankId": "",
//   "referenceNo": "",
//   "remarks": "",
//   "Amount": "",
//   "createdBy": ""
// }



const handleSubmit = async (formdata) => {
        
  let userId;
  userId = localStorage.getItem("userId");
  if (userId) {
    userId = parseInt(userId);
  }

  let data={...formdata};
  let m = moment(new Date());
  
  data. createdDate=  m.toISOString()
  data. createdBy=  userId
  data. entryTypeId=  195
  
  if(data.saleRepId==null)
  {
    data.saleRepId=0;
  }
  apiClient.addEditJournal(data).then(res => {
    notify(res[0].remarks)
    redirect("/account-journals");
      
  }).catch(err => {
      console.log(err);
  });
}

  return (
    <Create titles="Journal Create" {...propsObj}>
    <SimpleForm
        redirect="list"
        
        onSubmit={handleSubmit} 
        toolbar={<FormToolbar onSave={handleSubmit} {...props} showDelete={false} showCancel = {true} />}
        variant="outlined"
      >
        <div style={{width:"50%"}}>
        <DateInput source="journalDate" label="Journal Date" fullWidth variant="outlined" validate={[required()]} />  
       <ReferenceInput label="Client"   fullWidth={true} 
          perPage={5000} source="clientId"
          reference="clients" onChange={(e)=>
          {
            setClientId(e);
           // loadBalancePayment(e,customerId);
          }
          } >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput> 
        <ReferenceInput label="Customer"    fullWidth={true} 
          perPage={5000} source="customerId"
          reference="customers" 
          onChange={(e)=>
          {
            setCustomerId(e);
          //  loadBalancePayment(clientId,e);
          }
            
          } 
          >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <NumberInput source="amount" label="Amount (₹)" fullWidth variant="outlined" validate={[required()]} /> 
        <ReferenceInput label="Journal Type"   fullWidth={true} 
                    perPage={5000} source="journalTypeId"
                    filter={{ type: 'AJT' }} 
                    validate={[required()]}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" />
                </ReferenceInput> 
        
 
      <ReferenceInput label="Sale Representative" fullWidth={true} 
          perPage={5000} source="saleRepId"
          reference="sale-reps" >
          
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>    
        
                <TextInput source="referenceNo" variant="outlined" label="Reference No"  fullWidth={true}   validate={[required()]} />
                <TextInput source="remarks" variant="outlined" label="Remarks"   fullWidth={true}  />  
       </div>
    </SimpleForm>
    </Create>
  );
};

