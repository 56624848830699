import React, { useState, useCallback } from "react";
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput, useNotify, useRedirect,
  ReferenceInput,
  SelectInput, DateField, AutocompleteInput,
  BoolenField, 
  NumberField,
  FormDataConsumer, ReferenceField, useRefresh, RichTextField,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import CDeleteButton from "../../common/CDeleteButton";
import { FormToolbar } from "../../common/FormToolbar";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import ClientCreate from "./ClientCreate";
import ClientEdit from "./ClientEdit";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { useStyles } from "../../formStyles";
import DisCreate from "./DisCreate";

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));

export const ProductEdit = (props) => {
  const [isAddClient, setIsAddClient] = useState(false);
  const [isAddDiscount, setIsAddDiscount] = useState(false);
  const [isEditClient, setIsEditClient] = useState(false);
  const myclasses = myStyles({});
  const classes = useStyles({});
  const [IsEditRecord, setIsEditRecord] = useState(null);
  
  const refresh = useRefresh();
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const handleClose = useCallback(() => {
    setIsAddClient(false);
    setIsEditClient(false);
    setIsAddDiscount(false);

  }, []);
  const onCloseDocumentForm = () => {
    setIsAddClient(false);
    setIsEditClient(false);
    setIsAddDiscount(false);
    refresh();
  }

  const onCancel = () => {
    setIsAddClient(false);
    setIsEditClient(false);
    setIsAddDiscount(false);

  }
  const CustomEdit = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<EditIcon />}>Edit</Button>
  }

  return (
    <React.Fragment>
      <Edit {...propsObj} title="Product Group Edit">

        <TabbedForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
          <FormTab label="Product  Details">
            <TextInput source="code" fullWidth={true} validate={[required()]} formClassName={classes.one_4_input} />
            <TextInput source="name" fullWidth={true} validate={[required()]} formClassName={classes.two_4_input} />
            <TextInput source="otherCode" fullWidth={true} initialValue={""} formClassName={classes.three_4_input} />
            <ReferenceInput label="Product Group" fullWidth={true} formClassName={classes.last_4_input}
              perPage={5000} source="productGroupId"
              reference="product-groups" >
              <SelectInput optionText="name" variant="outlined" validate={[required()]} />
            </ReferenceInput>
            <NumberInput label="Height" validate={[required()]} source="height" initialValue={0} fullWidth={true} formClassName={classes.one_4_input} />
            <NumberInput label="Width" validate={[required()]} source="width" initialValue={0} fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput label="Length" validate={[required()]} source="length" initialValue={0} fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="Weight" validate={[required()]} source="weight" initialValue={0} fullWidth={true} formClassName={classes.last_4_input} />
            <ReferenceInput label="UOM" fullWidth={true} formClassName={classes.one_4_input}
              perPage={5000} source="uomId" validate={[required()]}
              filter={{ baseUomId: 0 }}
              reference="uoms" >
              <SelectInput optionText="name" variant="outlined" validate={[required()]} />
            </ReferenceInput>
            <NumberInput label="Price" validate={[required()]} source="price"  initialValue={0}fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput multiline source="description1" initialValue={""} fullWidth={true} />
            <TextInput multiline source="description2" initialValue={""} fullWidth={true} />
            <NumberInput label="Threshold Quantity" validate={[required()]} source="thresholdQuantity"  initialValue={0}fullWidth={true} formClassName={classes.one_three_input} />
            <BooleanInput source="isActive" validate={[required()]}  label="Active"  fullWidth={true} formClassName={classes.two_three_input}/>
          </FormTab>
          <FormTab label="Clients">
            <div>
              <Button onClick={() => { setIsAddClient(true); }}>Add Client</Button>
            </div>



            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="product-client-maps"
              target="productId">

              <Datagrid >

                <CDeleteButton {...props} />
                <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditClient(true); }} label="Edit" /> 
                <ReferenceField source="clientId" reference="clients" label="Client " link={false}>
                  <TextField source="name" />
                </ReferenceField> 
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
          <FormTab label="Discounts">
            <div>
              <Button onClick={() => { setIsAddDiscount(true); }}>Add Customer Discount</Button>
            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="customer-item-wise-discounts"
              target="productId">
              <Datagrid >
                <CDeleteButton {...props} />
                <ReferenceField source="clientId" reference="clients" label="Client " link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="customerId" reference="customers" label="Customer " link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <NumberField  source="discount" />
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
        </TabbedForm>

      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper }}
        open={isEditClient || isAddClient || isAddDiscount} >
        {isAddClient && <ClientCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          productId={props.id}

          {...props} />}

        {isEditClient && <ClientEdit
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}

          record={IsEditRecord}
          {...props} />

        }
        {isAddDiscount && <DisCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          productId={props.id}

          {...props} />}


      </Drawer>
    </React.Fragment>
  );
};
