
import React, { useEffect, useCallback, useState } from 'react'; 
import { toast } from 'react-toastify';
import './RevisePickslip.css';
import { v4 as uuidv4 } from 'uuid';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, TabbedForm, FormTab

} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import './CustomTab.css'; // Import CSS for styling

import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import OrderItemList from './OrderItemList';


const RevisePickslip = (props) => {
    const classes = useStyles({});
    const [isLoading, SetIsLoading] = useState(true)
    const [reviseData, setReviseData] = useState(null)

    const [isShort, setIsShort] = useState(false)
    const [isAutoAllocate, setIsAutoAllocate] = useState(true)
    const [isAutoAllocateDisabled, setIsAutoAllocateDisabled] = useState(false)
    const [autoAllocatedQty, setAutoAllocatedQty] = useState(null)
    const [shortQty, setShortQty] = useState(null)
    const [activeTab, setActiveTab] = useState(1); // State to manage active tab

    useEffect(() => {
        loadData();
    }, []);
    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    const loadData = async () => {
        SetIsLoading(true)
        await apiClient.getPicklistRevision(props.pickdetail.pickListId).then(res => {
            debugger
            if (res && res != null) {
                debugger
                let data = JSON.parse(res[0].data);
                setReviseData(data[0].data[0]);
                if (data[0].data[0].availableStk.length == 0) {
                    setIsShort(true);
                    setIsAutoAllocate(false);
                    setIsAutoAllocateDisabled(true);
                }
                SetIsLoading(false)
            }
        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });
    }

    const changeQty = async (event, index) => {
        let _data = reviseData;
        _data.availableStk[index].allocatedQty = event.target.value;
        setReviseData(_data);
    }

    const handleSubmit = async (type, index) => {
        debugger
        let payload = { ...reviseData };
        let availableStk_allocated = [];
        if (type == "Short_Qty") {
            if (!shortQty || shortQty <= 0) {
                toast.error("Validation issue : Please enter Short Quantity", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            else if (shortQty > reviseData.qty) {
                toast.error("Validation issue : Short quantity cannot be more than pick qty ", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
        }

        if (type == "Allocated_Qty") {
            let _item = reviseData.availableStk[index];
            availableStk_allocated.push(_item);
            payload.availableStk = [];
            payload.availableStk = availableStk_allocated;
            payload.revisionQty = _item.allocatedQty;
        }
        if (type == "Auto_Allocated_Qty") {
            if (!autoAllocatedQty || autoAllocatedQty <= 0) {
                toast.error("Validation issue : Please enter Auto Allocated quantity", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            else if (autoAllocatedQty > reviseData.qty) {
                toast.error("Validation issue : Auto Allocated quantity cannot be more than pick qty ", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
        }
        if (type == "Allocated_Qty") {
            if (payload.availableStk.length == 0) {
                toast.error("Validation issue : There is no record to manaul allocation ", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            else if (!payload.availableStk[0].allocatedQty) {
                toast.error("Validation issue : Enter allocated qty ", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            else if (parseInt(payload.availableStk[0].allocatedQty) > parseInt(payload.qty)) {
                toast.error("Validation issue : Enter allocated qty cannot be more than pick qty", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            else if (parseInt(payload.availableStk[0].allocatedQty) <= 0) {
                toast.error("Validation issue : Enter valid allocated qty", {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
        }
        if (type == "Short_Qty") {
            payload.isShort = 1;
            payload.revisionQty = shortQty
        }
        if (type == "Auto_Allocated_Qty") {
            payload.isAutoAllocate = 1;
            payload.revisionQty = autoAllocatedQty
        }

        SetIsLoading(true)

        let final_payload = { data: [{ data: [] }] };
        final_payload.data[0].data = [{ data: { ...payload } }];
        // console.log(JSON.stringify(final_payload),"****final_payload****")
        // return
        await apiClient.AddRevisePick({ data: [{ data: { ...payload } }] }).then(res => {
            debugger
            if (res && res != null) {
                debugger
                if (res[0].result) {
                    //setReviseData(null);
                    let data = JSON.parse(res[0].data);
                    //setIsAutoAllocate(false);
                    setAutoAllocatedQty(null);
                    setShortQty(null);
                    setReviseData(data[0].data[0]);
                    if (data[0].data[0].availableStk.length == 0) {
                        setIsShort(true);
                        setIsAutoAllocate(false);
                        setIsAutoAllocateDisabled(true);
                    }

                    toast.success("Success  : " + res[0].message, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                else {
                    toast.error("Error :" + res[0].message, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }

                SetIsLoading(false)
            }
        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });

        ///orders/RevisePick
        // //pending-putaway-data
        // //props.onClose(attributeData,'attribute');

        // //good-inward-orders/confirmputaway/{grnId}/{userId}

        // let userId;
        // userId = localStorage.getItem("userId");
        // if (userId) {
        //     userId = parseInt(userId);
        // }
        // SetIsLoading(true)
        // await apiClient.confirmputaway(parseInt(props.record.id), userId).then(res => {
        //     debugger
        //     if (res && res != null) {
        //         if(res[0].id>0 )
        //         {
        //             toast.success("Success  : " + res[0].Remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //             props.onClose();
        //         }
        //         else
        //         {
        //             toast.error("Validation issue : " + res[0].Remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //         }
        //         SetIsLoading(false)
        //     }

        // }).catch(err => {
        //     console.log(err);
        //     SetIsLoading(false)
        // });

    }
    return (
        <React.Fragment>
            <Create {...props} resouce={null} >
                <SimpleForm toolbar={null}
                    variant="outlined"

                >
                    <div className="custom-tabs-container">
                        <div className="custom-tabs">
                            <div
                                className={`custom-tab ${activeTab === 1 ? 'active' : ''}`}
                                onClick={() => handleTabChange(1)}
                            >
                                Pick Revision
                            </div>
                            <div
                                className={`custom-tab ${activeTab === 2 ? 'active' : ''}`}
                                onClick={() => handleTabChange(2)}
                            >
                                Cancel Item
                            </div>

                        </div>
                        <div className="custom-tab-content">
                            {activeTab === 1 && <div >
                                {isLoading && <div className='revise-heading'>
                                    Please Wait......
                                </div>}
                                {!isLoading && reviseData && <div fullWidth={true} >

                                    <div className='shortcontainer' fullWidth={true}>
                                        <div fullWidth={true}>
                                            <BooleanInput initialValue={isShort} source="isShort" label="Short Close" onChange={(event) => { setIsShort(event); }} />
                                        </div>
                                        <div fullWidth={true}>
                                            {isShort && <NumberInput onChange={(event) => setShortQty(event.target.value)} style={{ marginTop: 4, marginRight: 4 }} variant="outlined" source={uuidv4()} initialValue={shortQty} label="Short Close Quantity" />}

                                        </div>
                                        <div fullWidth={true}>
                                            {isShort && <Button style={{ marginTop: 4 }} color="primary" variant="contained" onClick={() => handleSubmit("Short_Qty")}> Confirm Short Close </Button>}
                                        </div>
                                    </div>
                                    <div className='shortcontainerpick-product' fullWidth={true}>
                                        <div className='item'>
                                            Product
                                        </div>
                                        <div className='code'>
                                            {"( "}{reviseData.productCode}{" ) "}{reviseData.productName}
                                        </div>

                                    </div>
                                    <div className='shortcontainerpick-pickbin' fullWidth={true}>
                                        <div className='col'>
                                            Pick Bin
                                        </div>
                                        <div className='val'>
                                            {reviseData.binName}
                                        </div>

                                        <div className='col'>
                                            Qty To Pick
                                        </div>
                                        <div className='val'>
                                            {reviseData.qty}
                                        </div>



                                    </div>
                                    <div className='shortcontainerpick-pickbin' fullWidth={true}>
                                        <div className='col'>
                                            Price
                                        </div>
                                        <div className='val'>
                                            {reviseData.price}
                                        </div>
                                        <div className='col'>
                                            .
                                        </div>
                                        <div className='val'>

                                            .
                                        </div>



                                    </div>
                                    {!isShort && !isAutoAllocateDisabled && <div className='shortcontainer' fullWidth={true}>
                                        <div fullWidth={true}>
                                            <BooleanInput source="isAutoAllocate" initialValue={isAutoAllocate} label="Auto Allocate" onChange={(event) => setIsAutoAllocate(event)} />
                                        </div>
                                        <div fullWidth={true}>
                                            {isAutoAllocate && <NumberInput initialValue={autoAllocatedQty}
                                                onChange={(event) => setAutoAllocatedQty(event.target.value)} style={{ marginTop: 4, marginRight: 4 }} variant="outlined" source={uuidv4()} label="Auto Allocated Quantity" />}

                                        </div>
                                        <div fullWidth={true}>
                                            {isAutoAllocate && <Button style={{ marginTop: 4 }} color="primary" variant="contained" onClick={() => handleSubmit("Auto_Allocated_Qty")} > Confirm Auto Allocate </Button>}
                                        </div>
                                    </div>
                                    }
                                    {reviseData.availableStk && reviseData.availableStk.length == 0 && <div className='revise-no-stock'>
                                        No Stock Available On other Bins
                                    </div>}
                                    {!isShort && reviseData.availableStk && reviseData.availableStk.length > 0 && <div className='revise-heading'>
                                        Available Stock
                                    </div>}
                                    {!isShort && reviseData.availableStk && reviseData.availableStk.length > 0 && <div className='revise-stockgrid' >
                                        <table>
                                            <tr>
                                                <th>Bin</th>
                                                <th>Available Qty</th>
                                                {!isAutoAllocate && <th>Allocated Qty</th>}
                                                {!isAutoAllocate && <th>Action</th>}
                                            </tr>
                                            <tbody>
                                                {reviseData.availableStk.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.binName}</td>

                                                            <td>{item.qty}</td>
                                                            {!isAutoAllocate && <td><NumberInput
                                                                variant="outlined" source={"allocatedQty_" + uuidv4()} label="Enter Allocated Qty" onChange={(event) => changeQty(event, index)} /></td>}
                                                            {!isAutoAllocate && <td>

                                                                {<Button style={{ marginTop: 4 }} color="primary" variant="contained" onClick={() => handleSubmit("Allocated_Qty", index)} > Allocate </Button>}
                                                            </td>}
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>}
                                    {<Button style={{ marginTop: 20 }} onClick={props.on_Close} color="primary" variant="contained"> Close</Button>}
                                </div>
                                }

                            </div>}
                            {activeTab === 2 && <div>
                                <OrderItemList orderId={props.orderId} />
                                {<Button style={{ marginTop: 20 }} onClick={props.on_Close} color="primary" variant="contained"> Close</Button>}
                                </div>}

                        </div>
                    </div>






                </SimpleForm>
            </Create>

        </React.Fragment>

    );
}
export default RevisePickslip;