import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormDataConsumer
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { Otherfields } from "./Otherfields";

export const UomsCreate = (props) => {
  return (
    <Create title="Uom Create" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <FormDataConsumer>
          {formDataProps => (
            <Otherfields {...formDataProps} />
          )}
        </FormDataConsumer>
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
      </SimpleForm>
    </Create>
  );
};
