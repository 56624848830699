import React, { useState,useEffect } from "react";
import * as apiClient from "../../common/apiClient"; 
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import moment from "moment";

export const OrderCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect=useRedirect();
const [gridData, setGridData] = useState([{ lineNumber: 1, productId:null , productName: null, uomId: 0, price: null, qty: null,damageQty: null, totalAmount: null,attributes:[],uomData:[]}]);



  useEffect(() => {
  setGridData(gridData)
  
  });
  
  const handleChange = (indata) => { 
     setGridData(indata);
  }

  const handleSubmit = async (formdata) => {
        //alert("c");
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let m = moment();

     
    
    let data=
    {
      

      autoNo: 0,
      dispatchOrderNo: "",
      dispatchOrderDate: formdata.dispatchOrderDate,
      facilityId: formdata.facilityId,
      clientId: formdata.clientId, 
      remarks: formdata.remarks, 
      carrierId: formdata.carrierId, 
      totalOrders: 0,
      totalBox: formdata.totalBox, 
      vehicleNo: formdata.vehicleNo, 
      driverName:  formdata.driverName,
      driverContactNo:  formdata.driverContactNo,
      awbNo: "", 
      awbDate:  formdata.awbDate,
      totalQty: 0,
      totalAmount: 0,
      statusId: 0,
      createdBy:parseInt(userId),
      createdDate: m.toISOString(),      
      isActive: true
    }
    
    let m1 = moment(data.dispatchOrderDate);
    if(data.dispatchOrderDate==null)
      {
        delete data["dispatchOrderDate"]
      }
      else
      {
        data=apiClient.padDate(data,"dispatchOrderDate");  
        // let m1 = moment(data.awbDate);
        // data.awbDate = m1.toISOString();
      }
   
    if(data.awbDate==null)
      {
        delete data["awbDate"]
      }
      else
      {
        data=apiClient.padDate(data,"awbDate");  
        // let m1 = moment(data.awbDate);
        // data.awbDate = m1.toISOString();
      }
       
   
     data.createdBy=  userId
    apiClient.addDispatchOrder(data).then(res => {
      if(res.id>0)
      {
        notify("Dispatch Order Created")
        redirect(`/dispatch-orders/${res.id}/1`);
      }
      else
      {
        notify("Error","warning")

      }
        //console.log(res);
        //props.onClose();
    }).catch(err => {
        console.log(err);
    });
}
  return (
    <Create title="Dispatch Order Create" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} toolbar={<FormToolbar  onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >
        <div className="transaction-header">
        Dispatch Order Detail
        </div>
        <ReferenceInput label="Facility" fullWidth={true} formClassName={classes.first_inline_input}
          perPage={5000} source="facilityId"
          sort={{ field: 'name', order: 'ASC' }}
          reference="facilities" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
         
        <ReferenceInput label="Client" fullWidth={true} formClassName={classes.last_inline_input}
          perPage={5000} source="clientId"
          sort={{ field: 'name', order: 'ASC' }}
          reference="clients" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>

        
        <DateInput source={"dispatchOrderDate"} label={"Disptach Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.first_inline_input} />

        <ReferenceInput label="Carrier" fullWidth={true} formClassName={classes.last_inline_input}
              perPage={5000} source="carrierId"
              sort={{ field: 'name', order: 'ASC' }}
                reference="carriers" >
              <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        
        <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true} />
        <NumberInput source="totalBox" label="Total Box" initialValue={0}  validate={[required()]} fullWidth={true} disabled formClassName={classes.first_inline_input} />
        <TextInput source="vehicleNo" label="Vehicle No"  validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} />
        <TextInput source="driverName" label="Driver Name"  validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput source="driverContactNo" label="Driver Contact No"  validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} />
        
	
        
             
            
              </SimpleForm>
    </Create>
  );
};
