import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import { MyDataProvider } from "./providers/MyDataProvider";
import VSDataProvider from "./providers/VSDataProvider";

import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { MainHome } from "./layout/Home";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import roletypes from './screens/roletypes';

import areas from './screens/areas';
import users from './screens/users';
import employee from './screens/employee';
import rowMaster from './screens/RowMaster';
import binMaster from './screens/BinMaster';
import insurer from './screens/insurer';
import corporate from './screens/Region';



import leavetype from './screens/leavetype';
import holidaylist from './screens/holidaylist';
import employeeleavelist from './screens/employeeleavelist'; 
import employeeleaveapprove from './screens/employeeleaveapprove'; 
import employeesalary from './screens/employeesalary'; 
import dsr from './screens/dsr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configs from './screens/configs';
import bankmaster from "./screens/bankmaster";

import customergroup from "./screens/customergroup";
import motorfuel from "./screens/motorfuel";
import motormodel from "./screens/motormodel";
import motorvariant from "./screens/motorvariant";
import policycategory from "./screens/policycategory";
import policydepartment from "./screens/policydepartment";
import policytype from "./screens/policytype";
import ratemaster from "./screens/ratemaster";
import motormake from "./screens/motormake";
import motorclass from "./screens/motorclass";
import facility from "./screens/Facility";
import industryclass from "./screens/industryclass";
import dimensiongroup from "./screens/dimensiongroup";
import entrytype from "./screens/entrytype";
import customer from "./screens/customer";
import policymaster from "./screens/policymaster";
import zone from "./screens/Zone";
import taxhead from "./screens/taxhead";
import region from "./screens/Region";
import RowMaster from "./screens/BinMaster";
import uoms from "./screens/uoms";
import productgroup from "./screens/productgroup";
import carrier from "./screens/carrier";
import Vendor from "./screens/Vendor";
import client from "./screens/client";
import city from "./screens/city";
import product from "./screens/product";
import purchaseorder from "./screens/purchaseorder";
import attributegroup from "./screens/attributegroup";
import goodinwardorder from "./screens/goodinwardorder";
import orders from "./screens/orders";
import state from "./screens/state";
import country from "./screens/country";
import district from "./screens/district";
import disptachorders from "./screens/disptachorders";
import saleRep from "./screens/saleRep";
import orderpacking from "./screens/orderpacking";
import unfulfilledorders from "./screens/unfulfilledorders";
import payment from "./screens/payment";
import accountJournals from "./screens/account-journals";
import unapprovedorder from "./screens/unapprovedorder";





const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);

  }, []);

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource title="Area" name="areas" {...areas} />
        <Resource title="User" name="users" {...users} />
        <Resource title="Employee" name="employees" {...employee} />
        
        <Resource title="Insurer" name="insurers" {...insurer} />
        <Resource title="Region" name="regions" {...region} />
        
        
        <Resource name="lookups" list={ListGuesser} edit={EditGuesser} />
        
        <Resource title="Role Type" name="role-types" {...roletypes} />
         
        <Resource name="hospital-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configdets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configs" {...configs} />
        <Resource name="dsrs" {...dsr} />
        
        <Resource name="leave-types" {...leavetype}/>
        <Resource name="holiday-lists" {...holidaylist}/>
        <Resource name="employee-leaves" {...employeeleavelist}/>
        <Resource name="employee-leaves-approve"  {...employeeleaveapprove}/>
        <Resource name="employee-sal"  {...employeesalary}/>
        <Resource name="employee-sal-dets"  list={ListGuesser} edit={EditGuesser}  />
        
        
        <Resource name="cust-groups" {...customergroup} />
        
        <Resource name="customer-contacts" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="product-client-maps" list={ListGuesser} edit={EditGuesser}  />
        
        <Resource name="facilities" {...facility} />
        <Resource name="policy-categories" {...policycategory} />
        <Resource name="policy-departments" {...policydepartment}  />
        <Resource name="industry-classes" {...industryclass}  />
        <Resource name="dimension-groups" {...dimensiongroup}  />
        <Resource name="products" {...product}  />
        <Resource name="product-group-attributes" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="rate-masters" {...ratemaster} />
        <Resource name="customers" {...customer} />
        <Resource name="countries" {...country}/>
        <Resource name="states" {...state} />
        <Resource name="districts" {...district} />
        <Resource name="clients" {...client} />
        <Resource name="cities" {...city} />
        <Resource name="vendors" {...Vendor} />
        <Resource name="carriers" {...carrier} />
        <Resource name="product-groups" {...productgroup}/>
        <Resource name="uoms"  {...uoms}/>
        <Resource name="bin-masters" {...binMaster}/> 
        <Resource name="row-masters" {...rowMaster}/> 
        <Resource name="policy-masters" {...policymaster} />
        <Resource name="zones" {...zone} />
        <Resource name="tax-heads" {...taxhead} />
        
        <Resource name="purchase-orders" {...purchaseorder} />
        <Resource name="purchase-order-dtls" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="purchase-order-line-dtls" list={ListGuesser} edit={EditGuesser}  />

        <Resource name="good-inward-orders" {...goodinwardorder} />
        <Resource name="good-inward-order-dtls" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="good-inward-order-line-dtls" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="attribute-groups" {...attributegroup} />
        <Resource name="attribute-group-dtls" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="product-global-attributes" list={ListGuesser} edit={EditGuesser}  /> 
        <Resource name="customer-item-wise-discounts" list={ListGuesser} edit={EditGuesser}  /> 
        <Resource name="orders" {...orders} />
        <Resource name="dispatch-orders" {...disptachorders} /> 
        <Resource name="dispatch-order-dtls" list={ListGuesser} edit={EditGuesser}  /> 
        <Resource name="client-facility-maps" list={ListGuesser} edit={EditGuesser}  />  
        <Resource name="sale-reps" {...saleRep}/> 
        <Resource name="order-packets" {...orderpacking} />  
        <Resource name="orders-unfulfilleds" {...unfulfilledorders} />  
        <Resource name="orders-unfulfilled-dtls" list={ListGuesser} edit={EditGuesser}  />  
        <Resource name="user-facility-maps" list={ListGuesser} edit={EditGuesser}  />  
        <Resource name="bank-masters" list={ListGuesser} edit={EditGuesser}  />  
        <Resource name="vw-dispatch-orders" {...disptachorders} /> 
        <Resource name="account-payments" {...payment} />  
        <Resource name="account-journals" {...accountJournals} />  
        <Resource name="user-state-maps" list={ListGuesser} edit={EditGuesser}  />  
        <Resource name="un-approved-orders" {...unapprovedorder} />  
        <Resource name="user-customer-state-maps" list={ListGuesser} edit={EditGuesser}  />  
        
        
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;
