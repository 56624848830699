import React,{useState,useCallback} from 'react';
import {
  
  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
  useRefresh,TabbedForm, FormTab,ReferenceManyField,Datagrid,TextField,ReferenceField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate'; 
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import FacilityMapCreate from './FacilityMapCreate';
import StateMapCreate from './StateMapCreate';
import CustomerStateMapCreate from './CustomerStateMapCreate';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));
export const UsersEdit = (props) => {
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const [IsEditRecord, setIsEditRecord] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [isAddState, setIsAddState] = useState(false);
  const [isAddCustomerState, setIsAddCustomerState] = useState(false);
  const classes = useStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const handleClose = useCallback(() => {
    setIsAdd(false);
    setIsAddState(false);
    setIsAddCustomerState(false);
    

  }, []);
  const onCloseDocumentForm = () => {
    setIsAdd(false);
    setIsAddState(false);
    setIsAddCustomerState(false);
    
    refresh();
  }
  return (
    <>
    <Edit title="User Edit" {...propsObj}>
      
      <TabbedForm   toolbar={<FormToolbar {...props} />} variant="outlined">
                <FormTab label="User  Details">
      <TextInput source="code"  validate={[required()]} formClassName={classes.one_three_input}/>
      <TextInput source="userName" formClassName={classes.two_three_input}  />
      <PasswordInput source="password" formClassName={classes.last_three_input}  />

      <ReferenceInput label="Role" source="roleTypeId" reference="role-types" formClassName={classes.one_three_input}>
              <SelectInput optionText="roleName" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput label="Type" formClassName={classes.two_three_input} 
                    perPage={5000} source="typeId"
                    filter={{ type: 'USR' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput>
       
        <DateInput source="startDate" formClassName={classes.last_three_input}/>
        <DateInput source="endDate" formClassName={classes.one_three_input}  />
        <NumberInput source="allowEditDays" label="Allow Editing For Days " formClassName={classes.two_three_input}  />
        <NumberInput source="allowAddDays" label="Allow Deletion For Days " formClassName={classes.last_three_input}/>
        <FormDataConsumer formClassName={classes.one_three_input}>
          {({ formData, ...rest }) =>
            formData.id && 
            <ReferenceInput  perPage={2000} label="Approver" source="approverId" reference="users" variant="outlined" formClassName={classes.one_three_input} filterToQuery={(id) => ({ 'id~neq': formData.id })} sort={{ field: 'userName', order: 'ASC' }}v>
            <SelectInput optionText="userName" formClassName={classes.one_three_input} variant="outlined" validate={[required()]} />
          </ReferenceInput> 
          }
        </FormDataConsumer>
        
        
      
        {/* <BooleanInput source="isSuperUser" validate={[required()]}  label="Super User" formClassName={classes.two_three_input}  />  */}
        <BooleanInput source="isActive" validate={[required()]}  label="Active"  formClassName={classes.two_three_input}  />
        
        <FormDataConsumer formClassName={classes.last_three_input}>
          {({ formData, ...rest }) =>
            formData.typeId &&
            formData.typeId === 10 &&
             (
              <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" formClassName={classes.last_three_input} variant="outlined" >
                <SelectInput optionText="name"  validate={[required()]}  variant="outlined" formClassName={classes.last_three_input} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        </FormTab>
        <FormTab label="Facility Mapping">
            <div>
              <Button onClick={() => { setIsAdd(true); }}>Add Facility Mapping</Button>
            </div>



            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="user-facility-maps"
              target="userId"> 
              <Datagrid > 
                <CDeleteButton {...props} /> 
                
                 

                <ReferenceField source="facilityId" reference="facilities" label="Facility " link={false}>
                  <TextField source="name" />
                </ReferenceField>

                 
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
          <FormTab label="Approval State Mapping">
            <div>
              <Button onClick={() => { setIsAddState(true); }}>Add State Mapping</Button>
            </div>



            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="user-state-maps"
              perPage={5000}
              target="userId"> 
              <Datagrid > 
                <CDeleteButton {...props} /> 
                
                 

                <ReferenceField source="clientId" reference="clients" label="Client " link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="stateId" reference="states" label="State " link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="approverTypeId" reference="lookups" label="Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                 
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
          <FormTab label="Customer State Mapping">
            <div>
              <Button onClick={() => { setIsAddCustomerState(true); }}>Add Customer State Mapping</Button>
            </div>



            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="user-customer-state-maps"
              perPage={5000}
              target="userId"> 
              <Datagrid > 
                <CDeleteButton {...props} /> 
                
                 
 
                <ReferenceField source="stateId" reference="states" label="State " link={false}>
                  <TextField source="name" />
                </ReferenceField>
                 

                 
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
      </TabbedForm>
    </Edit>
      <Drawer
      anchor="right"
      onClose={handleClose}
      classes={{ paper: myclasses.drawerPaper }}
      open={ isAdd || isAddState || isAddCustomerState} >
      {isAdd && <FacilityMapCreate
        onClose={onCloseDocumentForm}
        onCancel={handleClose}
        userId={props.id}

        {...props} />}
{isAddState && <StateMapCreate
        onClose={onCloseDocumentForm}
        onCancel={handleClose}
        userId={props.id}

        {...props} />}
{isAddCustomerState && <CustomerStateMapCreate
        onClose={onCloseDocumentForm}
        onCancel={handleClose}
        userId={props.id}

        {...props} />}

      
    </Drawer>
    </>
  );
};
