import React,{useState,useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, TabbedForm, FormTab

} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as apiClient from "../../common/apiClient";
import { Button } from '@material-ui/core';
import { Input } from '@material-ui/icons';

const StyledTableCell = withStyles(theme => ({
    head: {
        //backgroundColor: theme.palette.common.black,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.black,
        },
    },
}))(TableRow);

 

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const OrderItemList = (props) => {
    const[items,setItems]=useState([])
    const[isLoading,setIsLoading]=useState(false)
    const[isSubmiting,setIsSubmiting]=useState(false)
    const classes = useStyles();

    useEffect(() => {
        loadData();
    }, []);
    const loadData = async () => {
        setIsLoading(true)
        await apiClient.getOrderDetail(props.orderId).then(res => { 
            if (res && res != null) { 
                let data = JSON.parse(res[0].data);
                setItems(data[0].lineItems)   
            }
            setIsLoading(false)
        }).catch(err => {
            console.log(err);
            setIsLoading(false)
        });
    }
    const handleRemarks = async (event,index) => {
        let data=[...items];
        data[index]['remarks']=event.target.value;
        setItems(data);
    }
    
    const handleCancel = async (index) => {
        let item=items[index];
        
        const userConfirmed = window.confirm('Are you sure you want to proceed?');
        if (userConfirmed)
        {
            
            let userId;
            userId = localStorage.getItem("userId");
            if (userId) {
                userId = parseInt(userId);
            }
            let data = {
                orderId: props.orderId,            
                userId:userId ,
                ...item
            }
            setIsSubmiting(true);
            await apiClient.PickingCancelProduct(data).then(res => {
                if (res[0].result) {
                  //  props.onClose();
    
                    toast.success(res[0].message, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    // refresh();
                    loadData();
                   
                }
                else {
    
                    toast.error(res[0].message, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    setIsSubmiting(false);
                }
            }).catch(err => {
                toast.error(err, {
                    position: "bottom-center",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                setIsSubmiting(false);
                //notify(err, "warning")
            });
        }
       
    }
    
    return (<div style={{paddingBottom:10}}>
        <div style={{maxHeight:400,overflow:'auto'}}  >
        {!isLoading && <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Product Code</StyledTableCell>
                    <StyledTableCell >Product</StyledTableCell>
                    <StyledTableCell >Order Qty</StyledTableCell>
                    <StyledTableCell >Qty To Be Pick</StyledTableCell>
                    <StyledTableCell >Price</StyledTableCell>  
                    <StyledTableCell >Discount</StyledTableCell>
                    <StyledTableCell >Status</StyledTableCell>
                    <StyledTableCell>Remarks</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell> 
                    
                </TableRow>
            </TableHead>
         
            <TableBody>
                {items.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>{row["productCode"]}</StyledTableCell>
                        <StyledTableCell>{row["productName"]}</StyledTableCell>
                        <StyledTableCell>{row["qty"]}</StyledTableCell>
                        <StyledTableCell>{row["packedQty"]}</StyledTableCell> 
                        <StyledTableCell>{row["price"]}</StyledTableCell> 
                        <StyledTableCell>{row["discount"]}</StyledTableCell> 
                        <StyledTableCell>{row["status"]}</StyledTableCell> 
                        <StyledTableCell>
                        <textarea  value={row["remarks"]} onChange={(event)=>handleRemarks(event,index)}  multiline style={{fontSize:10, marginTop: 4, marginRight: 4,outline:'none',border:1,borderColor:'grey',borderRadius:5 }} placeholder='Enter Cancel Remarks'  />
                            </StyledTableCell>
                        <StyledTableCell>
                        {row["statusId"]!="189" &&<Button disabled={isSubmiting}   onClick={()=>handleCancel(index)} color="primary" variant="contained">{isSubmiting?'Please wait..': 'Cancel Item'}</Button>}
                        </StyledTableCell>
                        
                        
                        
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>}
        </div>
        </div>
    );
}
export default OrderItemList;