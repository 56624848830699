import React, { useEffect, useCallback, useState } from 'react';
import * as apiClient from "../../common/apiClient";

import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email, Create,
  choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
  Edit, SimpleForm, TextInput,
  DateInput, BooleanInput, NumberInput, FileInput, FileField,
  ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import './ProductImport.css';
import PopupImportToolbar from "../../common/PopupImportToolbar";
import { useStyles } from "../../formStyles";
import Papa from 'papaparse';
import FileUpload from './FileUpload';


const ProductImport = (props) => {
  const classes = useStyles({});
  const [csvData, setCsvData] = useState([]);
  const [list, setList] = useState([]);
  const [listCols, setListCols] = useState([]);
  const [invalidListCols, setInvalidListCols] = useState([]);
  
  const [invalidList, setInvalidList] = useState([]);
  const handleFileUpload = (data) => {
  console.log(JSON.stringify(data),"*****data*****")    
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element.productCode == null || element.productCode == "") {
       // delete data[index]
      }

    }
    setCsvData(data);
  };
  const handleSubmit = (formdata) => {
    if(list.length>0)
    {
      props.onSuccess(list);
    }
   
  };
  const validateFile = (formdata) => {

    let payload = {
      data: {
        facilityId: 1,
        clientId: 1,
        list: csvData
      },
      recordType: "order_product"
    }
    apiClient.importData(payload).then(res => {
      
      let data = res[0];
      if (data && data.list && data.list.length>0) {
        setList(JSON.parse(data.list))
          let _list=JSON.parse(data.list);
          if(_list && _list.length>0)
          {
            let keys = Object.keys(JSON.parse(data.list)[0]);
            setListCols(keys);
          }
          else
          {
            
          setListCols([]);
          }
      
      } 
        if (data && data.invalidList && data.invalidList.length>0) {
          let _list=JSON.parse(data.invalidList);
        setInvalidList(JSON.parse(data.invalidList))
        if(_list && _list.length>0)
          {
            let keys = Object.keys(JSON.parse(data.invalidList)[0]);
           setInvalidListCols(keys);
          }
          else
          {
            
            setInvalidListCols([]);
          }
        
        
      }
    }).catch(err => {
     // console.log(err);
    });
  };
  return (
    <>
      <Create title="" basePath="importfile" resource="importfile">
        <SimpleForm
          onSubmit={handleSubmit} 
          toolbar={<PopupImportToolbar showSave={list.length>0?true:false} label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel}
          validateFile={validateFile}
           />}
          variant="outlined"
        >
          <FileUpload onFileUpload={handleFileUpload} />
          {list.length > 0 && <div className='header validrecord'>Total Valid Records {list.length }</div>}
          {invalidList.length > 0 && <div  className='header invalidrecord'>Total invalid Records {invalidList.length}</div>}
          <div className="dual-tables-container">
          {listCols.length>0 &&  <div className="table-container left-table">
              <div  className='header validrecord'>Valid List</div>
              <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
                    
                    <tr>
                        {listCols && listCols.map((item, index) => {
                            return (
                                <th key={item}>{item}</th>
                            )
                        })}
                    </tr>

                    {list && list.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                {listCols && listCols.map((col, colIndex) => {
                                    return (
                                        <td key={rowIndex + "-" + colIndex}>
                                            {row[col]}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </table>
            </div>
}

           {invalidListCols.length>0 && <div className="table-container right-table">
              <div  className='header invalidrecord'>Invalid List</div>
              <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
                    
                    <tr>
                        {invalidListCols && invalidListCols.map((item, index) => {
                            return (
                                <th key={item}>{item}</th>
                            )
                        })}
                    </tr>

                    {invalidList && invalidList.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                {invalidListCols && invalidListCols.map((col, colIndex) => {
                                    return (
                                        <td key={rowIndex + "-" + colIndex}>
                                            {row[col]}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </table>
            </div>
}
          </div>
        </SimpleForm>
      </Create>

    </>
  );
};

export default ProductImport;
