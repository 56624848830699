import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const PolicyDepartmentEdit = (props) => {
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  
  return (
    <Edit {...propsObj}  title="Policy Department Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
       <NumberInput  variant="outlined" label="Max TP Reward Rate" source="maxTpRewardRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Max Od Reward Rate" source="maxOdRewardRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Max TP Brok Rate" source="maxTPBrokRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Max OD Brok Rate" source="maxOdBrokRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Policy Expiry Days" source="policyExpiryDays" fullWidth={true}  />
       <BooleanInput source="isMotorDetails" validate={[required()]}  label="is Motor Details"  />
       <BooleanInput source="isHealthType" validate={[required()]}  label="Is Health Type" />
       <BooleanInput source="isUnderIrda" validate={[required()]}  label="Under IRDA" />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Edit>
  );
};
