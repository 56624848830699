import React, { useEffect,useCallback,useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';

import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import  PopupCreateToolbar  from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const FacilityMapEdit = props => {
    const classes = useStyles({});
   
    
    

    const handleSubmit = async (formdata) => {
        
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }

        let data={...formdata};
        let m = moment(new Date());
        
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("client-facility-maps", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="client-facility-maps" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
  <ReferenceInput label="Facility" fullWidth={true}
                    perPage={5000} source="facilityId"
                    filter={{ type: 'AT' }}
                    reference="facilities" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                <TextInput source="gstNo" label='Gst No' fullWidth={true} initialValue={""}/>
            <TextInput source="panNo" label='Pan No' fullWidth={true} initialValue={""}/>
            <TextInput source="tanNo" label='Tan No' fullWidth={true} initialValue={""}/>
            <TextInput source="cinNo" label='Cin No' fullWidth={true} initialValue={""}/>
            <TextInput source="addressLine1" label='Address Line 1' fullWidth={true} initialValue={""} />
                <TextInput source="addressLine2" label='Address Line 2' fullWidth={true} initialValue={""} />
                <TextInput source="addressLine3" label='Address Line 3' fullWidth={true} initialValue={""} />
                <TextInput source="addressLine4" label='Address Line 4' fullWidth={true} initialValue={""} />

            <TextInput source="accountNo" label='Bank Account Number' fullWidth={true} initialValue={""}/> 
            
            <TextInput source="bankName" label='Bank Name' fullWidth={true} initialValue={""}/>
            <TextInput source="branchName" label='Bank Branch Name' fullWidth={true} initialValue={""}/>
            <TextInput source="branchAddress" label='Bank Branch Address' fullWidth={true} initialValue={""}/>
            <NumberInput source="otherFixedCharges" label='Fixed Charges' fullWidth={true} initialValue={0}/>


                <BooleanInput source="isActive" validate={[required()]} label="Active" fullWidth={true} initialValue={true} />


            
            </SimpleForm>
        </Edit>
    );
}
export default FacilityMapEdit;