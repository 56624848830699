import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const ProductUomData = (props) => {
    const classes = useStyles({});
    const [uomData, setUomData] = useState([])


    useEffect(() => {
        debugger
        let _data = [];
        _data = [...props.gridData];
        //let item = _data[props.selectedIndex];
        let aData = _data[props.selectedIndex]["uomData"];
        setUomData([...aData])
    }, []);

    const handleChange = (event, index) => {
        let adata = [...uomData];
        adata[index]["qty"] = event.target.value;
        setUomData(adata)
    }
    const handleSubmit = (formdata) => {


        props.onClose(uomData,'uom');

    }
    return (
        <Create {...props} >
            <SimpleForm
                onSubmit={handleSubmit}

                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <div className="transaction-detail ">
                    Product UOM Detail
                </div>
                {uomData.length > 0 && <div>
                    {uomData.map((item, index) => {
                        return <div style={{ width: "100%" }}>
                            
                            <NumberInput source={"code" + index} label={item.name} fullWidth={true} validate={[required()]} variant="outlined" initialValue={item.qty} onChange={(event) => handleChange(event, index)} />
                            
                        </div>
                    })}

                </div>
                }



            </SimpleForm>
        </Create>
    );
}
export default ProductUomData;