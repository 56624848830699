import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  SelectInput,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import { DisplayDateTime } from "../../common/DisplayDate";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Code" source="code~like" alwaysOn />
        <SearchInput variant="outlined" label="Prefix" source="name~like" alwaysOn />
        <ReferenceInput label="Zone" source="zoneId" reference="zones" >
              <SelectInput optionText="name" />
          </ReferenceInput>
    </Filter>
);
export const RowMasterList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <List {...propsObj} exporter={propsObj.hasExport}  bulkActionButtons={false}  title="Row Master List" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
      {propsObj.hasEdit && <EditButton />}

        <TextField source="code" label="Code" />
        <TextField source="name" label="Prefix" />
        <TextField source="rowNo" label="Row No" />
        <ReferenceField source="dimensionGrpId" reference="dimension-groups" link={false} label="Dimension Group" >
                        <TextField source="name" />
                    </ReferenceField>
        <ReferenceField source="zoneId" reference="zones" link={false} label="Zone" >
                        <TextField source="name" />
                    </ReferenceField>
        <ReferenceField source="facilityId" reference="facilities" link={false} label="Facility" >
                        <TextField source="name" />
                    </ReferenceField>  
      <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date"  locales="fr-FR"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date"  locales="fr-FR"/>
            </Datagrid>      
    </List>
  );
};
