import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const PolicyDepartmentCreate = (props) => {
  
    
  let defaultValues = { 
    isUnderIrda: false,
    isActive: false,
    code:"-",
    isHealthType:false,
    maxTpRewardRate:0,
    maxOdRewardRate:0,
    maxTPBrokRate:0,
    maxOdBrokRate:0,
    policyExpiryDays:0,
    isMotorDetails:false 
  };

  return (
    <Create title="Policy Department Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={defaultValues} >
      
       <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
       <NumberInput  variant="outlined" label="Max TP Reward Rate" source="maxTpRewardRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Max Od Reward Rate" source="maxOdRewardRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Max TP Brok Rate" source="maxTPBrokRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Max OD Brok Rate" source="maxOdBrokRate" fullWidth={true}  />
       <NumberInput  variant="outlined" label="Policy Expiry Days" source="policyExpiryDays" fullWidth={true}  />
       
       <BooleanInput source="isMotorDetails" validate={[required()]}  label="is Motor Details"  />
       <BooleanInput source="isHealthType" validate={[required()]}  label="Is Health Type" />
       <BooleanInput source="isUnderIrda" validate={[required()]}  label="Under IRDA" />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Create>
  );
};
