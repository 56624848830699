import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const FacilityMapCreate = props => {
    const classes = useStyles({});



    const initialValues = {
        clientId: props.id,

    }


    const handleSubmit = (formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        let data = {

            clientId: parseInt(formdata.clientId),
            facilityId: parseInt(formdata.facilityId),
            gstNo: formdata.gstNo,
            panNo: formdata.panNo,
            tanNo: formdata.tanNo,
            cinNo: formdata.cinNo,
            addressLine1: formdata.addressLine1,
            addressLine2: formdata.addressLine2,
            addressLine3: formdata.addressLine3,
            addressLine4: formdata.addressLine4,
            isActive: formdata.isActive,
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(),

        }
        apiClient.addEntiyRecord("client-facility-maps", data).then(res => {

            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Create {...props} >
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">


                <ReferenceInput label="Facility" fullWidth={true}
                    perPage={5000} source="facilityId"
                    filter={{ type: 'AT' }}
                    reference="facilities" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                <TextInput source="gstNo" label='Gst No' fullWidth={true} initialValue={""} />
                <TextInput source="panNo" label='Pan No' fullWidth={true} initialValue={""} />
                <TextInput source="tanNo" label='Tan No' fullWidth={true} initialValue={""} />
                <TextInput source="cinNo" label='Cin No' fullWidth={true} initialValue={""} />
                <TextInput source="addressLine1" label='Address Line 1' fullWidth={true} initialValue={""} />
                <TextInput source="addressLine2" label='Address Line 2' fullWidth={true} initialValue={""} />
                <TextInput source="addressLine3" label='Address Line 3' fullWidth={true} initialValue={""} />
                <TextInput source="addressLine4" label='Address Line 4' fullWidth={true} initialValue={""} />

                <TextInput source="accountNo" label='Bank Account Number' fullWidth={true} initialValue={""} />
                <TextInput source="bankName" label='Bank Name' fullWidth={true} initialValue={""} />
                <TextInput source="branchName" label='Bank Branch Name' fullWidth={true} initialValue={""} />
                <TextInput source="branchAddress" label='Bank Branch Address' fullWidth={true} initialValue={""} />
                <NumberInput source="otherFixedCharges" label='Fixed Charges' fullWidth={true} initialValue={0} />

                <BooleanInput source="isActive" validate={[required()]} label="Active" fullWidth={true} initialValue={true} />





            </SimpleForm>
        </Create>
    );
}
export default FacilityMapCreate;