import React, { useState } from "react";
import { DisplayDateTime } from '../../common/DisplayDate';

import {
  SelectInput,
  List,
  Datagrid,
  TextField, 
  ReferenceField,
  NumberField, 
  EditButton, 
  Filter,
  ReferenceInput,
  SearchInput, 
  SimpleShowLayout ,ChipField
} from "react-admin";  
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Order No "
      source="orderNo~like"
      alwaysOn
    />
    <ReferenceInput label="Status"
      perPage={5000} source="statusId"
      reference="lookups"
      sort={{ field: 'name', order: 'ASC' }}
      filter={{ code: 'PKS' }}
    >
      <SelectInput optionText="name" variant="outlined" alwaysOn />
    </ReferenceInput>
    <ReferenceInput label="Customer"
      perPage={5000} source="customerId"
      reference="customers"
    >
      <SelectInput optionText="name" variant="outlined" />
    </ReferenceInput>

  </Filter>
);
  

const ExpandedPanel = (props) => {

  let propsObj = { ...props };
  propsObj.hasList = false;
  propsObj.hasShow = true;
  propsObj.hasEdit = false;//(p.Edit == 1) ? true : false;
  propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
  propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
  propsObj.hasExport = false;
  propsObj.resource = "orders"  
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={false} filter={{ parentOrderId: props.record.parentOrderId }}  {...propsObj} pagination={false} perPage={100}
      title=""
      exporter={null}
      actions={<React.Fragment />}
      filters={<React.Fragment />}
    > 
      <Datagrid rowClick={false} sortable={false}>

        <ReferenceField source="id" reference="orders" label="Released Order No" link={false}>
          <ChipField source="orderNo"  style={{ backgroundColor: 'black',color:'white' }}/>
        </ReferenceField>
        <ReferenceField source="customerId" reference="customers" label="Customer" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="documentNo" label="Document No  " />
        <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="totalQty" label="Total Qty  " />
        <NumberField source="totalAmount" label="Total Amount" />


        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>

  );
};
export const OrderList = (props) => {

  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = false;//(p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <React.Fragment>
      <List sort={{ field: 'id', order: 'DESC' }}  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Order Packing List" filters={<Filters />}  >
        <Datagrid rowClick={false} expand={<SimpleShowLayout> <ExpandedPanel source="parentOrderId" /> </SimpleShowLayout>} >
          {propsObj.hasEdit && <EditButton />}
          <ChipField source="orderNo" label="Order No  " />
          <ReferenceField source="customerId" reference="customers" label="Customer" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="documentNo" label="Document No  " />
          <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="totalQty" label="Total Qty  " />
          <NumberField source="totalAmount" label="Total Amount" />
          <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
          <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};
