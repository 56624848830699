import React, { useState, useEffect } from "react";
import * as apiClient from "../../common/apiClient";
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";
import { v4 as uuidv4 } from 'uuid';
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles"; 
import { GridDetail } from './GridDetail';
import { Button, TextField } from "@material-ui/core";
import { CustomerField } from "./CustomerField";

export const OrderPackingEdit = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect = useRedirect();
  const [gridData, setGridData] = useState([{key:uuidv4(), lineNumber: 1, productCode: null, productName: null, uomId: 0, price: null, qty: null, totalAmount: null, attributes: [],uomData:[] }]);
  const [headerData, setHeaderData] = useState([]);
  const[isLoading,SetIsLoading]=useState(true)
  const[transactionNo,SettransactionNo]=useState("") ;
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  useEffect(() => {
    setGridData(gridData)
  }); 
  useEffect(() => { 
    loadData()
  }, []);  
  const loadData =  async() => {
    SetIsLoading(true)
    await apiClient.getOrderData(props.id).then(res => { 
      setGridData(JSON.parse(res[0].data)[0].lineItems)
      SettransactionNo(JSON.parse(res[0].data)[0].orderNo)
      setHeaderData(JSON.parse(res[0].data)[0])
      SetIsLoading(false)
    }).catch(err => {
      console.log(err);
      SetIsLoading(false)
    });
    
  }
  const handleChange = (indata) => { 
    console.log(indata,"******Main indata******");

    setGridData(indata);
  }
  const findDuplicateProducts = async() => {
    const productIdCount = {};  
    gridData.forEach((item:any) => {
      const productId = item.productId;
      productIdCount[productId] = (productIdCount[productId] || 0) + 1;
    });  
    const duplicateProductIds = Object.keys(productIdCount).filter(productId => productIdCount[productId] > 1);   
    const duplicateProductNames = gridData.filter((item:any) => duplicateProductIds.includes(item.productId.toString())).map(item => item.productName);  
    return duplicateProductNames; 
  };
  
  const handleApprove = async () => { 
    //orders/approveOrder
    
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    } 
    let data: any = {};
    data.id = parseInt(props.id);
    data.userId=userId;
    apiClient.approveOrder(data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/orders");
      }
      else {
        notify(res[0].remarks, "warning")

      }
    });
  }
 
  const handleSubmit = async (formdata) => {
    
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    } 
    let data: any = {};
    data.id = parseInt(props.id);
    data.facilityId=formdata.facilityId;
    data.orderType=formdata.orderType;
    data.clientId=formdata.clientId;
    data.customerId=formdata.customerId;
    data.documentNo=formdata.documentNo;
    data.orderDate=formdata.orderDate;
    data.saleRepId=formdata.saleRepId;
    data.shipAddress1=formdata.shipAddress1; 
    data.shipAddress2=formdata.shipAddress2; 
    data.shipZipCode=formdata.shipZipCode; 
    data.shipStateId=formdata.shipStateId;   
    data.tpMode = formdata.tpMode;
    data.remarks=formdata.remarks; 
    data.lineItems=[...gridData];
    if(gridData && gridData.length>0)
    {
      let DuplicateProducts= await findDuplicateProducts();
      if(DuplicateProducts && DuplicateProducts.length>0)
      {
       notify("Duplicate Product In Grid "+ DuplicateProducts.join(', '),"warning")
       return;
      }
      
    }
    
    
    // let m = moment(new Date());

    // data. modifyDate=  m.toISOString()
    data.createdBy = userId
    apiClient.addeditOrder(data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/orders");
      }
      else {
        notify(res[0].remarks, "warning")

      }
      //console.log(res);
      //props.onClose();
    }).catch(err => {
      console.log(err);
    });
  }
  return (
    <Edit title=" Order Edit" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} 
      toolbar={!isLoading?
      <FormToolbar onSave={handleSubmit}  {...props} showDelete={false} />:null}
       variant="outlined" >
        <div className="transaction-header">
        Order Detail ::-{transactionNo}
        
          
        
        </div>
        
        <ReferenceInput label="Facility" fullWidth={true} formClassName={classes.one_5_input}
          perPage={5000} source="facilityId"
          reference="facilities" >
          <AutocompleteInput disabled optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
          
        </ReferenceInput>
        <ReferenceInput label="Order Type" fullWidth={true} formClassName={classes.two_5_input}
          perPage={5000} source="orderType"
          filter={{ type: 'OT' }} disabled
          reference="lookups" >
          <AutocompleteInput disabled optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
          
        </ReferenceInput> 
        <ReferenceInput label="Client" fullWidth={true} formClassName={classes.three_5_input}
          perPage={5000} source="clientId"
          reference="clients" >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>
        <FormDataConsumer fullWidth={true} formClassName={classes.four_5_input}>
                        {formDataProps => (
                            <CustomerField  {...formDataProps} />
                        )}
                    </FormDataConsumer>
        <ReferenceInput label="Sale Representative" fullWidth={true} formClassName={classes.last_5_input}
          perPage={5000} source="saleRepId"
          reference="sale-reps" >
          
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>    
        <DateInput source={"orderDate"} label={"Order  Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_5_input} />
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.two_5_input} />
        <ReferenceInput label="Mode" formClassName={classes.three_5_input} 
                    perPage={5000} source="tpMode"
                    filter={{ type: 'TPM' }}
                    validate={[required()]}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput>
        <div>
        </div>
        <TextInput source="shipAddress1" multiline label="Shipping Address 1"  validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.one_5_input}/>
        <TextInput source="shipAddress2" multiline label="Shipping Address 2"  initialValue={""} fullWidth={true} formClassName={classes.two_5_input}/>
        
        <TextInput source="shipZipCode" label="Ship Pincode" validate={[required()]} initialValue={""} fullWidth={true}  formClassName={classes.three_5_input}/>
        <ReferenceInput label="Shipping State" fullWidth={true} formClassName={classes.four_5_input}
          perPage={5000} source="shipStateId"
          sort={{ field: 'name', order: 'ASC' }}
          reference="states" >
          <SelectInput optionText="name" variant="outlined"  validate={[required()]} />
          
        </ReferenceInput>    
        <TextInput source="remarks" multiline label="Remarks" initialValue={""} fullWidth={true} formClassName={classes.last_5_input}/> 

       {/* {!isLoading && isAdmin &&  <div style={{ textAlign: "right", width: "100%", marginTop: 20 }} >
          <Button className="close-me-btn" variant="contained" color="primary" onClick={handleApprove} >
           Approve
          </Button> 
        </div>
      } */}
      {!isLoading && isAdmin &&  <div style={{  width: "100%", marginTop: 20 }} >
<FormDataConsumer formClassName={classes.last_three_input}>
          {({ formData, ...rest }) =>
            formData.statusId &&
            formData.statusId != 157 &&
            <ReferenceInput label="Status"  disabled
            perPage={5000} source="statusId"
            sort={{ field: 'name', order: 'ASC' }}
            reference="lookups" >
            <SelectInput optionText="name" variant="outlined"  validate={[required()]} />
            
          </ReferenceInput> 
          }
        </FormDataConsumer>
        </div>}
{!isLoading && isAdmin &&  <div style={{ textAlign: "right", width: "100%", marginTop: 20 }} >
<FormDataConsumer formClassName={classes.last_three_input}>
          {({ formData, ...rest }) =>
            formData.statusId &&
            formData.statusId === 157 &&
            <Button className="close-me-btn" variant="contained" color="primary" onClick={handleApprove} >
            Approve
           </Button> 
          }
        </FormDataConsumer>
        </div>}

        {!isLoading && <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail loadAddress={true}  headerData={headerData} {...formDataProps} gridData={gridData}  handleChange ={handleChange}/>
              )}
            </FormDataConsumer>
}
             

      </SimpleForm>
    </Edit>
  );
};
