import React, { useState, useEffect, useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import * as apiClient from "../../common/apiClient";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 15,
    },
}));


export const MyCheckbox = ({ value, right, category, name, id, onChange }) => {
    
    let [stateChecked, setStateChecked] = useState(false);
    const handleOnChange = (e) => {
        if (stateChecked) {
            setStateChecked(false);
            onChange(0, right, category, name, id);
        } else {
            onChange(1, right, category, name, id);
            setStateChecked(true);
        }
    }
    useEffect(() => {
        debugger
        if (value == 1)
            setStateChecked(true);
        else
            setStateChecked(false);
    }, [value]);
    return (
        <FormControlLabel
            control={ <Checkbox checked={ stateChecked }
                onChange={ handleOnChange }
                name={ name } /> }
            label={ name }
        />
    )
}

export const RoleTypesEditModule = ({ right, category, rightIndex, flagAllrights, selectUnselectItem }) => {
    
    const [item, setItem] = useState<any>({});
    const classes = useStyles();

    const selectAllItems = (e) => {
        debugger
        let itemsArr = [...item.items];
        let itemObj = { ...item };
        if (e.target.checked) {
            itemsArr.forEach(inner => {
                inner.value = 1;
            })
        } else {
            itemsArr.forEach(inner => {
                inner.value = 0;
            })
        }
        itemObj.items = itemsArr;
        setItem(itemObj);
        flagAllrights(e, itemObj, category);
    }
    const mineselectUnselectItem = (flag, right, category, name, id) => {
        debugger
        let itemsArr = [...item.items];
        let index = itemsArr.findIndex(inner => inner.id == id);
        if (index >= 0) {
            itemsArr[index].value = flag;
        }
        let itemObj = { ...item };
        itemObj.items = itemsArr;
        setItem(itemObj);
        selectUnselectItem(flag, itemObj, category, name);
    }
    const createDisplay = (right) => {
        debugger
        if(right.ModuleRights!=undefined && right.ModuleRights!=null)
        {
            let mrArr = right.ModuleRights.split(",");
            const rightsArray:any[] = [];
            mrArr.forEach(r => {
                let rArr = r.split("|");
                rightsArray.push({
                    "id": rArr[0],
                    "label": rArr[1],
                    "value": rArr[2]
                });
            });
            right.items = rightsArray;
            setItem(right);
        }
    }
    useEffect(() => {
        debugger
        //setItem(right);
        createDisplay(right);
    }, []);
    const allSelected = (item) => {
        debugger
        if(!item.items) return false;
        let notCheked = item.items.findIndex(inner => inner.value == 0)
        if(notCheked >= 0)
            return false;
        return true;    
    }
    return (

        <div className="item-parent">
            <div className="item-header">
                { item && <div className="item-caption">{ item.Module }</div> }
                { item && <div className="item-switch"><Switch className="right-toggle"
                    checked={allSelected(item)}
                    onChange={ (e) => { selectAllItems(e); } }
                    name="checkedA"
                    inputProps={ { 'aria-label': 'secondary checkbox' } }
                /></div> }
            </div>
            { item && <div className="right-option">
                { item.items && item.items.map((inner, index) => {
                    return (
                        <MyCheckbox key={ index } onChange={ mineselectUnselectItem } right={ right } category={ category } name={ inner.label } id={ inner.id } value={ inner.value } />
                    )
                }) }
            </div> }
        </div>
    );
}
