import React,{useState,useCallback} from "react";
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput, useNotify, useRedirect,
  ReferenceInput,
  SelectInput, DateField, AutocompleteInput,
  BoolenField,
  
  NumberField,
  FormDataConsumer, ReferenceField, useRefresh,RichTextField,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import CDeleteButton from "../../common/CDeleteButton";
import { FormToolbar } from "../../common/FormToolbar";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import AttributeCreate from "./AttributeCreate";
import AttributeEdit from "./AttributeEdit";
import { DisplayDateTime } from '../../common/DisplayDate';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));

export const ProductGroupEdit = (props) => {
  const [isAddAttribute, setIsAddAttribute] = useState(false);
  const [isEditAttribute, setIsEditAttribute] = useState(false);
  const myclasses = myStyles({}); 
  const [IsEditRecord, setIsEditRecord] = useState(null);
  const refresh = useRefresh();
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const handleClose = useCallback(() => {
    setIsAddAttribute(false);
    setIsEditAttribute(false);
    
  }, []);
  const onCloseDocumentForm = () => {
    setIsAddAttribute(false);
    setIsEditAttribute(false);
    refresh();
  }

  const onCancel = () => {
    setIsAddAttribute(false);
    setIsEditAttribute(false);

  }
  const CustomEdit = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"
  
      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<EditIcon />}>Edit</Button>
  }
  
  return (
    <React.Fragment>
    <Edit {...propsObj}  title="Product Group Edit">
      
      <TabbedForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <FormTab label="Product Group Details">
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
        </FormTab>
        {/* <FormTab label="Attributes">
            <div>
              <Button onClick={() => { setIsAddAttribute(true); }}>Add Attribute</Button>
            </div>

            

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="product-group-attributes"
              target="productGroupId">

              <Datagrid >

                <CDeleteButton {...props} />
                <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditAttribute(true); }} label="Edit" />


                <ReferenceField source="attributeTypeId" reference="lookups" label="Attribute Type" link={false}>
                  <TextField source="name" />
                </ReferenceField> 
                <TextField source="name"  label="Attribute Name"/>
                <ReferenceField source="attributeGroupId" reference="attribute-groups" label="Attribute Group" link={false}>
                  <TextField source="name" />
                </ReferenceField> 
                <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifyDate" label="Updated Date" locales="fr-FR" />

              </Datagrid>

            </ReferenceManyField>
          </FormTab> */}
      </TabbedForm>
      
    </Edit>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper}}
    open={isEditAttribute || isAddAttribute} >
 {isAddAttribute && <AttributeCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          productGroupId={props.id}
          
          {...props} />}

        {isEditAttribute && <AttributeEdit
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
          
          record={IsEditRecord}
          {...props} />}


  </Drawer>
  </React.Fragment>
  );
};
