import React, { useEffect, useCallback, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const PutawayConfirm = (props) => {
    const classes = useStyles({});
    const [isLoading, SetIsLoading] = useState(true)
    const [gridData, setGridData] = useState([])
    const [headerData, setHeaderData] = useState(null)

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        let payload = { transactionId: parseInt(props.record.id) }
        SetIsLoading(true)
        await apiClient.pendingPutawayData(payload).then(res => {
            debugger
            if (res && res != null) {
                let data = JSON.parse(res[0][""]);
                setHeaderData(data[0]);
                setGridData(data[0].lineItems)
                SetIsLoading(false)
            }

        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });

    }

    
    const changeBin = async (data,index) => {
            let item=gridData[index]
        await apiClient.updatebininputaway(parseInt(item.lineNumber),parseInt(0),parseInt(data),1).then(res => {
            debugger
            if (res && res != null) {
                if(res[0].id>0 )
                {
                    toast.success("Success  : " + res[0].remarks, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                else
                {
                    toast.error("Validation issue : " + res[0].remarks, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            
            
                SetIsLoading(false)
            }

        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });
        
    }
    const handleSubmit = async (formdata) => {

        //pending-putaway-data
        //props.onClose(attributeData,'attribute');

        //good-inward-orders/confirmputaway/{grnId}/{userId}

        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        SetIsLoading(true)
        await apiClient.confirmputaway(parseInt(props.record.id), userId).then(res => {
            debugger
            if (res && res != null) {
                if(res[0].id>0 )
                {
                    toast.success("Success  : " + res[0].Remarks, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    props.onClose();
                }
                else
                {
                    toast.error("Validation issue : " + res[0].Remarks, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                SetIsLoading(false)
            }

        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });

    }
    return (
        <React.Fragment>
            <Create {...props} >
                <SimpleForm
                    onSubmit={handleSubmit}

                    toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                    variant="outlined">
                    {!isLoading && <div style={{ minWidth: "100%" }}>
                        <div className="transaction-detail ">
                            Putaway Confirmation. Putaway Id- {headerData.goodInwardNo}

                        </div>
                        <div style={{minHeight:400,maxHeight:400,overflow:'auto'}}>
                        <Table className={classes.customtable} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: 5 }} ></TableCell>
                                    <TableCell style={{ width: 200 }} >Product Code</TableCell>
                                    <TableCell >Product Name</TableCell>
                                    {/* <TableCell >Attributes</TableCell> */}
                                    <TableCell >UOM</TableCell>
                                    <TableCell >Bin</TableCell>
                                    <TableCell >Good Qty</TableCell>
                                    {/* <TableCell >Action</TableCell> */}


                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {gridData.map((item, index) => {
                                    return <tr >
                                        <TableCell style={{ width: 5 }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell >
                                            {item.productCode}
                                        </TableCell>
                                        <TableCell >
                                            {item.productName}
                                        </TableCell>
                                        {/* <TableCell >

                                            {item.attributes && item.attributes.length > 0
                                                && item.attributes.map((_item, index) => {
                                                    return <div>
                                                        <div >{_item.name} :- </div>
                                                        <div>{" "}{_item.attributeVal}</div>
                                                    </div>

                                                })
                                            }
                                        </TableCell> */}
                                        <TableCell >
                                            {item.uomName}
                                        </TableCell>
                                        <TableCell >
                                            {<ReferenceInput perPage={25} sort={{ field: 'code', order: 'ASC' }}
                                                filterToQuery={searchText => ({ 'code~like': searchText })}
                                                label="" source={uuidv4()} reference="bin-masters" 
                                                validate={[required()]}
                                                initialValue={item.binId}
                                                onChange={(data) => {   changeBin(data,index); }}
                                            >
                                                <AutocompleteInput optionText="code" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                                            </ReferenceInput>
                                            }
                                        </TableCell>
                                        <TableCell >
                                            {item.goodQty}
                                        </TableCell>
                                        {/* <TableCell >
                                            {"Confirm "}
                                        </TableCell> */}


                                    </tr>
                                })}
                            </TableBody>
                        </Table>
                        </div>
                    </div>}
                </SimpleForm>
            </Create>
        </React.Fragment>

    );
}
export default PutawayConfirm;