import React from 'react';
import { FormDataConsumer,Create, SimpleForm, TextInput,BooleanInput,NumberInput, required } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { Addressfields } from "./Addressfields";
export const VendorCreate = props => {
    const initialValue={
        countryId:0,
        regionId:0,
        stateId:0,
        districtId:0,
        cityId:0
    }
    return (
        <React.Fragment>
            <Create label="Create Vendor" {...props}>
                <SimpleForm redirect="list" toolbar={<FormToolbar {...props} />}
                    variant="outlined" initialValues={initialValue}>
                    <TextInput source="code" label='Code' fullWidth={true} validate={[required()]} />
                    <TextInput source="name" label='Name' fullWidth={true} validate={[required()]} />
                    <TextInput source="contactPerson" label='Contact Person ' fullWidth={true} validate={[required()]} />
                    <TextInput source="contactNumber" label='Contact Number ' fullWidth={true} validate={[required()]} />
                    <TextInput source="address1" label='Address 1 ' fullWidth={true} initialValue={""} />
                    <TextInput source="address2" label='Address 2' fullWidth={true} initialValue={""} />
                    <TextInput source="zipCode" label='Pincode' fullWidth={true} initialValue={""} />
                    <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <Addressfields {...formDataProps} />
              )}
            </FormDataConsumer>
                    
                    <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} />
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}