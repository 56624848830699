import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const DimensionGroupEdit = (props) => {
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  
  return (
    <Edit {...propsObj}  title="Dimension Group Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
       <NumberInput  variant="outlined" source="width"   placeholder={'Enter Number '} fullWidth={true}  validate={[required()]} />
       <NumberInput  variant="outlined" source="height"   placeholder={'Enter Number '} fullWidth={true}  validate={[required()]} />
       <NumberInput  variant="outlined" source="length"   placeholder={'Enter Number '} fullWidth={true}  validate={[required()]} />
       <NumberInput  variant="outlined" source="weight"   placeholder={'Enter Number (in kgs) '} fullWidth={true}  validate={[required()]} />
       <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Edit>
  );
};
