import React from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  SimpleForm,
  NumberInput
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import { useStyles } from "../../formStyles";
export const RowMasterEdit = (props) => {
  const classes = useStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
   return (
    <Edit {...propsObj}  title="Row Master Edit">
      <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...propsObj} />}>
      <ReferenceInput variant="outlined" label="Dimension Groups" source="dimensionGrpId" reference="dimension-groups" validate={[required()]}
                fullWidth={true} perPage={500} >
                <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
            
      <ReferenceInput label="Facility" fullWidth={true} source="facilityId" reference="facilities"  
            >
              <SelectInput optionText="name" disabled={true} />
          </ReferenceInput>
          <ReferenceInput label="Zone" source="zoneId" fullWidth={true} reference="zones"  
            >
              <SelectInput optionText="name" disabled={true} />
          </ReferenceInput>

            <TextInput source="code" label="Code" fullWidth={true} disabled={true}/>
            <TextInput source="name" label="Prefix"  fullWidth={true}disabled={true}/>
            <NumberInput source="rowNo" label="Row Number "  fullWidth={true}    disabled={true}/> 
            <BooleanInput source="isActive" label="Active" fullWidth={true}/>
            </SimpleForm>
    </Edit>
  );
};
