import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  SearchInput
} from "react-admin";
import queryString from 'query-string';


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Hospital Name" source="hospitalName~like" alwaysOn  shouldRenderSuggestions={(val) => { return val && val.trim().length   >= 1 }} /> 
    <ReferenceInput label="Executive" source="employeeId" reference="employees"  alwaysOn
            sort={{ field: 'name', order: 'ASC' }} >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
        
    </Filter>
);
export const DsrList = (props) => {
  let propsObj = { ...props };
  let  loggedInuserType:any;
  loggedInuserType = localStorage.getItem("RoleTypeId");
  var filter = {};
  if(parseInt(loggedInuserType)!=1 && parseInt(loggedInuserType)!=6)
  {
    let user:any;
     user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    filter={createdBy:user}
  }


  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}  title="DSR List" filters={<Filters />}  filter={{...filter}}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        <ReferenceField label="Hosiptal  " source="existingHospitalId" reference="hospitals" fullWidth={true} link={false} >
              <TextField source="name" />
        </ReferenceField>
            
        <TextField source="hospitalName" label="Hospital Name " />
        <TextField source="hospitalAddress" label="Hospital Address" />
        <TextField source="contactPersonName" label="Contact Person" />
        <TextField source="contactNo" label="Contact No" />
        <TextField source="emailId" label="Email" />
        <ReferenceField label="Execitive  " source="employeeId" reference="employees" fullWidth={true} link={false} >
              <TextField source="name" />
        </ReferenceField>
        <TextField source="discussionDetails" label="Details" />
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date" locales="fr-FR" />
        
        
        
        
        
      </Datagrid>
    </List>
  );
};
