// Install necessary packages
// npm install @react-pdf-viewer
// npm install @react-pdf/renderer

// Create a component for the PDF invoice
import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet,Font } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../common/apiClient";
import font from'../../font/TimeRomanBold.ttf'
Font.register({
  family: 'Times-Roman-Bold',
  src: {font}
});
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
    border: 1,
    fontFamily: 'Times-Roman',
    backgroundColor: '#ffffff', // White background for high contrast
    fontWeight: ''
    
  },
  pageContainer: {
    border: 0.7,
    width: "99%",
    height: "99%",

  },
  clientContainer: {
    minheight: 80,
    borderBottom: 0.7,
  },
  clientContainerRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  clientContainerRowGstNo: {
    width: '50%',
    textAlign: 'left',
    fontSize: 10,
    fontWeight: 'heavy',
    padding: 2

  },
  clientContainerRowCopy: {
    width: '50%',
    textAlign: 'right',
    fontSize: 9,
    padding: 2
  },
  clientContainerReportHeading: {
    width: '100%',
    textAlign: 'center',
    fontSize: 12,
    textDecoration: 'underline'

  },
  clientContainerName: {
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'heavy'


  },
  clientContainerAddress: {
    width: '100%',
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'heavy'

  },
  invoiceContainer: {
    minheight: 80,
    borderBottom: 0.7,
    fontSize: 9,
    display: 'flex',
    flexDirection: 'row',
  },
  invoiceContainer1: {
    width: '50%',

    borderRight: 0.7
  },
  invoiceContainer2: {
    width: '50%',
  },
  invoiceContainerField: {
    padding: 2,
    paddingLeft: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    fontWeight: 'heavy',
  },
  invoiceContainerLabel: {
    width: '40%',
  },
  invoiceContainerSeparator: {
    width: '20%',
  },
  invoiceContainerValue: {
    width: '40%',
  },
  invoiceContainerBillingInfo: {
    padding: 2,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceContainerBillingInfoHeading: {
    width: '100%',
    fontSize: 12,
    fontWeight: 'heavy',
    paddingLeft: 5
    
  },
  invoiceContainerBillingInfoAddress: {
    width: '100%',
    
    textAlign: 'left',
    padding: 5,
    fontSize: 11,
    fontWeight: 'heavy',
  },
  invoiceLineItemHeaderContainer: {
    width: '100%',
    borderBottom: 0.7,

    minHeight: 20,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 11,
    fontWeight: 'heavy',
  },

  invoiceLineItemDetailContainer: {
    width: '100%',
    minHeight: 20,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 11,
    fontWeight: 'heavy',
  },
  invoiceLineItemSNO: {
    width: '5%',
    borderRight: 0.7,
    minHeight: 10,
    padding: 2,
  },
  invoiceLineItemProduct: {
    width: "43%",
    borderRight: 0.7,
    minHeight: 10,
    padding: 2,
  },

  invoiceLineItemHSN: {
    width: "15%",
    borderRight: 0.7,
    minHeight: 10,
    padding: 2
  },
  invoiceLineItemQty: {
    width: "10%",
    borderRight: 0.7,
    minHeight: 10,
    textAlign: 'right',
    padding: 2,
  },
  invoiceLineItemUnit: {
    width: "5%",
    borderRight: 0.7,
    minHeight: 10,
    padding: 2,
  },

  invoiceLineItemPrice: {
    width: "10%",
    borderRight: 0.7,
    minHeight: 10,
    padding: 2,
    textAlign: 'right'
  },
  invoiceLineItemDiscount: {
    width: "8%",
    borderRight: 0.7,
    minHeight: 10,
    padding: 2,
    textAlign: 'right',
    paddingRight: 2

  },


  invoiceLineItemAmount: {
    width: "12%",
    borderRight: 0.7,
    minHeight: 10,
    padding: 2,
    textAlign: 'right',
    paddingRight: 2
  },
  invoiceAmountInWords:{
    
    fontSize: 10,  
    textAlign:'left'
  },
  invoiceTaxContainer: {
    width: '100%',
    minHeight: 30,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    borderTop: 0.7,
    borderBottom: 0.7,
  },
  invoiceTaxLabel: {
    width: 70,
    textAlign:'center',
    fontSize: 10,
    
    borderBottom: 0.7,
  },
  invoiceTaxLabelValue: {
    width: 70,
    fontSize: 10,  
    textAlign:'center'
  },
  invoiceTotalContainer: {
    width: '100%',
    minHeight: 20,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    borderTop: 0.7,
    borderBottom: 0.7,
    fontSize: 11,
    fontWeight: 'heavy',
  },
  invoiceTotalQty: {
    width: '88%',
    minHeight: 20,
    textAlign: 'right',
    fontSize: 11,
    padding: 10,
    
    fontWeight: 'heavy',

  },
  invoiceTotalAmount: {
    width: '12%',
    minHeight: 20,
    borderLeft: 0.7,

    textAlign: 'right',
    fontSize: 11,
    fontWeight: 'heavy',
    padding: 10,
    paddingRight: 2,
  },
  invoiceBankContainer: {
    minheight: 20,
    borderBottom: 0.7,
    textAlign: 'center'
  },
  invoiceBank: {
    fontSize: 14,
    fontWeight: 'heavy',
  },
  invoiceBankDetail: {
    fontSize: 9,

  },
  invoiceBankAddress: {
    fontSize: 9,

  },
  invoiceTermAndConditionContainer: {
    minheight: 80,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 0.7,
  },
  invoiceTermAndCondition: {
    width: '50%',
    borderRight: 0.7,
    fontSize: 8,
    padding: 10

  },
  invoiceSignatureBox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceSignatureReciver: {
    borderBottom: 0.7,
    height: 30,
    textAlign: 'left',
    fontSize: 13,
    padding: 5,
    fontWeight: 'extrabold',
  },
  invoiceSignature: {
    textAlign: 'right',
    fontSize: '14',
    fontWeight: 'extrabold',
    paddingTop: 30
  }


});
const getTotalQty = async (item) => {

}
const Invoice = ({ data }) => {
  return (
    <Document>
      {data&& data.map((item, index) => {
        return <Page size="A4" style={styles.page}>
          <View style={styles.pageContainer}>
            <View style={styles.clientContainer} >
              <View style={styles.clientContainerRow} >
                <View style={styles.clientContainerRowGstNo} >
                  <Text>GSTIN : {item.facilityAddress[0].gstNo}</Text>
                </View>
                <View style={styles.clientContainerRowCopy} >
                  <Text>Original Copy</Text>
                </View>
              </View>
              <View style={styles.clientContainerReportHeading} >
                <Text>Purchase Order</Text>
              </View>
              <View style={styles.clientContainerName} >
                <Text>{item.facilityAddress[0].clientName}</Text>
              </View>
              <View style={styles.clientContainerAddress} >
                <Text>{item.facilityAddress[0].addressLine1} </Text>
                <Text>{item.facilityAddress[0].addressLine2} </Text>
                <Text>{item.facilityAddress[0].addressLine3} </Text>
                <Text>{item.facilityAddress[0].addressLine4} </Text>
              </View>

            </View>
            <View style={styles.invoiceContainer}>
              <View style={styles.invoiceContainer1}>
                {item.leftContent&& item.leftContent.map((leftContent, index) => {
                  return <View style={styles.invoiceContainerField}>
                    <View style={styles.invoiceContainerLabel}>
                      <Text>{leftContent.iKey}</Text>
                    </View>
                    <View style={styles.invoiceContainerSeparator}>
                      <Text>:</Text>
                    </View>
                    <View style={styles.invoiceContainerValue}>
                      <Text>{leftContent.iValue}</Text>
                    </View>
                  </View>
                })}  
              </View>

              <View style={styles.invoiceContainer1}>

                {item.rightContent&& item.rightContent.map((rightContent, index) => {
                  return <View style={styles.invoiceContainerField}>
                    <View style={styles.invoiceContainerLabel}>
                      <Text>{rightContent.iKey}</Text>
                    </View>
                    <View style={styles.invoiceContainerSeparator}>
                      <Text>:</Text>
                    </View>
                    <View style={styles.invoiceContainerValue}>
                      <Text>{rightContent.iValue}</Text>
                    </View>
                  </View>
                })}

              </View>


            </View>
           
            <View style={styles.invoiceLineItemHeaderContainer}>
              <View style={styles.invoiceLineItemSNO}>
                <Text>S.N.</Text>
              </View>
              <View style={styles.invoiceLineItemProduct}>
                <Text>Description</Text>
              </View>
              <View style={styles.invoiceLineItemHSN}>
                <Text>Product Code</Text>
              </View>
              <View style={styles.invoiceLineItemQty}>
                <Text>Qty.</Text>
              </View>

              <View style={styles.invoiceLineItemUnit}>
                <Text>Unit</Text>
              </View>
              {/* <View style={styles.invoiceLineItemPrice}>
                <Text>List Price</Text>
              </View> */}
              {/* <View style={styles.invoiceLineItemDiscount}>
                <Text>Discount</Text>
              </View> */}
              <View style={styles.invoiceLineItemPrice}>
                <Text>Price</Text>
              </View>
              <View style={styles.invoiceLineItemAmount}>
                <Text>Amount</Text>
              </View>
            </View>
            {item.lineItems  && item.lineItems.map((lineItem, index) => {
              return <View style={styles.invoiceLineItemDetailContainer}>
                <View style={styles.invoiceLineItemSNO}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={styles.invoiceLineItemProduct}>
                
                  <Text>{lineItem.productName}</Text>
                  
                  
                </View>
                <View style={styles.invoiceLineItemHSN}>
                  
                  <Text>{lineItem.productCode}</Text>
                </View>
                <View style={styles.invoiceLineItemQty}>
                  <Text>{lineItem.qty}</Text>
                </View>

                <View style={styles.invoiceLineItemUnit}>
                  <Text>{lineItem.unit}</Text>
                </View>
                {/* <View style={styles.invoiceLineItemPrice}>
                  
                  <Text>{lineItem.listPrice?lineItem.listPrice.toFixed(2):'0.00'}</Text>
                </View> */}
                {/* <View style={styles.invoiceLineItemDiscount}>
                  
                  <Text>{lineItem.discount}</Text>
                </View> */}
                <View style={styles.invoiceLineItemPrice}>
                  
                  <Text>{lineItem.price?lineItem.price.toFixed(2):'0.00'}</Text>
                </View>
                <View style={styles.invoiceLineItemAmount}>
                  <Text>{lineItem.amount?lineItem.amount.toFixed(2):'0.00'}</Text>
                </View>
              </View>

            })}
            {/* "summary": [
          {
            "uniqueID": 1,
            "totalQty": 305,
            "totalAmount": 45860,
            "freightCharge": 0,
            "totalInvoiceAmount": 45860,
            "maxPageNo": 1
          }
        ] */}
            {item.summary && item.summary.length > 0 && <View style={styles.invoiceTotalContainer}>
              <View style={styles.invoiceTotalQty}>
                <Text>Total Qty :- {item.summary[0].totalQty}</Text>
              </View>
              <View style={styles.invoiceTotalAmount}>
                <Text>{item.summary[0].totalAmount?item.summary[0].totalAmount.toFixed(2):'0.00'}</Text>
                
              </View>
            </View>}

            {/* {item.taxDetail && item.taxDetail.length > 0 && <View style={styles.invoiceTotalContainer}>
              <View style={styles.invoiceTotalQty}>
                <Text>CGST Amt </Text>
              </View>
              <View style={styles.invoiceTotalAmount}>
              <Text>{item.taxDetail['CGST Amt']?item.taxDetail['CGST Amt'].toFixed(2):'0.00'}</Text>
              
              </View>
            </View>} */}
            {item.taxSlabDetail && item.taxSlabDetail.map((_item, index) => {
                  return <View style={styles.invoiceTotalContainer}>
                  <View style={styles.invoiceTotalQty}>
                  
                    <Text>{_item['Tax Rate']} </Text>
                  </View>
                  <View style={styles.invoiceTotalAmount}>
                  <Text>{_item['Amt']?parseFloat(_item['Amt']).toFixed(2):'0.00'}</Text>
                  
                  
                  </View>
                </View>
                })}

            {item.summary && item.summary.length > 0 && <View style={styles.invoiceTotalContainer}>
              <View style={styles.invoiceTotalQty}>
                <Text>Grand Total </Text>
              </View>
              <View style={styles.invoiceTotalAmount}>
                
                <Text>{item.summary[0].totalInvoiceAmount?item.summary[0].totalInvoiceAmount.toFixed(2):'0.00'}</Text>
              </View>
            </View>}
            {item.summary && item.summary.length > 0 && <View style={styles.invoiceTaxContainer}>
              
              <View >
                <View style={styles.invoiceTaxLabel} >
                  <Text>Tax Rate</Text>
                </View>
                <View style={styles.invoiceTaxLabelValue} >
                  <Text>{item.taxDetail && item.taxDetail.length>0 ?item.taxDetail[0]['Tax Rate']:''  }</Text>
                </View> 
              </View>

              

              <View>
              <View style={styles.invoiceTaxLabel} >
                  <Text>Taxable Amount</Text>
                </View>
                <View style={styles.invoiceTaxLabelValue} >
                
                <Text>{item.summary[0].totalAmount?item.summary[0].totalAmount.toFixed(2):'0.00'}</Text>
                </View> 
              </View>


              <View>
              <View style={styles.invoiceTaxLabel} >
                  <Text>IGST Amount</Text>
                </View>
                <View style={styles.invoiceTaxLabelValue} >
                <Text>{item.taxDetail && item.taxDetail.length>0 ?parseFloat(item.taxDetail[0]['IGST Amt']).toFixed(2):''  }</Text>
                  
                </View> 
              </View>

              <View>
              <View style={styles.invoiceTaxLabel} >
                  <Text>CGST Amount</Text>
                </View>
                <View style={styles.invoiceTaxLabelValue} >
                <Text>{item.taxDetail && item.taxDetail.length>0 ?parseFloat(item.taxDetail[0]['CGST Amt']).toFixed(2):''  }</Text>
                
                </View> 
              </View>

              

              
              <View>
              <View style={styles.invoiceTaxLabel} >
                  <Text>Total Tax </Text>
                </View>
                <View style={styles.invoiceTaxLabelValue} >
                <Text>{item.taxDetail && item.taxDetail.length>0 ?parseFloat(item.taxDetail[0]['Total Tax']).toFixed(2):""}</Text>
                  
                </View> 
              </View>
            </View>}
            {item.summary && item.summary.length > 0 && <View style={styles.invoiceTaxContainer}>
              
              <View >
                <View style={styles.invoiceAmountInWords} >
                  <Text>{item.summary[0].AmountInWords}</Text>
                </View>
                
              </View>  
            </View>}
            {/* <View style={styles.invoiceBankContainer}>
              <View style={styles.invoiceBank}>
                <Text>Bank Details</Text>
              </View>
              <View style={styles.invoiceBankDetail}>
                <Text>{item.bankDetail[0].accountNo}</Text>
              </View>
              <View style={styles.invoiceBankAddress}>
                <Text>{item.bankDetail[0].bankName} {item.bankDetail[0].branchName} {item.bankDetail[0].branchAddress}</Text>
              </View>
            </View> */}


            <View style={styles.invoiceTermAndConditionContainer}>
              <View style={styles.invoiceTermAndCondition}>
                {item.termsAndCondition && item.termsAndCondition.map((termNCondition, index) => {
                  return <Text>{termNCondition.iValue}</Text>
                })}


              </View>
              <View style={styles.invoiceSignatureBox}>
                <View style={styles.invoiceSignatureReciver}>
                  <Text>Receiver's Signature :</Text>
                </View>
                <View style={styles.invoiceSignature}>
                  <Text>{item.signature[0].iValue}</Text>
                  <Text>Authorised Signatory</Text>
                </View>
              </View>
            </View>




          </View>
        </Page>
      })}


    </Document>
  );
};
const InvoicePrint = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    LoadData(props.record.id)
  }, []);
  const LoadData = async (id) => {

    await apiClient.globalPrint(id, 'purchaseorder').then(res => {

      
      let data = res;
      setFinalData(JSON.parse(data[0].data));
      setLoading(false);

    }).catch(err => {
      setLoading(false);
      console.log(err);
    });

  }


  return (
    <>
      <div>
        <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
      </div>
      {!isLoading && <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <Invoice data={finalData} />

      </PDFViewer>
      }
    </>

  );
};

export default InvoicePrint;
