import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { Button } from '@material-ui/core';

export const CopyAddress = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div > 
            <Button  className="close-me-btn" variant="contained" color="primary"
            onClick={(v) => { 
                

                form.change("shipAddress1", formData.billAddress1);
                form.change("shipAddress2", formData.billAddress2);
                form.change("shipZipCode", formData.billZipCode);
                form.change("shipCountryId", formData.billCountryId);
                form.change("shipRegionId", formData.billRegionId);
                form.change("shipStateId", formData.billStateId);
                form.change("shipDistrictId", formData.billDistrictId); 
                form.change("shipCityId", formData.billCityId);
                form.change("emailId", formData.billEmailId);
            }}
            > Same As Billing Address</Button>  
        </div>
    )
}
