import React, { useState, useCallback } from 'react';
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const Otherfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <React.Fragment>
            
            <BooleanInput source="isMasterUom" validate={[required()]} label="Is Master UOM"  initialValue={true}
             className={classes.first_inline_input}
            onChange={(v) => {   
                if (v===false)           
                {
                    form.change("baseUomId", null);
                }
                else
                {
                    form.change("baseUomId", 0);
                }
                
                
            }}
            />
           

            <FormDataConsumer   className={classes.first_last_input}>
          {({ formData, ...rest }) =>
            

            formData.isMasterUom === false &&
             (
            <ReferenceInput variant="outlined" label="Base Uom" source="baseUomId" reference="uoms" validate={[required()]}
                className={classes.first_last_input}  perPage={500} >
                <SelectInput optionText="name" validate={[required()]}  className={classes.first_last_input}  />
            </ReferenceInput>
            )
          }
        </FormDataConsumer>
            
            
            </React.Fragment>
    )
}
