import React, { useEffect, useCallback, useState,useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify';

import Pdf from "react-to-pdf";

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const PutawayPrint = (props) => {
    const classes = useStyles({});
    const [isLoading, SetIsLoading] = useState(true)
    const [gridData, setGridData] = useState([])
    const [headerData, setHeaderData] = useState(null)
    
    const ref = useRef() ;

    useEffect(() => {
        loadData();
    }, []);

    const options = {
        orientation: 'landscape',
        unit: 'in',
      //  format: [4,2]
    };

    const loadData = async () => {
        let payload = { transactionId: parseInt(props.record.id) }
        SetIsLoading(true)
        await apiClient.pendingPutawayData(payload).then(res => {
            debugger
            if (res && res != null) {
                let data = JSON.parse(res[0][""]);
                setHeaderData(data[0]);
                setGridData(data[0].lineItems)
                SetIsLoading(false)
            }

        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });

    }

    
    const changeBin = async (data,index) => {
            let item=gridData[index]
        await apiClient.updatebininputaway(parseInt(item.lineNumber),parseInt(0),parseInt(data),1).then(res => {
            debugger
            if (res && res != null) {
                if(res[0].id>0 )
                {
                    toast.success("Success  : " + res[0].remarks, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                else
                {
                    toast.error("Validation issue : " + res[0].remarks, {
                        position: "bottom-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            
            
                SetIsLoading(false)
            }

        }).catch(err => {
            console.log(err);
            SetIsLoading(false)
        });
        
    }
    const handleSubmit = async (formdata) => {

        //pending-putaway-data
        //props.onClose(attributeData,'attribute');

        //good-inward-orders/confirmputaway/{grnId}/{userId}
        
        console.log(ref)

        // var originalContents = document.body.innerHTML;
        // document.body.innerHTML = ref.current.innerHTML;
        // window.print();
        // document.body.innerHTML = originalContents;

        // let userId;
        // userId = localStorage.getItem("userId");
        // if (userId) {
        //     userId = parseInt(userId);
        // }
        // SetIsLoading(true)
        // await apiClient.confirmputaway(parseInt(props.record.id), userId).then(res => {
        //     debugger
        //     if (res && res != null) {
        //         if(res[0].id>0 )
        //         {
        //             toast.success("Success  : " + res[0].Remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //             props.onClose();
        //         }
        //         else
        //         {
        //             toast.error("Validation issue : " + res[0].Remarks, {
        //                 position: "bottom-center",
        //                 autoClose: 6000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //             });
        //         }
        //         SetIsLoading(false)
        //     }

        // }).catch(err => {
        //     console.log(err);
        //     SetIsLoading(false)
        // });

    }
    return (
        <React.Fragment>
            <Create {...props} >
                <SimpleForm
                    onSubmit={handleSubmit}

                    // toolbar={<PopupCreateToolbar label="Print" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                    toolbar={null}
                    variant="outlined">
                    {!isLoading && <div ref={ref} style={{paddingLeft:"1%",minWidth: "99%",maxWidth: "99%" }} >
                    <div style={{  minWidth: "100%",maxWidth: "100%" }} >
                        <div className="transaction-detail ">
                            Putaway Print. Putaway Id- {headerData.goodInwardNo}

                        </div>
                        <div style={{maxHeight:400,overflow:'scroll'}} >
                        <Table className={classes.customtable} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: 5 }} ></TableCell>
                                    <TableCell style={{ width: 200 }} >Product Code</TableCell>
                                    <TableCell >Product Name</TableCell>
                                    <TableCell >Attributes</TableCell>
                                    <TableCell >UOM</TableCell>
                                    <TableCell >Bin</TableCell>
                                    <TableCell >Good Qty</TableCell>
                                    <TableCell >Damage Qty</TableCell>
                                    {/* <TableCell >Action</TableCell> */}


                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {gridData.map((item, index) => {
                                    return <tr >
                                        <TableCell style={{ width: 5 }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell >
                                            {item.productCode}
                                        </TableCell>
                                        <TableCell >
                                            {item.productName}
                                        </TableCell>
                                        <TableCell >

                                            {item.attributes && item.attributes.length > 0
                                                && item.attributes.map((_item, index) => {
                                                    return <div>
                                                        <div >{_item.name} :- </div>
                                                        <div>{" "}{_item.attributeVal}</div>
                                                    </div>

                                                })
                                            }
                                        </TableCell>
                                        <TableCell >
                                            {item.uomName}
                                        </TableCell>
                                        <TableCell >
                                            {item.binName}
                                            {item.binName == "" && <ReferenceInput perPage={25} sort={{ field: 'code', order: 'ASC' }}
                                                filterToQuery={searchText => ({ 'code~like': searchText })}
                                                label="" source="binId" reference="bin-masters" 
                                                validate={[required()]}
                                                disabled
                                                onChange={(data) => {   changeBin(data,index); }}
                                            >
                                                <AutocompleteInput optionText="code" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                                            </ReferenceInput>
                                            }
                                        </TableCell>
                                        <TableCell >
                                            {item.goodQty}
                                        </TableCell>
                                        <TableCell >
                                            {item.damageQty}
                                        </TableCell>
                                        {/* <TableCell >
                                            {"Confirm "}
                                        </TableCell> */}


                                    </tr>
                                })}
                            </TableBody>
                        </Table>
                        </div>
                    </div>
                    </div>}
                    <Pdf targetRef={ref} filename="print.pdf" options={options}  scale={0.8}>
        {({ toPdf }) => 
        
        <div>
            <Button
                style={{ marginLeft: 10 }}
                variant="contained" color="primary"
                size="medium"
                label="Download PDF"
                startIcon={<PrintIcon />}
                onClick={toPdf}
            />
            <Button
                style={{ marginLeft: 10 }}
                variant="contained" color="primary"
                size="medium"
                label="Cancel"
                startIcon={<CancelIcon />}
                onClick={()=>props.onClose()}
            />
            
            
        </div>
        
        
        }
      </Pdf>
                </SimpleForm>
            </Create>
        </React.Fragment>

    );
}
export default PutawayPrint;