import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const StateDistrict = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <>
            <ReferenceInput variant="outlined" label="State" source="stateId" reference="states"
                onChange={(v) => {                    
                    form.change("districtId", 0);
                }}
                validate={required()}
                fullWidth={true} perPage={500} >
                <SelectInput optionText="name" />
            </ReferenceInput>            
            <ReferenceInput variant="outlined" label="District" source="districtId"
                reference="districts" perPage={500}
                filter={{ "stateId": formData.stateId }}
                validate={required()}
                fullWidth={true} >
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>
        </>
    )
}
