import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const Addressfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div >
            
            <ReferenceInput variant="outlined" label="Country" source="countryId" reference="countries"
                onChange={(v) => {                     
                    form.change("regionId", 0);
                    form.change("stateId", 0);
                    form.change("districtId", 0);
                    form.change("cityId", 0);
                }}
               
                fullWidth={true} perPage={500} className={classes.one_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Region" source="regionId" reference="regions"
                onChange={(v) => {                    
                    form.change("stateId", 0);
                    form.change("districtId", 0);
                    form.change("cityId", 0);
                    
                }}
                filter={{ "countryId": formData.countryId }}
               
                fullWidth={true} perPage={500} className={classes.two_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="State" source="stateId"
                reference="states" perPage={500}
                filter={{ "regionId": formData.regionId }}
                
                onChange={(v) => {
                    form.change("districtId", 0);
                    form.change("cityId", 0);
                }}
                fullWidth={true} className={classes.last_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            
            <ReferenceInput variant="outlined" label="District" source="districtId"
                reference="districts" perPage={500}
                filter={{ "stateId": formData.stateId }}
               
                onChange={(v) => {
                    form.change("cityId", 0);
                }}
                fullWidth={true} className={classes.one_three_input}>
                <SelectInput  optionText="name" />
            </ReferenceInput>

            
             <ReferenceInput variant="outlined" label="City" source="cityId"
                reference="cities" perPage={500}
                filter={{ "districtId": formData.districtId }}
               
                
                fullWidth={true} className={classes.two_three_input}>
                <SelectInput  optionText="name" />
            </ReferenceInput> 

        </div>
    )
}
