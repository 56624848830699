import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const DisCreate = props => {
    const classes = useStyles({});
    

    
    const initialValues = {
        productId: props.productId,

    }
 

    const handleSubmit = (formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }
        let data = {
            productId: parseInt(formdata.productId),
            clientId: parseInt(formdata.clientId),   
            customerId: parseInt(formdata.customerId),   
            discount: parseFloat(formdata.discount),   
            isActive: formdata.isActive,
            createdBy: userId,
            modifyBy: 0,
            createdDate: m.toISOString(),
            modifyDate: m.toISOString(),

        }
        apiClient.addEntiyRecord("customer-item-wise-discounts", data).then(res => {
            
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Create {...props} >
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">


                <ReferenceInput label="Client" fullWidth={true}
                    perPage={5000} source="clientId"
                    filter={{ type: 'AT' }}
                    reference="clients" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                <ReferenceInput label="Customer" fullWidth={true}
                    perPage={5000} source="customerId"
                    filter={{ type: 'AT' }}
                    reference="customers" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                
                <NumberInput source="discount" validate={[required()]} label="Discount" fullWidth={true} initialValue={0} /> 

                <BooleanInput source="isActive" validate={[required()]} label="Active" fullWidth={true} initialValue={true} />





            </SimpleForm>
        </Create>
    );
}
export default DisCreate;