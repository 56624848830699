import React from "react";
import { CreateToolbar } from "../../CreateToolbar";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  SimpleForm,
  TabbedForm,
  useRedirect,
  useNotify,
  NumberInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import moment from "moment";
import { Otherfields } from "./Otherfields";
import * as apiClient from "../../common/apiClient";

export const BinMasterCreate = (props) => {
  const classes = useStyles({});
  const redirect=useRedirect();
  const notify = useNotify();
  const initialValue={
    facilityId:null,
    zoneId:0,
    name:""
    
}
  const handleSubmit = async (formdata) => {
    
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();
    if (data.rangeType=='number')
    {
      if(parseInt(data.fromBin)<=0)
      {
        notify("Please enter valid input in Start Bin fields ", "warning") 
        return;
      }
      if(parseInt(data.toBin)<=0)
      {
        notify("Please enter valid input in To Bin fields ", "warning") 
        return;
      }
      if(parseInt(data.fromBin)>parseInt(data.toBin))
      {
        notify("Please enter valid input in Start and To Bin fields ", "warning") 
        return;
      }
  
     
    }
    
    apiClient.createbinmaster(data).then(res => {
      if(res[0].result)
      {
        notify("Bins are added succesffully");
        redirect("/bin-masters");
      }
      else
      {
        notify(res[0].message, "warning")   
      }
      
      

    }).catch(err => {
     notify("unable to add ", "warning")
      //console.log(err);
    });
  }

  return (
    <Create title="Bin Master Create" {...props}>
      <SimpleForm variant="outlined" redirect="list" onSubmit={handleSubmit}toolbar={<CreateToolbar onSave={handleSubmit}  {...props} />} initialValues={initialValue}>
      
        <FormDataConsumer>
          {formDataProps => (
            <Otherfields {...formDataProps} />
          )}
        </FormDataConsumer>
        <TextInput source="prefix" label="Prefix" fullWidth={true} />       
        <SelectInput variant="outlined" source="rangeType" label="Range Type" choices={[{ id: 'number', name: 'number' },
        { id: 'alpha', name: 'alpha' }]}   initialValue="number"  fullWidth={true}/>
         
         <FormDataConsumer >
          {({ formData, ...rest }) =>
            
            formData.rangeType === 'number' &&
             (
              <NumberInput inputProps={{ maxLength: 2 }} variant="outlined" source="fromBin" label="Start Bin Number "  placeholder={'Enter Number '} fullWidth={true}  validate={[required()]} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            
            formData.rangeType === 'number' &&
             (
              <NumberInput inputProps={{ maxLength: 2 }}  variant="outlined" source="toBin" label="To Bin Number "  placeholder={'Enter Number '} fullWidth={true}validate={[required()]} />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer >
          {({ formData, ...rest }) =>
            
            formData.rangeType === 'alpha' &&
             (
              <TextInput  inputProps={{ maxLength: 1 }} variant="outlined" source="fromBin" label="Start Bin  " placeholder={'Enter Alpha '}  fullWidth={true}  validate={[required()]} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            
            formData.rangeType === 'alpha' &&
             (
              <TextInput   inputProps={{ maxLength: 1 }} variant="outlined" source="toBin" label="To Bin  " placeholder={'Enter Alpha '}  fullWidth={true}validate={[required()]} />
            )
          }
        </FormDataConsumer>
        
        
        

      </SimpleForm>

    </Create>
  );
};
