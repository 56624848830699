import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import CountBlock from "./CountBlock";
import HolidayList from "./HolidayList";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {

    useNotify
} from "react-admin";

const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center", flexWrap: "wrap" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});
const Dashboard = () => {
    const [db, setdb] = useState([]);
    const [fetchingAttedanceStatus, setFetchingAttedanceStatus] = useState(true);
    const [attType, setAttType] = useState("");
    const [workLocation, setWorkLocation] = useState("");
    const [attOnOff, setattOnOff] = useState(false);
    const notify = useNotify();
    const styles = useStyles({});
    const[holiday,setHoliday]=useState([]);

    const loadDashboard = () => {
        let userid = localStorage.getItem("userId");
        apiClient.get_dashboard(userid).then(res => {
            setdb(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const GetDashboardHoliday = () => {
        apiClient.GetDashboardHoliday().then(res => {
            //console.log(res);
            setHoliday(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const handleAttendance = (event) => {
        if (workLocation === null || workLocation === "") {
            notify("Please Select Work location", "warning")
            return;
        }
        if (attType === "ATT_END" && window.confirm('Do you really want to end your working?')) {
            updateAttedance();
        }
        if (attType === "ATT_START" && window.confirm('Do you really want to Start your working?')) {
            updateAttedance();
        }
    }
    const updateAttedance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        let formBody = {
            userId: userid,
            attType: attType,
            location: workLocation
        }
        apiClient.updateAttendance(formBody).then(res => {
            notify(res[0].Msg)
            loadAttendance();
        }).catch(err => {
            console.log(err);
        });
    }
    const loadAttendance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        apiClient.get_attendancestatus(userid).then(res => {
            
            setAttType(res[0].Msg);
            setWorkLocation(res[0].location)
            if (res[0].Msg === 'ATT_START') {
                setattOnOff(false)
            }
            if (res[0].Msg === 'ATT_END') {
                setattOnOff(true)
            }

            setFetchingAttedanceStatus(false);
        }).catch(err => {
            //alert(JSON.stringify(err))
            console.log(err);
        });
    }
    useEffect(() => {
        loadDashboard();
        loadAttendance();
        GetDashboardHoliday();
    }, []);

    return (
        <div>
            
            
            
            <div className={styles.flexCenter}>
                {db && db.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <CountBlock title={item.Descriptions} value={item.TotalCount} icon={item.IconUrl} />
                        </div>
                    )
                })}

            </div>
            {/* <div className={styles.flexColumn}>
                <h2 style={{ textAlign: "center" }}>Holiday Details</h2>
                {holiday && <HolidayList data={holiday} />}
            </div> */}
        </div>

    );
}

export default Dashboard;
