import Button from '@material-ui/core/Button';
import React,{useCallback,useState} from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  SelectInput,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import { DisplayDateTime } from "../../common/DisplayDate";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import GenerateBarcode from './GenerateBarcode';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));

const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Code" source="code~like" alwaysOn />
        <SearchInput variant="outlined" label="Prefix" source="name~like" alwaysOn />
        <ReferenceInput label="Zone" source="zoneId" reference="zones" >
              <SelectInput optionText="name" />
          </ReferenceInput>
    </Filter>
);
export const BinMasterList = (props) => {
  const myclasses = myStyles({}); 
  const [isGenerateBarcode, setIsGenerateBarcode] = useState(false);
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const handleClose = useCallback(() => {
    
    setIsGenerateBarcode(false);
    
  }, []);
  const onCloseDocumentForm = () => {
    setIsGenerateBarcode(false);  
  }

  const onCancel = () => {
    
    setIsGenerateBarcode(false);

  }
  return (
    <React.Fragment>
    <List {...propsObj} exporter={propsObj.hasExport}  bulkActionButtons={false}  title="Bin Master List" filters={<Filters />}  >
      
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
      {propsObj.hasEdit && <EditButton />}

        <TextField source="code" label="Code" />
        
        
        <ReferenceField source="rowId" reference="row-masters" link={false} label="Row" >
                        <TextField source="code" />
                    </ReferenceField>
                    <ReferenceField source="zoneId" reference="zones" link={false} label="Zone" >
                        <TextField source="name" />
                    </ReferenceField>
                    
        <ReferenceField source="facilityId" reference="facilities" link={false} label="Facility" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="zoneId" reference="zones" link={false} label="Zone" >
                        <TextField source="name" />
                    </ReferenceField>
                    <BooleanField source="isActive" />
      <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date"  locales="fr-FR"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date"  locales="fr-FR"/>
            </Datagrid>      
    </List>
    <div style={{width:"100%",marginTop:"10px",textAlign:"center"}}>
    <span style={{width:100}}><Button variant="contained" color="primary" onClick={()=>setIsGenerateBarcode(true)}  >Generate / Download Bin Barcodes</Button></span>
    </div>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper}}
    open={isGenerateBarcode } >
 {isGenerateBarcode && <GenerateBarcode
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          
          {...props}
           />}

        


  </Drawer>    
    </React.Fragment>
  );
};
