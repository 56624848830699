import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const Otherfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <React.Fragment>
            <ReferenceInput variant="outlined" label="Dimension Groups" source="dimensionGrpId" reference="dimension-groups" validate={[required()]}
                fullWidth={true} perPage={500} >
                <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
            
            <ReferenceInput variant="outlined" label="Facility" source="facilityId" reference="facilities"
                onChange={(v) => {                     
                    form.change("zoneId", null);
                    
                }}
                validate={[required()]}
                fullWidth={true} perPage={500} >
                <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Zone" source="zoneId" reference="zones" validate={[required()]}
                fullWidth={true} perPage={500} >
                <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>

            
            </React.Fragment>
    )
}
