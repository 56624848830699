import React, { useEffect,useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import * as apiClient from "../../common/apiClient";
const CustomSelectInput = ({ formData, ...rest }) => {
    const[data,setData]=useState([])
    const form = useForm();
    const classes = containerStyles({});
    let resource=`${rest.reference}?filter[offset]=0&filter[limit]=250000&filter[order]=${rest.optionText}%20ASC`
    let filters=rest.filters;
        if(filters && filters.length>0)
        {
            for (let index = 0; index < filters.length; index++) {
                const element = filters[index];
                let keys = Object.keys(element);
                for (let i = 0; i < keys.length; i++) {
                    const innerElement = keys[i];
                    if(formData[innerElement] && formData[innerElement]!=0)
                    {
                        resource= `${resource}&filter[where][${innerElement}]=${formData[innerElement]===undefined?0:formData[innerElement]}`
                    }
                    
                }  
            } 
        }
    useEffect(() => { 
          getData(); 
      }, [data] ) 
    
    const handleChange = (event) => {
        if(rest.dependentFields && rest.dependentFields.length>0)
        {
            for (let i = 0; i < rest.dependentFields.length; i++) {
                const innerElement = rest.dependentFields[i]; 
                form.change(innerElement , 0);
            } 
            
        } 
    }
    const getData = () => {
        
        apiClient.getdata_v1(resource).then(res => {
            let _data=[];
            let fdata=[]; 
            _data.push({id:0,[rest.optionText]:rest.selectedText});
            fdata=[..._data,...res]
            setData(fdata);
            
        }).catch(err => {
            console.log(err);
        });
    }
    
    
    return (
        <div > 
                
                <SelectInput   fullWidth={true}   variant="outlined" validate={[required()]} onChange={(event)=>handleChange(event)}
                 label={rest.label} source={rest.source} optionText={rest.optionText} choices={data} />
            
        </div>
    )
}
export default CustomSelectInput;