import React from 'react';
import { FormDataConsumer, Create, SelectInput, ReferenceInput, SimpleForm, TextInput, BooleanInput, NumberInput, required } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BillAddressfields } from "./BillAddressfields";
import { ShipAddressfields } from "./ShipAddressfields";
import { Button } from '@material-ui/core';
import { CopyAddress } from './CopyAddress';
export const CustomerCreate = props => {
    const initialValue={
       // billCountryId:0,
       // billRegionId:0,
       // billStateId:0,
        billDistrictId:0,
        billCityId:0,
        shipCountryId:0,
        shipRegionId:0,
        shipStateId:0,
        shipDistrictId:0,
        shipCityId:0
    }
   
    return (
        <React.Fragment>
            <Create label="Create Customer" {...props}>
                <SimpleForm redirect="list" toolbar={<FormToolbar {...props} />}
                    variant="outlined" initialValues={initialValue}>
                    <ReferenceInput variant="outlined" label="Customer Group" source="custGroupId" reference="cust-groups"  
                        fullWidth={true} perPage={500} >
                        <SelectInput optionText="name" validate={[required()]} />
                    </ReferenceInput>
                    <TextInput source="code" label='Code' fullWidth={true} validate={[required()]} />
                    <TextInput source="name" label='Name' fullWidth={true} validate={[required()]} />
                    <TextInput source="contactPerson" label='Contact Person ' fullWidth={true} validate={[required()]} />
                    <TextInput source="contactNumber" label='Contact Number ' fullWidth={true} validate={[required()]} />
                    <div>
                        <h4> Billing Address</h4>
                    </div>
                    <TextInput source="billAddress1" label='Address 1 ' fullWidth={true} initialValue={""} />
                    <TextInput source="billAddress2" label='Address 2' fullWidth={true} initialValue={""} />
                    <TextInput source="billZipCode" label='Pincode' fullWidth={true} initialValue={""} />


                    <FormDataConsumer fullWidth={true} >
                        {formDataProps => (
                            <BillAddressfields {...formDataProps} />
                        )}
                    </FormDataConsumer>  
                    <TextInput source="billEmailId" label='Bill Email Id ' fullWidth={true} initialValue={""} />
                    <div>
                        <h4> Shipping Address</h4>
                        
                    </div>
                    <FormDataConsumer fullWidth={true} >
                        {formDataProps => (
                            <CopyAddress {...formDataProps} />
                        )}
                    </FormDataConsumer>

                    
                    <TextInput source="shipAddress1" label='Address 1 ' fullWidth={true} initialValue={""} />
                    <TextInput source="shipAddress2" label='Address 2' fullWidth={true} initialValue={""} />
                    <TextInput source="shipZipCode" label='Pincode' fullWidth={true} initialValue={""} />
                    <FormDataConsumer fullWidth={true} >
                        {formDataProps => (
                            <ShipAddressfields {...formDataProps} />
                        )}
                    </FormDataConsumer>

                    <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} />
                    <TextInput source="emailId" label='Email' fullWidth={true} initialValue={""} />
                    <TextInput source="gstNo" label='Gst No' fullWidth={true} initialValue={""} />
                    <TextInput source="panNo" label='Pan No' fullWidth={true} initialValue={""}/>
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}