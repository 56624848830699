import * as http from "./http";


export const isDemoSite = false;
export const DateFormat = "dd-MM-yyyy"; // Define the date format
const getApi = () => {
    let hostname = window.location.host;
    var arrValue = hostname.split(".");
    var result = arrValue[0];
    if (result.includes("localhost") === true) {
          result = "http://localhost:9001/"; 
    }
    else {
      
    result = "http://13.233.247.53:9001/";
    }
    //result = "http://13.233.247.53:9001/";
     result = "http://3.109.90.174:9001/";
   // result = "https://Api.igniteinsurancebroking.com/";
    //result = "http://localhost:9001/"; 
    return result;
};
export const APIUrl = getApi();

export const innerUploadFile = (field, data) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {

            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const padDate = (data, field) => {
    if (data[field]) {
      if (data[field].length == 10) {
        data[field] = data[field] + "T00:00:00.000Z";
      }
    }
    return data;
  }
export const getBaseUrl = (url) => {
    if (url.indexOf("?") != -1) {
        url = url.substring(0, url.indexOf("?"));
    }
    return url;
}

export const UploadFile = async (field, data) => {
    if (data && data.rawFile) {
        let response = await innerUploadFile(field, data);
        if (response.files && response.files.length > 0) {
            return getBaseUrl(response.files[0].url);
        } else {
            return data;
        }
    }
    return data;
}




export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}



export const getVideoId = (content_url) => {
    var aData = content_url.split("/");
    var index = aData.length - 1;
    return aData[index];
}
export const VideoPatch = (url, token, model) => {
    let headers = new Headers();
    headers.append("Authorization", 'Bearer ' + token);
    headers.append("Content-Type", "application/json");
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(model),
        headers
    }).then(r => {
        if (r.status == 409) {
            return r.json();
        }
        if (!r.ok) {
            throw Error(r.statusText);
        }
        return r.json();
    });
};

export const updateVideoMeta = (video_id, token, title, description) => {
    let api = "https://api.vimeo.com/videos/" + video_id;
    let data = {
        name: title,
        description: description
    };
    return VideoPatch(api, token, data).then(response => {
        return response;
    });
}
export const registerUser = (user) => {
    let api = APIUrl + "registerUser";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const approveContent = (contentId, approvalFlag) => {
    let api = APIUrl + "content/approve_disapprove_content";
    let data = {
        id: contentId,
        status: approvalFlag
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const loginUser = (user) => {
    let api = APIUrl + "user/login";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const domainValidate = (domain) => {
    let api = APIUrl + "app/domain";
    return http.post(api, domain).then(response => {
        return response;
    });
}


export const StudentTracking = (content_id) => {
    let api = APIUrl + "studenttracking";
    let data = {
        content_id: content_id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const addEditPayment = (data) => {
    let api = APIUrl + "account-payments/addedit";
     return http.post(api, data).then(response => {
        return response;
    });
}

export const addEditJournal = (data) => {
    let api = APIUrl + "/account-payments/addeditjournal";
     return http.post(api, data).then(response => {
        return response;
    });
}


export const approvepayment = (data) => {
    let api = APIUrl + "account-payments/approvepayment";
     return http.post(api, data).then(response => {
        return response;
    });
}
export const approvejournal = (data) => {
    let api = APIUrl + "account-payments/approvejournal";
     return http.post(api, data).then(response => {
        return response;
    });
}


export const ContentReadStatus = (content_id) => {
    let api = APIUrl + "contentreadstatus";
    let data = {
        content_id: content_id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getOrgData = () => {
    let api = APIUrl + "org/1";
    return http.get(api).then(response => {
        return response;
    });
}

export const getStudentAssignment = () => {
    let api = APIUrl + "dashboard/upcoming_assignments";
    return http.get(api).then(response => {
        return response;
    });
}

export const loginUserByToken = (token) => {
    let api = APIUrl + "userById";
    let user = {
        token: token,
        StartIndex: 0,
        PageSize: 10,
    };
    return http.post(api, user).then(response => {
        return response;
    });
}

export const deleteRecord = async (path, id) => {
    let api = APIUrl + path + "/" + id;
    return await http.del(api).then(response => {
        return response;
    });
}

export const addEntiyRecord = (resource, data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const ChangePassword = (data) => {
    let api = APIUrl + "users/update_password";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const reviewAssignment = (data) => {
    let api = APIUrl + "assignment/review_assignment";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getSubjectsForStudent = () => {
    let api = APIUrl + "subject/get_subjects_for_logged_user";
    return http.get(api).then(response => {
        return response;
    });
}

export const getContentPageForSubject = (subjectId) => {
    let api = APIUrl + `content/get_content_page_for_subject/${subjectId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAccountToken = (accountId) => {
    let api = APIUrl + `account/get_account_token`;
    let data = { "accountId": accountId };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const getSectionsForClass = (classId) => {
    //let api = APIUrl + `section/${classId}`;
    var filter = { "class_id=": classId };
    const filterString = JSON.stringify(filter);
    let api = `${APIUrl}/section?filter=${filterString}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getRateMaster  = (id) => {
    let api = APIUrl + `rate-masters?filter[offset]=0&filter[limit]=2500000&filter[order]=name%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getBrokrageData  = (orderPaymentId) => {
    let api = APIUrl + `/order-brokrages/getbrokragedata/${orderPaymentId}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getTaxHead  = (id) => {
    let api = APIUrl + `tax-heads/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getBrokrageType  = (id) => {
    let api = APIUrl + `lookups?filter[offset]=0&filter[limit]=25&filter[order]=id%20DESC&filter[where][type]=BT`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getMeetingSchedule = (id) => {
    let api = APIUrl + `meeting_schedule/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const startMeeting = (id) => {
    let api = APIUrl + `meeting_schedule/flag_meeting_as_started`;
    let data = {
        id: id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const stopMeeting = (id) => {
    let api = APIUrl + `meeting_schedule/flag_meeting_as_stopped`;
    let data = {
        id: id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
//Dashboard APIs
export const getAllClasses = () => {
    let api = APIUrl + `genere?pagesize=30&page=1&sortby=numeric_equivalent&sortorder=ASC&filter={}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_scholars_for_assignment = (class_id, assignmentCode, submitStatus) => {
    let api = APIUrl + `scholar/get_scholars_for_assignment`;
    let data = {
        class_id: class_id,
        assignment_id: assignmentCode,
        submit_status: submitStatus
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const get_all_users = () => {
    let api = APIUrl + `user/get_all_users`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_all_assignments = () => {
    let api = APIUrl + `content/get_all_assignments`;
    let data = {

    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const get_all_classes_for_employee = () => {
    let api = APIUrl + `genere/get_all_classes_for_employee`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_dashboard = (userid) => {
    let api = APIUrl + `users/dashboard/`+userid;
    return http.get(api).then(response => {
        return response;
    });
}
export const GetDashboardHoliday = () => {
    let api = `${APIUrl}employees/dashboardholiday`;
    return http.get(api).then(response => {
        return response;
    });
}

export const get_attendancestatus = (userid) => {
    let api = APIUrl + `employees/checkemploginstatus/`+userid;
    return http.get(api).then(response => {
        return response;
    });
}

export const get_all_assignments_for_class_from_faculty = (class_id) => {
    let api = APIUrl + `content/get_all_assignments_for_class_from_faculty`;
    let data = {
        class_id: class_id
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

// export const updateRolerights = (data) => {
//     let api = APIUrl + "/role-types/rights";
//     return http.post(api, data).then(response => {
//         return response;
//     });
// }
export const updateUserAccountMapping = (data) => {
    let api = APIUrl + "/useraccountmaps/mapaccount";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateUserRoleMapping = (data) => {
    let api = APIUrl + "/userrolemap/maprole";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserDeparmentMapping = (data) => {
    let api = APIUrl + "/userdepartmentmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserfDeparmentMapping = (data) => {
    let api = APIUrl + "/userfullfillmentdeptmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}



export const updateaccountprice = (data) => {
    let api = APIUrl + "/accountlabpartnertestprices/mapprice";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const mapglobalRouting = (data) => {
    let api = APIUrl + "/globalroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const mapTestRouting = (data) => {
    let api = APIUrl + "/testroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const MapGroupTest = (data) => {
    let api = APIUrl + "/testgroupmaps/maptest";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const fetchGridData = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    console.log(api);
    return http.get(api).then(response => {

        return response;
    })
}
export const getPatientData = (id) => {
    let api = APIUrl + "/patients/" + id;
    return http.get(api).then(response => {
        return response;
    })
}
export const addFromSetup = (id, config) => {
    let api = APIUrl + "/realiz-ords/addfromsetup/" + id + "/" + config;
    return http.get(api).then(response => {
        return response;
    })
}
export const exportToCSV = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    return http.get(api).then(response => {

        return response;
    })
}
export const managePatient = (data) => {
    let api = APIUrl + "/patient/manage";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getBookigList = (data) => {
    let api = APIUrl + "/testbookingorders/list";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const SearchTest = (data) => {
    let api = APIUrl + "/testaccountmap/find";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const addEditBooking = (data) => {
    let api = APIUrl + "/testbookingorders/addedit";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const DeleteBookedTest = (data) => {
    let api = APIUrl + "/testbookingorders/delete";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const GetBookingData = (data) => {
    let api = APIUrl + "/testbookingorders/bookingdata";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateRealizationDocument = (data) => {
    let api = APIUrl + "/realiz-ord-dets/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const updateHospitalDocument = (data) => {
    let api = APIUrl + "/hospital-docs/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}

export const createHospitalTpaConfig = (data) => {
    let api = APIUrl + "hospital-dets";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createHospitalInsurerConfig = (data) => {
    let api = APIUrl + "hospital-ins-dets";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createHospitalRealisation = (data) => {
    let api = APIUrl + "hospital-real-dets";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const loadReport = (resource,data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getdata = (resource) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=250&filter[order]=id%20DESC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getdata_v1 = (resource) => {
    let api = `${APIUrl}${resource}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getdataWithOrder = (resource,orderField,sortDirection) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=25000&filter[order]=${orderField}%20${sortDirection}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getOrderHeaderData = (id) => {
    let api = APIUrl + "orders/" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const updateRolerights = (data) => {
    let api = APIUrl + "role-types/rights";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateRoleType = (id, data) => {
    let api = APIUrl + `role-types/${id}`;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const getmodules = (user_id) => {
    let api = APIUrl + "employees/getmodules/" + user_id;
    return http.get(api).then(response => {
        return response;
    });
}

export const getfunctionbyroletype = (roleid) => {
    let api = `${APIUrl}employees/getfunctionbyroletype/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}

 
export const addRecord = (data, resource) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
 


export const getsalary = (id,month,year) => {
    let api = APIUrl + `employee-sal/getsalary/${id}/${month}/${year}`
    return http.get(api).then(response => {
        return response;
    });
}



export const getInvoiceData= (invoiceid) => {
    let api = APIUrl + `/hospital-invoice/getinvoicedata/${invoiceid}`
    return http.get(api).then(response => {
        return response;
    });
}

export const getInvoiceDataForPrint= (invoiceid) => {
    let api = APIUrl + `/hospital-invoice/printinvoice/${invoiceid}`
    return http.get(api).then(response => {
        return response;
    });
}

export const globalPrint= (id,transactionType) => {
    let api = APIUrl + `globalPrint/${id}/${transactionType}`
    return http.get(api).then(response => {
        return response;
    });
}

export const getpayslipPrint= (id) => {
    let api = APIUrl + `/employee-sal/printpayslip/${id}`
    return http.get(api).then(response => {
        return response;
    });
}


export const getOrderDocuments= (id) => {
    let api = APIUrl + `/order-documents?filter[offset]=0&filter[limit]=250&filter[order]=id%20ASC&filter[where][ordId]=${id}`
    return http.get(api).then(response => {
        return response;
    });
}
export const getOrederEmailData= (id,templateId) => {
    let api = APIUrl + `/orders/emaildata/${id}/${templateId}`
    return http.get(api).then(response => {
        return response;
    });
}

export const getproductAttributes= (productId) => {
    let api = APIUrl + `/products/attribute/${productId}`
    return http.get(api).then(response => {
        return response;
    });
}

export const productUomHierarchy= (productId) => {
    let api = APIUrl + `/products/product-uom-hierarchy/${productId}`
    return http.get(api).then(response => {
        return response;
    });
}
export const getproductdata= (productId) => {
    let api = APIUrl + `/products/${productId}`
    return http.get(api).then(response => {
        return response;
    });
}
export const getCustomerProductDiscount= (productId,customerId) => {
    let api = APIUrl + `/customer-item-wise-discounts/?filter[offset]=0&filter[limit]=25&filter[order]=id ASC&filter[where][productId]=${productId}&filter[where][customerId]=${customerId}`
    return http.get(api).then(response => {
        return response;
    });
}


export const addupdateHospitalInvoice = (data) => {
    let api = APIUrl + "hospital-invoice/createupdateinvoice";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createupdatesalary = (data) => {
    let api = APIUrl + "employee-sal/createupdatesalary";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const createrowmaster = (data) => {
    let api = APIUrl + "row-masters/createrowmaster";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createbinmaster = (data) => {
    let api = APIUrl + "bin-masters/createbinmaster";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateleave = (data) => {
    let api = APIUrl + "/employee-leaves/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const updateAttendance = (data) => {
    let api = APIUrl + "/employees/attendanceentry";
    return http.post(api, data).then(response => {
        return response;
    });

}
export const addeditPurchaseOrder = (data) => {
    let api = APIUrl + "/purchase-orders/addedit";
    return http.post(api, data).then(response => {
        return response;
    });

}
 
export const getPurchaseOrder = (id) => {
    let api = APIUrl + "purchase-orders/getpurchaseOrderData/"+id;
    return http.get(api).then(response => {
        return response;
    });
}

export const addeditGoodInwardOrder = (data) => {
    let api = APIUrl + "/good-inward-orders/addedit";
    return http.post(api, data).then(response => {
        return response;
    });

}


export const addeditOrder = (data) => {
    let api = APIUrl + "/orders/addedit";
    return http.post(api, data).then(response => {
        return response;
    }); 
}

export const ValidateTransaction = (data) => {
    let api = APIUrl + "/orders/validateprocessparameters";
    return http.post(api, data).then(response => {
        return response;
    }); 
}
export const approveOrder = (data) => {
    let api = APIUrl + "/orders/approveOrder";
    return http.post(api, data).then(response => {
        return response;
    }); 
}

export const importData = (data) => {
    let api = APIUrl + "/orders/importdata";
    return http.post(api, data).then(response => {
        return response;
    });

}
export const addDispatchOrder = (data) => {
    let api = APIUrl + "/dispatch-orders";
    return http.post(api, data).then(response => {
        return response;
    });

}
 
export const getGoodInwardOrder = (id) => {
    let api = APIUrl + "good-inward-orders/getOrderData/"+id;
    return http.get(api).then(response => {
        return response;
    });
}

 
export const getOrderData = (id) => {
    let api = APIUrl + "orders/getOrderData/"+id;
    return http.get(api).then(response => {
        return response;
    });
}
 
export const getUnfullfiledOrderData = (id) => {
    let api = APIUrl + "orders/getUnfullfiledOrderData/"+id;
    return http.get(api).then(response => {
        return response;
    });
}


export const getOrderDetail = (id) => {
    let api = APIUrl + "orders/getOrderDetail/"+id;
    return http.get(api).then(response => {
        return response;
    });
}
export const getPicklistData = (id) => {
    let api = APIUrl + "orders/getPicklistData/"+id;
    return http.get(api).then(response => {
        return response;
    });
}
export const getPicklistRevision = (id) => {
    let api = APIUrl + "orders/getPicklistRevision/"+id;
    return http.get(api).then(response => {
        return response;
    });
}
export const AddRevisePick = (data) => {
    console.log(data)
    let api = APIUrl + "/orders/AddRevisePick";
    return http.post(api, data).then(response => {
        return response;
    });
    
}


export const confirmputaway = (id,userId) => {
    let api = APIUrl + `good-inward-orders/confirmputaway/${id}/${userId}`
    return http.get(api).then(response => {
        return response;
    });
}

export const updatebininputaway = (id,lineId,binId,isGood) => {
    let api = APIUrl + `good-inward-orders/updatebininputaway/${id}/${lineId}/${binId}/${isGood}`
    return http.get(api).then(response => {
        return response;
    });
}
export const getCustomerData = (id) => {
    let api = APIUrl + `customers?filter[where][id]=${id}`
    return http.get(api).then(response => {
        return response;
    });
}




export const pendingPutawayData = (data) => {
    let api = APIUrl + "/pending-putaway-data";
    return http.post(api, data).then(response => {
        return response;
    }); 
}
export const pickSlipData = (data) => {
    
    let api = APIUrl + `orders/pickslip/${data}`;
    return http.get(api).then(response => {
        return response;
    }); 
}
export const confirmPicking = (data,userid) => {
    
    let api = APIUrl + `orders/confirmpickslip/${data}/${userid}`;
    return http.get(api).then(response => {
        return response;
    }); 
}
export const getCustomerBalancePayment = (clientId,customerId,paymentId) => {
    
    let api = APIUrl + `/account-payments/getaccountbalance/${clientId}/${customerId}/${paymentId}`;
    return http.get(api).then(response => {
        return response;
    }); 
}


export const PickingCancelProduct = (data) => {
    
    let api = APIUrl + "/orders/picking/cancelproduct";
    return http.post(api, data).then(response => {
        return response;
    }); 
}
export const viewdocumentinHTML_V1 = (data) => {
   
    let api = APIUrl + "/orders/viewdocumentinHTML";
    return http.post(api, data).then(response => {
        return response;
    }); 
}

export const viewdocumentinHTML = (transactionId,transactionTypeId,rpt) => {
    let data={
        voucherId:transactionId,
        processtypeId:transactionTypeId,
        rptFrom:rpt
    }
    let api = APIUrl + "/orders/viewdocumentinHTML";
    return http.post(api, data).then(response => {
        return response;
    }); 
}
 

export const generatepacking = (data) => {
    
    let api = APIUrl + "/orders/generatepacking";
    return http.post(api, data).then(response => {
        return response;
    }); 
}
export const completepacking = (data) => {
    
    let api = APIUrl + "/orders/completepacking";
    return http.post(api, data).then(response => {
        return response;
    }); 
}

export const addTransactionQueue = (data) => {
    let api = APIUrl + "/transactionqueue/add";
    return http.post(api, data).then(response => {
        return response;
    }); 
}
export const getData = (resource) => {
    let api = APIUrl + resource ;
    return http.get(api).then(response => {
        return response;
    });
}
export const getEntiyRecord = (resource, id) => {
    let api = APIUrl + resource + "/" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const updateRecord = (resource,data) => {
    if (data.hasOwnProperty("dob") && data.dob ==null) {
        data.dob="1900-01-01T03:09:32.033Z";
      }
      if (data.hasOwnProperty("dob") && data.dob !=null) {
        if (!data.dob.toString().includes('T03:09:32.033Z'))
        {
            data.dob=data.dob+'T03:09:32.033Z';
        }
        
      }
      if (data.hasOwnProperty("dealDate") && data.dob !=null) {
        if (!data.dealDate.toString().includes('T03:09:32.033Z'))
        {
            data.dealDate=data.dealDate+'T03:09:32.033Z';
        }
        
      }
      if (data.hasOwnProperty("paymentDate") && data.paymentDate !=null) {
        if (!data.paymentDate.toString().includes('T03:09:32.033Z'))
        {
            data.paymentDate=data.paymentDate+'T03:09:32.033Z';
        }
        
      }
      
      if (data.hasOwnProperty("openDate") && data.dob !=null) {
        if (!data.openDate.toString().includes('T03:09:32.033Z'))
        {
            data.openDate=data.openDate+'T03:09:32.033Z';
        }
        
      }

      var keys = Object.keys(data);
      keys.forEach((item) => {
          if (data[item] == null) {
              delete data[item];
          }
      });
    let api = APIUrl + "/"+resource+"/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}


