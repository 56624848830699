import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const StateCreate = (props) => {
  return (
    <Create title="State Edit" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
       
      <ReferenceInput source="countryId" reference="countries"  fullWidth={true} link={false} label="Country" >
                        <SelectInput source="name" />
                    </ReferenceInput>
               
      <ReferenceInput source="regionId" reference="regions"  fullWidth={true} link={false} label="Region" >
                        <SelectInput source="name" />
                    </ReferenceInput>
        <TextInput source="code" fullWidth={true} validate={[required()]} />
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
      </SimpleForm>
    </Create>
  );
};
