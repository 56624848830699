import React, { useCallback } from 'react';
import {
    required,Create,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,FormDataConsumer,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

 
const PostCreateToolbar = props => (
       
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const BranchCreate = props => {
    const classes = useStyles({});
    let initialValue=
    {
      
      
      "code": "-",
      "name": "",
      "areaId": 0,
      employeeId:0,
      
      "add1": "",
      "add2": "",
      "add3": "",
      "city": "",
      "state": "",
      "pincode": "",
      "email": "",
      "phoffice": "",
      "phmobile": "",
      "cpName1": "",
      "cpName2": "",
      "cpName3": "",
      "cpName4": "",
      "cpName5": "",
      "cpMob1": "",
      "cpMob2": "",
      "cpMob3": "",
      "cpMob4": "",
      "cpMob5": "",
      "cpRes1": "",
      "cpRes2": "",
      "cpRes3": "",
      "cpRes4": "",
      "cpRes5": "",
      "cstNo": "",
      tin: "",
      tpt: "",
      licno: "",
      panno: "",
      isCenteral: false,
      taxtTypeId:0,
      taxPercentage: 0,
      isActive: true,
      
    }
    const handleSubmit = async (formdata) => {
         console.log(formdata);
         let user = localStorage.getItem("userId");
         if (user) {
         user = parseInt(user);
         }
        

          formdata.insurerId=parseInt(props.recId);
          formdata.createdBy=  user;
          formdata.createdDate= moment().toISOString();
          formdata.modifyBy= user;
          formdata.modifyDate= moment().toISOString();
          
        // data = {
        //     custId:parseInt(props.custId),
        //     name: formdata.fname,
        //     "shortName": formdata.fshortName, 
        //     "dob":  formdata.fdob,
        //     "phone":  formdata.phone,
        //     "mobile":  formdata.mobile,
        //     "email":  formdata.email,
        //     "createdBy":  user,
        //     "createdDate": moment().toISOString(),
        //     "modifyBy": user,
        //     "modifyDate": moment().toISOString(),
        //     "isActive": true
        //   }
          if(formdata.dealDate!=null)
          {
            formdata.dealDate=formdata.dealDate+"T10:40:14.968Z";
          }
          if(formdata.openDate!=null)
          {
            formdata.openDate=formdata.openDate+"T10:40:14.968Z";
          }
          
          
        apiClient.addEntiyRecord("/insurer-branches", formdata).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
        
    }
    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined" initialValues={initialValue}>

                          
            
            <DateInput source="dealDate" label="Ent. Date" fullWidth={true} validate={[required()]} formClassName={classes.one_4_input} />
            <DateInput source="openDate" label="Open Date"  fullWidth={true} validate={[required()]} formClassName={classes.two_4_input} /> 
            <TextInput source="name" label="Name"  fullWidth={true} validate={[required()]} formClassName={classes.three_4_input} />
            <TextInput source="phoffice" label="Phone(Off)."   fullWidth={true} formClassName={classes.last_4_input} />

            <TextInput source="email" label="email"  fullWidth={true} formClassName={classes.one_4_input} />  
            <TextInput source="phmobile" label="Mobile"  fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput source="tin" label="Tin"   fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput source="cstNo" label="CST Number"   fullWidth={true}  formClassName={classes.last_4_input} />

            <TextInput source="tpt" label="tpt"  fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput source="licno" label="LIC No"  fullWidth={true}  formClassName={classes.two_4_input} />
            <TextInput source="panno" label="Pan Number"  fullWidth={true}  formClassName={classes.three_4_input} /> 
            <TextInput source="state" label="State" fullWidth={true} validate={[required()]} formClassName={classes.last_4_input} />


          <ReferenceInput label="Area" source="areaId" fullWidth={true} reference="areas" formClassName={classes.one_4_input}>
              <SelectInput optionText="name" fullWidth={true} validate={[required()]} />
          </ReferenceInput>
          <TextInput source="city" label="City"  fullWidth={true}validate={[required()]} formClassName={classes.two_4_input} />  
          <TextInput source="add1" label="Address 1"  fullWidth={true} validate={[required()]} formClassName={classes.three_4_input} />
          <TextInput source="add2" label="Address 2"  fullWidth={true} formClassName={classes.last_4_input} />

          <TextInput source="add3" label="Address 3"   fullWidth={true} formClassName={classes.one_4_input} /> 
          <TextInput source="pincode" label="Pincode"  fullWidth={true}validate={[required()]} formClassName={classes.two_4_input} />  
          <TextInput source="cpName1" label="Contact Person 1"  fullWidth={true} formClassName={classes.three_4_input} />
          <TextInput source="cpMob1" label="Contact Person 1 Mobile"  fullWidth={true} formClassName={classes.last_4_input} /> 

          <TextInput source="cpRes1" label="Contact Person 1 ResiID"  fullWidth={true}  formClassName={classes.one_4_input} />
          <TextInput source="cpName2" label="Contact Person 2"  fullWidth={true}  formClassName={classes.two_4_input} />
          <TextInput source="cpMob2" label="Contact Person 2 Mobile" fullWidth={true} formClassName={classes.three_4_input} /> 
          <TextInput source="cpRes2" label="Contact Person 2 ResiID" fullWidth={true}  formClassName={classes.last_4_input} />


          <TextInput source="cpName3" label="Contact Person 3"   fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput source="cpMob3" label="Contact Person 3 Mobile" fullWidth={true} formClassName={classes.two_4_input} />  
          <TextInput source="cpRes3" label="Contact Person 3 ResiID"  fullWidth={true}  formClassName={classes.three_4_input} />
          <TextInput source="cpName4" label="Contact Person 4"  fullWidth={true} formClassName={classes.last_4_input} />

          <TextInput source="cpMob4" label="Contact Person 4 Mobile"  fullWidth={true} formClassName={classes.one_4_input} /> 
          <TextInput source="cpRes4" label="Contact Person 4 ResiID"  fullWidth={true} formClassName={classes.two_4_input} />
          <TextInput source="cpName5" label="Contact Person 5"   fullWidth={true} formClassName={classes.three_4_input} />
          <TextInput source="cpMob5" label="Contact Person 5 Mobile"  fullWidth={true} formClassName={classes.last_4_input} />

          <TextInput source="cpRes5" label="Contact Person 5 ResiID"  fullWidth={true} formClassName={classes.one_4_input} />
          <ReferenceInput label="Handle By" source="employeeId" fullWidth={true} reference="employees" formClassName={classes.two_4_input}
            sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" validate={[required()]}  fullWidth={true} formClassName={classes.two_three_input} />
          </ReferenceInput>

            <BooleanInput source="isActive" label="Active" initialValue={true}  fullWidth={true} formClassName={classes.three_4_input} />
            </SimpleForm>
        </Create>
    );
}
export default BranchCreate;