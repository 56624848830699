import React, { useEffect,useCallback,useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';

import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import  PopupCreateToolbar  from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const AttributeEdit = props => {
    const classes = useStyles({});
    const[tpPremiumAmt,setTpPremiumAmt]=useState(0);
    const[odPremiumAmt,setOdPremiumAmt]=useState(0);
    const[IsMultiPolicy,setIsMultiPolicy]=useState(true);
    const[IsinsurerId,setinsurerId]=useState(-1);
    
    const[net,setNet]=useState(0);
    

    const handleSubmit = async (formdata) => {
        
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }

        let data={...formdata};
        let m = moment(new Date());
        
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("attribute-group-dtls", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="attribute-group-dtls" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
   
                <TextInput source="name" fullWidth={true} validate={[required()]}  />

                <BooleanInput source="isActive" validate={[required()]} label="Active" fullWidth={true} initialValue={true} />




            
            </SimpleForm>
        </Edit>
    );
}
export default AttributeEdit;