import React, { useState, useEffect } from "react";
import * as apiClient from "../../common/apiClient";
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  NumberField,
  TextField, useRefresh


} from "react-admin";
import { v4 as uuidv4 } from 'uuid';
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import { GridDetail } from './GridDetail';
import { Button } from "@material-ui/core";
import { CustomerField } from "./CustomerField";


export const OrderEdit = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [gridData, setGridData] = useState([{ key: uuidv4(), lineNumber: 1, productCode: null, productName: null, uomId: 0, price: null, qty: null, totalAmount: null, attributes: [], uomData: [] }]);
  const [headerData, setHeaderData] = useState([]);
  const [isLoading, SetIsLoading] = useState(true)
  const [transactionNo, SettransactionNo] = useState("");
  const [parentOrderId, SetParentOrderId] = useState(0);
  const [documentStatusId, setDocumentStatusId] = useState(0);
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  useEffect(() => {
    setGridData(gridData)
  });
  useEffect(() => {
    loadData()
  }, []);
  const loadData = async () => {
    console.log(props, '####props####')
    SetIsLoading(true)
    
    await apiClient.getUnfullfiledOrderData(props.id).then(res => {
      setGridData(JSON.parse(res[0].data)[0].lineItems)
      SettransactionNo(JSON.parse(res[0].data)[0].orderNo)
      setHeaderData(JSON.parse(res[0].data)[0])
      SetIsLoading(false)
    }).catch(err => {
      console.log(err);
      SetIsLoading(false)
    });
  }
  const handleChange = (indata) => {
    setGridData(indata);
  }
  const handleApprove = async () => {
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    let data: any = {};
    data.id = parseInt(props.id);
    data.userId = userId;
    apiClient.approveOrder(data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/orders");
      }
      else {
        notify(res[0].remarks, "warning")

      }
    });
  }
  const handleSubmit = async (formdata: any) => {
    const userConfirmed = window.confirm('Are you sure you want to proceed?');
    if (userConfirmed) {
      let userId;
      userId = localStorage.getItem("userId");
      if (userId) {
        userId = parseInt(userId);
      }
      let payload = { userId: userId, parentOrderId: formdata.parentOrderId }
      apiClient.addRecord(payload, 'orders-unfulfilleds/processorder').then(res => {
        if (res[0].id > 0) {
          notify(res[0].remarks)
          redirect("/orders-unfulfilleds");
        }
        else {
          notify(res[0].remarks, "warning")
        }
      }).catch(err => {
        console.log(err);
      });

    } else {

    }


  }
  const handleChangeStatus = async (id, data) => {
    data.statusId = id;
    apiClient.updateRecord('orders-unfulfilled-dtls', data).
      then(res => {
        refresh();
        notify('Status Changed');
      }).catch(err => {
        notify('Error', "warning");
      });
  }
  const handleLineStatus = async (id) => {
    let updatedCount = 0;
    let grid_data = [];
    try {
        grid_data = await apiClient.getData(`orders-unfulfilled-dtls?filter[offset]=0&filter[limit]=2500&filter[order]=id%20ASC&filter[where][parentOrderId]=${parentOrderId}`);
    } catch (err) {
        notify('Error', "warning");
    }

    const updatePromises = grid_data.map(async (item:any) => {
        if (item.statusId != 183 && item.statusId != 188 && item.statusId != id) {
            item.statusId = id;
            try {
                await apiClient.updateRecord('orders-unfulfilled-dtls', item);
                updatedCount++;
            } catch (err) {
                
            }
        }
    }); 
    await Promise.all(updatePromises); 
    if(updatedCount>0)
    {
      refresh();
      notify(`${updatedCount} records were updated.`);
    }
    else
    {
      notify('No records were updated', "warning")
    }
    

    
}

  const StatusChange = (props) => {
    let { record } = props;
    return <ReferenceInput label="Status" formClassName={classes.three_5_input}
      source={`statusId_${uuidv4()}`} // Use a unique source for each ReferenceInput 
      filter={{ code: 'UOP' }}
      validate={[required()]}
      initialValue={record.statusId}
      disabled={(record.statusId == 183 || record.statusId == 188)}
      onChange={(event) => { handleChangeStatus(event.target.value, record) }}
      reference="lookups" >
      <SelectInput optionText="name" variant="outlined" formClassName={classes.one_three_input} />
    </ReferenceInput>

  }
  return (
    <Edit title=" Order Edit" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit}
        toolbar={!isLoading ?
          <FormToolbar onSave={handleSubmit}  {...props} hideSave={documentStatusId == 185 ? false : true} showDelete={false} /> : null}
        variant="outlined" >
        {/* <div className="transaction-header"> Order Detail ::-{transactionNo}  </div> */}
        <ReferenceInput label="Order No" fullWidth={true} 
          perPage={5000} source="parentOrderId"
          reference="orders"
          disabled >
          <SelectInput optionText="orderNo" />  
          </ReferenceInput>
        <ReferenceInput label="Facility" fullWidth={true} formClassName={classes.one_5_input}
          perPage={5000} source="facilityId"
          reference="facilities"
          disabled >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Order Type" fullWidth={true} formClassName={classes.two_5_input}
          perPage={5000} source="orderType"
          filter={{ type: 'OT' }} disabled reference="lookups"  >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Client" fullWidth={true} formClassName={classes.three_5_input}
          perPage={5000} source="clientId"
          reference="clients" disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer fullWidth={true} formClassName={classes.four_5_input}>
          {formDataProps => (
            <CustomerField  {...formDataProps} />
          )}
        </FormDataConsumer>
        <ReferenceInput label="Sale Representative" fullWidth={true} formClassName={classes.last_5_input}
          perPage={1} source="saleRepId" reference="sale-reps" disabled>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source={"orderDate"} label={"Order  Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_5_input} disabled />
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.two_5_input} disabled />
        <ReferenceInput label="Mode" formClassName={classes.three_5_input}
          perPage={5000} source="tpMode"
          filter={{ type: 'TPM' }}
          validate={[required()]}
          reference="lookups" disabled>
          <SelectInput optionText="name" variant="outlined" formClassName={classes.one_three_input} />
        </ReferenceInput>

        <div>
        </div>
        <TextInput source="shipAddress1" multiline label="Shipping Address 1" validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.one_5_input} disabled />
        <TextInput source="shipAddress2" multiline label="Shipping Address 2" initialValue={""} fullWidth={true} formClassName={classes.two_5_input} disabled />
        <TextInput source="shipZipCode" label="Ship Pincode" validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.three_5_input} disabled />
        <ReferenceInput label="Shipping State" fullWidth={true} formClassName={classes.four_5_input}
          perPage={5000} source="shipStateId"
          sort={{ field: 'name', order: 'ASC' }}
          reference="states" disabled >
          <SelectInput optionText="name" variant="outlined" validate={[required()]} />

        </ReferenceInput>
        <TextInput source="cityName" label="City Name" validate={[required()]} initialValue={""} fullWidth={true} formClassName={classes.last_5_input} disabled />
        <TextInput source="remarks" multiline label="Remarks" initialValue={""} fullWidth={true}  disabled />


        <ReferenceInput label="Change Line Status"
          source={`statusId_${uuidv4()}`} // Use a unique source for each ReferenceInput 
          filter={{ code: 'UOP' }}
          fullWidth={true} formClassName={classes.one_three_input}
          onChange={(event) => { handleLineStatus(event.target.value) }}
          reference="lookups" >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>

        <ReferenceInput label="Document Status"
          source="documentStatusId"
          filter={{ type: 'DOCST' }}
          fullWidth={true} formClassName={classes.two_three_input}
          disabled
          // onChange={(event) => { handleLineStatus(event.target.value) }}
          reference="lookups" >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>
        {!isLoading && isAdmin && <div style={{ textAlign: "right", width: "100%", marginTop: 20 }} >
          <FormDataConsumer formClassName={classes.last_three_input}>
            {({ formData, ...rest }) =>
              formData.statusId &&
              formData.statusId === 157 &&
              <Button className="close-me-btn" variant="contained" color="primary" onClick={handleApprove} >
                Approve
              </Button>
            }
          </FormDataConsumer>
        </div>}

        <ReferenceManyField fullWidth={true}
          addLabel={false}
          sort={{ field: 'id', order: 'ASC' }}
          reference="orders-unfulfilled-dtls"
          target="orderUnfulfilledId"
        >
          <Datagrid>
            <StatusChange source="Id" label="Status" />
            <ReferenceField source="productId" reference="products" label="Product Name " link={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="productId" reference="products" label="Product  Code" link={false}>
              <TextField source="code" />
            </ReferenceField>
            <NumberField source="price" />
            <NumberField source="discount" />
            <NumberField source="qty" />
            <NumberField source="totalAmount" />

          </Datagrid>
        </ReferenceManyField>

        {!isLoading && <FormDataConsumer fullWidth={true} >
          {formDataProps => (
            <GridDetail loadAddress={true} headerData={headerData} {...formDataProps} gridData={gridData} handleChange={handleChange} />
          )}
        </FormDataConsumer>
        }
        <FormDataConsumer >
          {formDataProps => (
            SetParentOrderId(formDataProps.formData.parentOrderId)
          )}
        </FormDataConsumer>
        <FormDataConsumer >
          {formDataProps => (
            setDocumentStatusId(formDataProps.formData.documentStatusId)
          )}
        </FormDataConsumer>


      </SimpleForm>
    </Edit>
  );
};
