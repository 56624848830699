import React, { useEffect,useCallback,useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';

import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import  PopupCreateToolbar  from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const AttributeEdit = props => {
    const classes = useStyles({});
    const[tpPremiumAmt,setTpPremiumAmt]=useState(0);
    const[odPremiumAmt,setOdPremiumAmt]=useState(0);
    const[IsMultiPolicy,setIsMultiPolicy]=useState(true);
    const[IsinsurerId,setinsurerId]=useState(-1);
    
    const[net,setNet]=useState(0);
    useEffect(() => {
        getOrderHeaderData();
    }, [IsinsurerId]); 
    const getOrderHeaderData = () => {
        apiClient.getOrderHeaderData(parseInt(props.ordId)).then(res => {
            debugger
            if(res.isMultiPolicy==true)
            {
                setIsMultiPolicy(true);
            }
            else
            {
                setIsMultiPolicy(false);
            }
            setinsurerId(res.insurerId)
            
        }).catch(err => {
            console.log(err);
        });
    }
    console.log(props);
    const TwoDecimalPlaces = (value) => {
        
        if (isNaN(value))
        {
            value=0;
        }
        return Number(value).toFixed(2).valueOf();
    }
    const onAmountChangeAmt = (e, field) => {
        let inputVal = parseFloat(e.target.value);  
        let odPremiumAmt_In=odPremiumAmt
        let tpPremiumAmt_In=tpPremiumAmt
        if (field == "odPremiumAmt") {
            odPremiumAmt_In = inputVal;
            setOdPremiumAmt(inputVal);
        }
         
        if (field == "tpPremiumAmt") {
            tpPremiumAmt_In = inputVal;
            setTpPremiumAmt(inputVal)
        }
        if (isNaN(odPremiumAmt_In)) {
            odPremiumAmt_In = 0;
        }

        if (isNaN(tpPremiumAmt_In)) {
            tpPremiumAmt_In = 0;
        }
       setNet(odPremiumAmt_In+  tpPremiumAmt_In)


    }

    const handleSubmit = async (formdata) => {
        
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }

        let data={...formdata};
        let m = moment(new Date());
        
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("product-group-attributes", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="product-group-attributes" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
  <ReferenceInput label="Type" fullWidth={true}
                    perPage={5000} source="attributeTypeId"
                    filter={{ type: 'AT' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" formClassName={classes.one_three_input} validate={[required()]} />
                </ReferenceInput>
                <TextInput source="name" fullWidth={true} validate={[required()]}  />
                <FormDataConsumer fullWidth={true} formClassName={classes.one_three_input}>
          {({ formData, ...rest }) =>
            formData.attributeTypeId &&
            formData.attributeTypeId === 145 &&
             (
              <ReferenceInput label="Attribute Group" source="attributeGroupId" reference="attribute-groups"  fullWidth={true} formClassName={classes.one_three_input} variant="outlined"  >
                <SelectInput optionText="name"  validate={[required()]}  variant="outlined"  fullWidth={true} formClassName={classes.one_three_input} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
                <BooleanInput source="isActive" validate={[required()]} label="Active" fullWidth={true} initialValue={true} />




            
            </SimpleForm>
        </Edit>
    );
}
export default AttributeEdit;